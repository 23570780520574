import React, { useState, useCallback, ReactNode } from "react"
import ProcessorEditors from "./process_editors"
import { Header, List, Title } from "@refinedev/antd"
import {
  Button,
  Typography,
  Card,
  Table,
  Input,
  Modal,
  Dropdown,
  Select,
  Badge,
} from "antd"
import { MenuOutlined } from "@ant-design/icons"
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable"
import { ProductFooter } from "../pipelines/styled"

import { uuidv4 } from "@antv/xflow-core"
import { useTranslation } from "react-i18next"
import {
  IProcessorRow,
  DraggableRow,
  DraggableWrapper,
  defaultSchemaContent,
  defaultPromptText,
  defaultGenParams,
  defaultModelParams,
  defaultEnvVariables,
} from "./helpers"
import MDEditor from "@uiw/react-md-editor"
import { InputOutputEditors } from "./input_output_editors"
import { useDataProvider } from "@refinedev/core"
import DropdownButton from "antd/es/dropdown/dropdown-button"
import FormItemLabel from "antd/es/form/FormItemLabel"
import FormItem from "antd/es/form/FormItem"

var JSON5 = require("json5")

const { Text } = Typography

const llmConfigTemplates = [
  {
    name: "OpenAI Config",
    value: {
      server_type: "openai",
      base_inference_url: "https://api.openai.com",
      model_prompt_format: "none",
      default_system_prompt:
        "You are an advanced AI legal news assistant working with Law360 to help with reader personalization, custom content curation, and generating in-depth insights to drive engagement and a deep understanding of Law360's customers. Always respond in the format that is requested. Do not include additional commentary unless explicitly asked for.",
      max_input_length: 4000,
      model_params: {},
      environment_variables: {
        // OPENAI_API_KEY: "your-key-here",
      },
    },
  },
  {
    name: "Anthropic Config",
    value: {
      server_type: "anthropic",
      base_inference_url: "http://localhost:8000",
      model_prompt_format: "anthropic",
      default_system_prompt:
        "You are an advanced AI legal news assistant working with Law360 to help with reader personalization, custom content curation, and generating in-depth insights to drive engagement and a deep understanding of Law360's customers. Always respond in the format that is requested. Do not include additional commentary unless explicitly asked for.",
      max_input_length: 90000,
      model_params: {},
      environment_variables: {
        // ANTHROPIC_API_KEY: "your-key-here",
      },
    },
  },
  {
    name: "Together Config",
    value: {
      server_type: "together",
      base_inference_url: "http://localhost:8000",
      model_prompt_format: "llama2",
      default_system_prompt:
        "You are an advanced AI legal news assistant working with Law360 to help with reader personalization, custom content curation, and generating in-depth insights to drive engagement and a deep understanding of Law360's customers. Always respond in the format that is requested. Do not include additional commentary unless explicitly asked for.",
      max_input_length: 4000,
      model_params: {},
      environment_variables: {
        // TOGETHER_API_KEY: "your-key-here",
      },
    },
  },
  {
    name: "Text Generation WebUI (Llama2) Config",
    value: {
      server_type: "text-generation-webui",
      base_inference_url: "https://localhost.zpipeliner.com",
      model_prompt_format: "llama2",
      default_system_prompt:
        "You are an advanced AI legal news assistant working with Law360 to help with reader personalization, custom content curation, and generating in-depth insights to drive engagement and a deep understanding of Law360's customers. Always respond in the format that is requested. Do not include additional commentary unless explicitly asked for.",
      max_input_length: 4000,
      model_params: {},
      environment_variables: {},
    },
  },
  {
    name: "Text Generation WebUI (Alpaca) Config",
    value: {
      server_type: "text-generation-webui",
      base_inference_url: "https://localhost.zpipeliner.com",
      model_prompt_format: "alpaca",
      default_system_prompt:
        "You are an advanced AI legal news assistant working with Law360 to help with reader personalization, custom content curation, and generating in-depth insights to drive engagement and a deep understanding of Law360's customers. Always respond in the format that is requested. Do not include additional commentary unless explicitly asked for.",
      max_input_length: 4000,
      model_params: {},
      environment_variables: {},
    },
  },
]

const processorTemplates = [
  {
    name: "Summarization",
    value: {
      default_system_prompt:
        "You are an advanced AI assistant that specializes in content summarization. You are always helpful, you follow all instructions closely, and you avoid making extra comments.",
      prompt: "Summarize the following content:\n\n {text}",
      json_schema: {
        name: "Summarization",
        content: {
          type: "object",
          title: "Summary",
          required: ["summary"],
          properties: {
            summary: {
              type: "string",
              title: "Summary",
              description: "The generated summary.",
            },
          },
        },
      },
      gen_params: {
        temperature: 0.1,
        max_tokens: 300,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
        stop: ["\n", " Human:", " AI:"],
      },
    },
  },
  {
    name: "Tagging",
    value: {
      default_system_prompt:
        "You are an advanced AI assistant that specializes in content tagging. You are always helpful, you follow all instructions closely, and you avoid making extra comments.",
      prompt: "Tag the following content:\n\n {text}",
      json_schema: {
        name: "Tagging",
        content: {
          type: "object",
          title: "Tags",
          required: ["tags"],
          properties: {
            tags: {
              type: "array",
              title: "Tags",
              description: "The generated tags.",
            },
          },
        },
      },
      gen_params: {
        temperature: 0.1,
        max_tokens: 300,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
        stop: ["\n", " Human:", " AI:"],
      },
    },
  },
  {
    name: "Entity Extraction",
    value: {
      default_system_prompt:
        "You are an advanced AI assistant that specializes in entity extraction. You are always helpful, you follow all instructions closely, and you avoid making extra comments.",
      prompt: "Extract entities from the following content:\n\n {text}",
      json_schema: {
        name: "Entity Extraction",
        content: {
          type: "object",
          title: "Entities",
          required: ["entities"],
          properties: {
            entities: {
              type: "array",
              title: "Entities",
              description: "The generated entities.",
            },
          },
        },
      },
      gen_params: {
        temperature: 0.1,
        max_tokens: 300,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
        stop: ["\n", " Human:", " AI:"],
      },
    },
  },
  {
    name: "Article Generation",
    value: {
      default_system_prompt:
        "You are an advanced AI assistant that specializes in article generation. You are always helpful, you follow all instructions closely, and you avoid making extra comments.",
      prompt: "Generate an article about the following topic:\n\n {text}",
      json_schema: {
        name: "Article Generation",
        content: {
          type: "object",
          title: "Article",
          required: ["article"],
          properties: {
            article: {
              type: "string",
              title: "Article",
              description: "The generated article.",
            },
          },
        },
      },
      gen_params: {
        max_tokens: 1500,
        temperature: 0.7,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
        stop: ["\n", " Human:", " AI:"],
      },
    },
  },
]

export const LiveTester: React.FC = () => {
  const { t } = useTranslation()
  const [editorText, setEditorText] = React.useState<string>("")
  const [outputText, setOutputText] = React.useState<string>("")
  const [rows, setRows] = React.useState<IProcessorRow[]>([])
  const [activeId, setActiveId] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalContent, setModalContent] = useState<ReactNode>(null)
  const [selectedLLMConfigTemplate, setSelectedLLMConfigTemplate] =
    useState<any>(llmConfigTemplates[0])
  const [selectedProcessorTemplate, setSelectedProcessorTemplate] =
    useState<any>(processorTemplates[0])

  const myDataProvider = useDataProvider()("default") as any

  //   const myDataProvider = dataProvider(axiosInstance)

  const showModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const showRows = () => {
    setModalContent(
      <div style={{ height: "500px", width: "100%" }}>
        <Typography.Title
          style={{
            textAlign: "center",
            backgroundColor: "lightgray",
            color: "black",
          }}
          level={4}
        >
          Rows
        </Typography.Title>
        <MDEditor
          value={JSON.stringify(rows, null, 4)}
          height={500}
          preview="edit"
        />
      </div>
    )
    showModal()
  }

  const columns = [
    {
      key: "dragHandle",
      dataIndex: "dragHandle",
      title: "Drag",
      width: 30,
      render: () => <MenuOutlined />,
    },
    {
      key: "key",
      dataIndex: "key",
      title: "Key",
    },
  ]

  const handleDefaultSystemPromptChange = useCallback(
    (value: string, recordKey: string) => {
      setRows((oldRows) => [
        ...oldRows.map((row) => {
          if (row.key === recordKey && row.llm_config) {
            row.llm_config.default_system_prompt = value
          }
          return row
        }),
      ])
    },
    []
  )

  const handleJsonSchemaChange = useCallback(
    (value: string, recordKey: string) => {
      setRows((oldRows) => [
        ...oldRows.map((row) => {
          if (row.key === recordKey && row.json_schema) {
            row.json_schema.content = value
          }
          return row
        }),
      ])
    },
    []
  )

  const handlePromptChange = useCallback((value: string, recordKey: string) => {
    setRows((oldRows) => [
      ...oldRows.map((row) => {
        if (row.key === recordKey && row.prompt) {
          row.prompt.text = value
        }
        return row
      }),
    ])
  }, [])

  const handleGenParamsChange = useCallback(
    (value: string, recordKey: string) => {
      setRows((oldRows) => [
        ...oldRows.map((row) => {
          if (row.key === recordKey && row.gen_params) {
            row.gen_params = value
          }
          return row
        }),
      ])
    },
    []
  )

  const handleModelParamsChange = useCallback(
    (value: string, recordKey: string) => {
      setRows((oldRows) => [
        ...oldRows.map((row) => {
          if (row.key === recordKey && row.llm_config?.model_params) {
            row.llm_config.model_params = value
          }
          return row
        }),
      ])
    },
    []
  )

  const handleEnvVariablesChange = useCallback(
    (value: string, recordKey: string) => {
      setRows((oldRows) => [
        ...oldRows.map((row) => {
          if (row.key === recordKey && row.llm_config?.environment_variables) {
            row.llm_config.environment_variables = value
          }
          return row
        }),
      ])
    },
    []
  )

  const handleGenerate = async () => {
    try {
      const result = await myDataProvider.generate({
        resource: "pipelines",
        variables: {
          pipeline: {
            id: uuidv4(),
            name: "test",
            processors: rows.map((row) => {
              return {
                id: row.id,
                name: row.name,
                llm_config: {
                  ...row.llm_config,
                  model_params: JSON5.parse(
                    row.llm_config?.model_params ?? "{}"
                  ),
                  environment_variables: JSON5.parse(
                    row.llm_config?.environment_variables ?? "{}"
                  ),
                },
                json_schema: {
                  name: row.json_schema?.name,
                  content: JSON5.parse(row.json_schema?.content ?? "{}"),
                },
                prompt: row.prompt,
                max_tokens_per_chunk_buffer: row.max_tokens_per_chunk_buffer,
                gen_params: JSON5.parse(row.gen_params ?? "{}"),
              }
            }),
          },
          input_text: editorText,
        },
      })
      console.log("result:", result)

      return result
    } catch (err: any) {
      console.log(err)
      return {
        data: {
          result: "Error",
          error: err.message,
          stacktrace: err.stack,
        },
      }
    }
  }

  const handleUploadSuccess = async (url: string) => {
    console.log("handleUploadSuccess")
    console.log(url)
    const result = await myDataProvider.getFileText({
      resource: "pipelines",
      variables: {
        file_url: url,
      },
    })

    return result
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  function handleDragStart(event: any) {
    const { active } = event
    setActiveId(active.id)
  }

  function handleDragEnd(event: any) {
    const { active, over } = event
    if (active.id !== over.id) {
      setRows((items: any[]) => {
        // In this example, find an item, where `item.key` === `useSortable.id`.
        const oldIndex = items.findIndex(
          (item: { key: any }) => item.key === active.id
        )
        const newIndex = items.findIndex(
          (item: { key: any }) => item.key === over.id
        )
        return arrayMove(items, oldIndex, newIndex)
      })
    }
    // Stop overlay.
    setActiveId(null)
  }

  const renderDeliverables = () => (
    <List
      headerProps={{ style: { marginTop: 20 } }}
      canCreate={true}
      title={
        <Text style={{ fontSize: 22, fontWeight: 800 }}>{t("Processors")}</Text>
      }
      headerButtons={({ defaultButtons }) => (
        <>
          <Select
            defaultValue={selectedLLMConfigTemplate.name}
            style={{ width: 300 }}
            onChange={(value) => {
              setSelectedLLMConfigTemplate(
                llmConfigTemplates.find(
                  (template) => template.name === value
                ) ?? {}
              )
            }}
            options={[
              ...llmConfigTemplates.map((template) => {
                return {
                  label: template.name,
                  value: template.name,
                }
              }),
            ]}
          />
          <Select
            defaultValue={selectedProcessorTemplate.name}
            style={{ width: 300 }}
            onChange={(value) => {
              setSelectedProcessorTemplate(
                processorTemplates.find(
                  (template) => template.name === value
                ) ?? {}
              )
            }}
            options={[
              ...processorTemplates.map((template) => {
                return {
                  label: template.name,
                  value: template.name,
                }
              }),
            ]}
          />

          {defaultButtons}
        </>
      )}
      createButtonProps={{
        onClick: () => {
          console.log("clicked")
          console.log(rows)
          setRows((oldRows) => [
            ...oldRows,
            {
              id: uuidv4(),
              key: uuidv4(),
              name: selectedProcessorTemplate.name,
              llm_config: {
                server_type: selectedLLMConfigTemplate.value.server_type,
                base_inference_url:
                  selectedLLMConfigTemplate.value.base_inference_url,
                model_prompt_format:
                  selectedLLMConfigTemplate.value.model_prompt_format,
                default_system_prompt:
                  selectedProcessorTemplate.value.default_system_prompt,
                max_input_length:
                  selectedLLMConfigTemplate.value.max_input_length,
                model_params: JSON5.stringify(
                  selectedLLMConfigTemplate.value.model_params,
                  null,
                  4
                ),
                environment_variables: JSON5.stringify(
                  selectedLLMConfigTemplate.value.environment_variables,
                  null,
                  4
                ),
              },
              json_schema: {
                name: selectedProcessorTemplate.value.json_schema.name,
                content: JSON5.stringify(
                  selectedProcessorTemplate.value.json_schema.content,
                  null,
                  4
                ),
              },
              prompt: {
                text: selectedProcessorTemplate.value.prompt,
              },
              max_tokens_per_chunk_buffer: 0,
              gen_params: JSON5.stringify(
                selectedProcessorTemplate.value.gen_params,
                null,
                4
              ),
            },
          ])
        },
      }}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <Table
          pagination={false}
          dataSource={rows}
          footer={(_data) => <ProductFooter></ProductFooter>}
          expandable={{
            expandedRowRender: (record) => (
              <Card>
                <div>
                  <ProcessorEditors
                    recordKey={record.key}
                    defaultSystemPrompt={
                      record.llm_config?.default_system_prompt ?? ""
                    }
                    jsonSchema={record.json_schema?.content ?? ""}
                    prompt={record.prompt?.text ?? ""}
                    genParams={record.gen_params ?? ""}
                    modelParams={record.llm_config?.model_params ?? ""}
                    envVariables={
                      record.llm_config?.environment_variables ?? ""
                    }
                    onDefaultSystemPromptChange={
                      handleDefaultSystemPromptChange
                    }
                    onJsonSchemaChange={handleJsonSchemaChange}
                    onPromptChange={handlePromptChange}
                    onGenParamsChange={handleGenParamsChange}
                    onModelParamsChange={handleModelParamsChange}
                    onEnvVariablesChange={handleEnvVariablesChange}
                  />
                </div>
              </Card>
            ),
            rowExpandable: (record: any) => record.name !== "Not Expandable",
            expandRowByClick: false,
          }}
          components={{
            body: {
              wrapper: DraggableWrapper,
              row: DraggableRow,
            },
          }}
        >
          <Table.Column<IProcessorRow>
            key="dragHandle"
            dataIndex="dragHandle"
            title="Drag"
            width={30}
            render={() => <MenuOutlined />}
          />
          <Table.Column<IProcessorRow> key="key" dataIndex="key" title="Key" />
          <Table.Column<IProcessorRow>
            key="name"
            dataIndex="name"
            title="Name"
            render={(value, record) => (
              <Input
                value={record.name}
                onChange={(e) => {
                  setRows((oldRows) => [
                    ...oldRows.map((row) => {
                      if (row.key === record.key && row.name) {
                        row.name = e.target.value
                      }
                      return row
                    }),
                  ])
                }}
              />
            )}
          />
          <Table.Column<IProcessorRow>
            key="server_type"
            dataIndex="llm_config.server_type"
            title="Server Type"
            render={(value, record) => (
              <Input
                value={record.llm_config?.server_type}
                onChange={(e) => {
                  setRows((oldRows) => [
                    ...oldRows.map((row) => {
                      if (row.key === record.key && row.llm_config) {
                        row.llm_config.server_type = e.target.value
                      }
                      return row
                    }),
                  ])
                }}
              />
            )}
          />

          <Table.Column<IProcessorRow>
            key="base_inference_url"
            dataIndex="llm_config.base_inference_url"
            title="Base Inference URL"
            render={(value, record) => (
              <Input
                value={record.llm_config?.base_inference_url}
                onChange={(e) => {
                  setRows((oldRows) => [
                    ...oldRows.map((row) => {
                      if (row.key === record.key && row.llm_config) {
                        row.llm_config.base_inference_url = e.target.value
                      }
                      return row
                    }),
                  ])
                }}
              />
            )}
          />

          <Table.Column<IProcessorRow>
            key="model_prompt_format"
            dataIndex="llm_config.model_prompt_format"
            title="Model Prompt Format"
            render={(value, record) => (
              <Input
                value={record.llm_config?.model_prompt_format}
                onChange={(e) => {
                  setRows((oldRows) => [
                    ...oldRows.map((row) => {
                      if (row.key === record.key && row.llm_config) {
                        row.llm_config.model_prompt_format = e.target.value
                      }
                      return row
                    }),
                  ])
                }}
              />
            )}
          />

          <Table.Column<IProcessorRow>
            key="max_input_length"
            dataIndex="llm_config.max_input_length"
            title="Max Input Length"
            render={(value, record) => (
              <Input
                value={record.llm_config?.max_input_length}
                onChange={(e) => {
                  setRows((oldRows) => [
                    ...oldRows.map((row) => {
                      if (row.key === record.key && row.llm_config) {
                        row.llm_config.max_input_length = parseInt(
                          e.target.value
                        )
                      }
                      return row
                    }),
                  ])
                }}
              />
            )}
          />

          <Table.Column<IProcessorRow>
            key="action"
            title={t("Action")}
            align="center"
            width={100}
            render={(value, record) => (
              <Button
                style={{
                  textAlign: "right",
                }}
                type="primary"
                onClick={() => {
                  console.log("clicked")
                  console.log(rows)
                  setRows((oldRows) => [
                    ...oldRows.filter((row) => row.key !== record.key),
                  ])
                }}
              >
                {t("Remove")}
              </Button>
            )}
          />
        </Table>
        <DragOverlay>
          <Table
            columns={columns}
            showHeader={false}
            dataSource={
              activeId
                ? new Array(1).fill(
                    rows[
                      rows.findIndex(
                        (item: { key: any }) => item.key === activeId
                      )
                    ]
                  )
                : []
            }
            pagination={false}
          />
        </DragOverlay>
      </DndContext>
    </List>
  )

  return (
    <>
      <Modal
        title="Extra Info"
        open={modalIsOpen}
        onOk={closeModal}
        onCancel={closeModal}
      >
        {modalContent}
      </Modal>
      <InputOutputEditors
        editorText={editorText ?? ""}
        handleEditorChange={setEditorText}
        outputText={outputText ?? ""}
        handleOutputChange={setOutputText}
        showRows={showRows}
        handleGenerate={handleGenerate}
        handleUploadSuccess={handleUploadSuccess}
      />
      {renderDeliverables()}
    </>
  )
}
