import { ThemedSiderV2 } from "@refinedev/antd"
import { Title } from "../title"
import {
  useTranslate,
  useLogout,
  useIsExistAuthentication,
  useActiveAuthProvider,
  useWarnAboutChange,
} from "@refinedev/core"

import { Menu } from "antd"
import { CloseSquareTwoTone } from "@ant-design/icons"

export const Sider = () => {
  const isExistAuthentication = useIsExistAuthentication()
  const { warnWhen, setWarnWhen } = useWarnAboutChange()
  const translate = useTranslate()
  const authProvider = useActiveAuthProvider()
  const { mutate: mutateLogout } = useLogout({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  })
  const handleLogout = () => {
    if (warnWhen) {
      const confirm = window.confirm(
        translate(
          "warnWhenUnsavedChanges",
          "Are you sure you want to leave? You have unsaved changes."
        )
      )

      if (confirm) {
        setWarnWhen(false)
        mutateLogout()
      }
    } else {
      mutateLogout()
    }
  }

  const myLogout = isExistAuthentication && (
    <Menu.Item
      key="logout"
      onClick={() => handleLogout()}
      icon={<CloseSquareTwoTone />}
    >
      {translate("buttons.logout", "Logout")}
    </Menu.Item>
  )
  return (
    <ThemedSiderV2
      Title={({ collapsed }: any) => <Title collapsed={collapsed} />}
      render={({ items, logout, collapsed }: any) => {
        return (
          <>
            {items}
            {myLogout}
          </>
        )
      }}
    />
  )
}
