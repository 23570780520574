import React, { useEffect, useState } from "react"
import { Authenticated, Refine, HttpError } from "@refinedev/core"
import {
  ThemedLayoutV2,
  notificationProvider,
  ErrorComponent,
} from "@refinedev/antd"
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6"
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom"
import { RefineKbarProvider } from "@refinedev/kbar"
import {
  DashboardTwoTone,
  CodeTwoTone,
  ExperimentTwoTone,
  FileTextTwoTone,
  ContainerTwoTone,
  ControlTwoTone,
  GoldTwoTone,
  ThunderboltTwoTone,
  BuildTwoTone
} from "@ant-design/icons"
import { Title, Header, Sider } from "./components"
import {
  PipelineList,
  PipelineCreate,
  PipelineEdit,
  PipelineShow,
  JsonSchemaList,
  JsonSchemaCreate,
  JsonSchemaEdit,
  JsonSchemaShow,
  PromptList,
  PromptCreate,
  PromptEdit,
  PromptShow,
  LlmConfigList,
  LlmConfigCreate,
  LlmConfigEdit,
  LlmConfigShow,
  ProcessorList,
  ProcessorCreate,
  ProcessorEdit,
  ProcessorShow,
  LiveTester,
  DashboardPage,
  PipelineEditorPage,
  PipelineRunnerPage
} from "./pages"
import { sec } from "./sec"
import axios, { AxiosRequestConfig } from "axios"
import { authProvider } from "./authProvider"
import { dataProvider } from "./dataProvider"
import { ConfigProvider } from "./context"
import "@refinedev/antd/dist/reset.css"
import "./App.css"
import { useTranslation } from "react-i18next"
import { Login } from "./pages/login"
import { useAuth0 } from "@auth0/auth0-react"
import { TOKEN_KEY } from "./constants"

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
  const token = localStorage.getItem(TOKEN_KEY)
  if (token) {
    if (request.headers) {
      request.headers["Authorization"] = `Bearer ${token}`
    } else {
      request.headers = {
        Authorization: `Bearer ${token}`,
      }
    }
  }

  return request
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      errors: error.response?.data?.errors,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    }

    return Promise.reject(customError)
  }
)

export { axiosInstance }

export const myDataProvider = dataProvider(axiosInstance)

export const useBeforeRender = (callback: any, deps: any) => {
  const [isRun, setIsRun] = useState(false)

  if (!isRun) {
    callback()
    setIsRun(true)
  }

  useEffect(() => () => setIsRun(false), deps)
}

const App: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0()
  sec.setAccessTokenSilently(getAccessTokenSilently)

  const { t, i18n } = useTranslation()

  const i18nProvider = {
    translate: (key: any, params: any) => t(key, params) as string,
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  }

  useBeforeRender(() => {
    window.addEventListener("error", (e) => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        )
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        )
        if (resizeObserverErr)
          resizeObserverErr.className = "hide-resize-observer"
        if (resizeObserverErrDiv)
          resizeObserverErrDiv.className = "hide-resize-observer"
      }
    })
  }, [])

  const { isLoading, user, logout, getIdTokenClaims } = useAuth0()

  return (
    <BrowserRouter>
      <ConfigProvider>
        <RefineKbarProvider>
          <Refine
            routerProvider={routerBindings}
            dataProvider={myDataProvider}
            authProvider={authProvider(
              axiosInstance,
              user,
              getIdTokenClaims,
              logout
            )}
            i18nProvider={i18nProvider}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
            notificationProvider={notificationProvider}
            resources={[
              {
                name: "dashboard",
                list: "/",
                meta: {
                  label: "Dashboard",
                  icon: <DashboardTwoTone />,
                },
              },
              {
                name: "pipeline_editor",
                list: "/editor",
                meta: {
                  label: "Pipeline Editor",
                  icon: <BuildTwoTone />,
                },
              },
              {
                name: "pipeline_runner",
                list: "/run",
                meta: {
                  label: "Pipeline Runner",
                  icon: <ThunderboltTwoTone />,
                },
              },
              {
                name: "live_tester",
                list: "/live_tester",
                meta: {
                  label: "Live Tester",
                  icon: <ExperimentTwoTone />,
                },
              },

              {
                name: "pipelines",
                list: "/pipelines",
                create: "/pipelines/create",
                edit: "/pipelines/edit/:id",
                show: "/pipelines/show/:id",
                meta: {
                  icon: <GoldTwoTone />,
                },
              },
              {
                name: "processors",
                list: "/processors",
                create: "/processors/create",
                edit: "/processors/edit/:id",
                show: "/processors/show/:id",
                meta: {
                  icon: <CodeTwoTone />,
                },
              },
              {
                name: "prompts",
                list: "/prompts",
                create: "/prompts/create",
                edit: "/prompts/edit/:id",
                show: "/prompts/show/:id",
                meta: {
                  icon: <FileTextTwoTone />,
                },
              },
              {
                name: "json_schemas",
                list: "/json_schemas",
                create: "/json_schemas/create",
                edit: "/json_schemas/edit/:id",
                show: "/json_schemas/show/:id",
                meta: {
                  icon: <ContainerTwoTone />,
                },
              },
              {
                name: "llm_configs",
                list: "/llm_configs",
                create: "/llm_configs/create",
                edit: "/llm_configs/edit/:id",
                show: "/llm_configs/show/:id",
                meta: {
                  icon: <ControlTwoTone />,
                },
              },
            ]}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />} key="authenticated">
                    <ThemedLayoutV2
                      Header={Header}
                      Title={Title}
                      Sider={Sider}
                    >
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route index element={<DashboardPage />} />
                <Route path="/editor">
                  <Route index element={<PipelineEditorPage />} />
                </Route>
                <Route path="/run">
                  <Route index element={<PipelineRunnerPage />} />
                </Route>
                <Route path="/live_tester">
                  <Route index element={<LiveTester />} />
                </Route>
                <Route path="/pipelines">
                  <Route index element={<PipelineList />} />
                  <Route path="show/:id" element={<PipelineShow />} />
                  <Route path="edit/:id" element={<PipelineEdit />} />
                  <Route path="create" element={<PipelineCreate />} />
                </Route>
                <Route path="/processors">
                  <Route index element={<ProcessorList />} />
                  <Route path="show/:id" element={<ProcessorShow />} />
                  <Route path="edit/:id" element={<ProcessorEdit />} />
                  <Route path="create" element={<ProcessorCreate />} />
                </Route>
                <Route path="/prompts">
                  <Route index element={<PromptList />} />
                  <Route path="show/:id" element={<PromptShow />} />
                  <Route path="edit/:id" element={<PromptEdit />} />
                  <Route path="create" element={<PromptCreate />} />
                </Route>
                <Route path="/json_schemas">
                  <Route index element={<JsonSchemaList />} />
                  <Route path="show/:id" element={<JsonSchemaShow />} />
                  <Route path="edit/:id" element={<JsonSchemaEdit />} />
                  <Route path="create" element={<JsonSchemaCreate />} />
                </Route>
                <Route path="/llm_configs">
                  <Route index element={<LlmConfigList />} />
                  <Route path="show/:id" element={<LlmConfigShow />} />
                  <Route path="edit/:id" element={<LlmConfigEdit />} />
                  <Route path="create" element={<LlmConfigCreate />} />
                </Route>
                <Route path="*" element={<ErrorComponent />} />
              </Route>
              <Route
                element={
                  <Authenticated fallback={<Outlet />} key="authenticated">
                    <NavigateToResource resource="dashboard" />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
              </Route>

              <Route
                element={
                  <Authenticated key="authenticated">
                    <ThemedLayoutV2
                      Header={Header}
                      Title={Title}
                      Sider={Sider}
                    >
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>
            <UnsavedChangesNotifier />
            <DocumentTitleHandler />
          </Refine>
        </RefineKbarProvider>
      </ConfigProvider>
    </BrowserRouter>
  )
}

export default App
