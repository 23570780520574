import { useEffect, useState } from "react"
import { useTranslate, useUpdate } from "@refinedev/core"
import {
  Action,
  createAction,
  Priority,
  useKBar,
  useRegisterActions,
} from "@refinedev/kbar"
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"

interface IPipeline {
  id: number
  name: string
  processors: any[]
  created_at: string
  updated_at: string
}

export const usePipelineCustomKbarActions = (pipeline?: IPipeline): void => {
  const t = useTranslate()
  const { mutate } = useUpdate()

  const { query } = useKBar()

  const fakeStatus = {
    text: "Pending",
  }

  const canAcceptPipeline = fakeStatus.text === "Pending"
  const canRejectPipeline =
    fakeStatus.text === "Pending" ||
    fakeStatus.text === "Ready" ||
    fakeStatus.text === "On The Way"

  const [actions, setActions] = useState<Action[]>([])

  const handleMutate = (status: { id: number; text: string }) => {
    // if (pipeline) {
    //   mutate(
    //     {
    //       resource: "pipelines",
    //       id: pipeline.id.toString(),
    //       values: {
    //         status,
    //       },
    //     },
    //     {
    //       onSuccess: () => setActions([]),
    //     }
    //   )
    // }
  }

  useEffect(() => {
    const preActions: Action[] = []
    if (canAcceptPipeline) {
      preActions.push(
        createAction({
          name: t("buttons.accept"),
          icon: <CheckCircleOutlined />,
          section: "actions",
          perform: () => {
            handleMutate({
              id: 2,
              text: "Ready",
            })
          },
          priority: Priority.HIGH,
        })
      )
    }
    if (canRejectPipeline) {
      preActions.push(
        createAction({
          name: t("buttons.reject"),
          icon: <CloseCircleOutlined />,
          section: "actions",
          perform: () => {
            handleMutate({
              id: 5,
              text: "Cancelled",
            })
          },
          priority: Priority.HIGH,
        })
      )
    }
    setActions(preActions)
  }, [pipeline])

  useRegisterActions(actions, [actions])
  //   useRegisterActions(!query ? [] : actions, [true, query])
}
