import { useState, useEffect } from "react"
import {
  useGetLocale,
  useSetLocale,
  useGetIdentity,
  useTranslate,
  useList,
} from "@refinedev/core"
import { Link } from "react-router-dom"

import {
  Avatar,
  Typography,
  Space,
  Grid,
  Row,
  Col,
  Layout as AntdLayout,
  Button,
  theme,
} from "antd"
import { useAuth0 } from "@auth0/auth0-react"

import { useTranslation } from "react-i18next"

import { useConfigProvider } from "../../context"
import { IconMoon, IconSun } from "../../components/icons"
import {
  IPipeline,
  IProcessor,
  IPrompt,
  ISchema,
  ILLMConfig,
  IIdentity,
} from "../../interfaces"
import { HeaderTitle } from "./styled"
import { TOKEN_KEY } from "../../constants"

const { Header: AntdHeader } = AntdLayout
const { useToken } = theme
const { Text } = Typography
const { useBreakpoint } = Grid

interface IOptionGroup {
  value: string
  label: string | React.ReactNode
}

interface IOptions {
  label: string | React.ReactNode
  options: IOptionGroup[]
}

export const Header: React.FC = () => {
  const {
    user: auth0User,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0()
  const [userMetadata, setUserMetadata] = useState(null)
  const { token } = useToken()
  const { mode, setMode } = useConfigProvider()
  const { i18n } = useTranslation()
  const locale = useGetLocale()
  const changeLanguage = useSetLocale()
  const { data: user } = useGetIdentity<IIdentity>()
  const screens = useBreakpoint()
  const t = useTranslate()

  const currentLocale = locale()

  const renderTitle = (title: string) => (
    <HeaderTitle>
      <Text style={{ fontSize: "16px" }}>{title}</Text>
      <Link to={`/${title.toLowerCase().replace(/ /g, "_")}`}>
        {t("search.more")}
      </Link>
    </HeaderTitle>
  )

  const renderItem = (title: string, imageUrl: string, link: string) => ({
    value: title,
    label: (
      <Link to={link} style={{ display: "flex", alignItems: "center" }}>
        <Avatar size={64} src={imageUrl} style={{ minWidth: "64px" }} />
        <Text style={{ marginLeft: "16px" }}>{title}</Text>
      </Link>
    ),
  })

  const [value, setValue] = useState<string>("")
  const [options, setOptions] = useState<IOptions[]>([])

  const { refetch: refetchPipelines } = useList<IPipeline>({
    resource: "pipelines",
    config: {
      filters: [{ field: "q", operator: "contains", value }],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data: any) => {
        console.log("data: ", data)
        const pipelineOptionGroup = data.map((item: any) =>
          renderItem(
            `${item.name} / #${item.id}`,
            "/images/default-order-img.png",
            `/pipelines/show/${item.id}`
          )
        )
        if (pipelineOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle(t("Pipelines")),
              options: pipelineOptionGroup,
            },
          ])
        }
      },
    },
  })

  const { refetch: refetchProcessors } = useList<IProcessor>({
    resource: "processors",
    config: {
      filters: [{ field: "q", operator: "contains", value }],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data: any) => {
        const processorOptionGroup = data.map((item: any) =>
          renderItem(
            item.name,
            "/images/default-store-img.png",
            `/processors/edit/${item.id}`
          )
        )
        if (processorOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle(t("Processors")),
              options: processorOptionGroup,
            },
          ])
        }
      },
    },
  })

  const { refetch: refetchPrompts } = useList<IPrompt>({
    resource: "prompts",
    config: {
      filters: [{ field: "q", operator: "contains", value }],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data: any) => {
        const promptOptionGroup = data.map((item: any) =>
          renderItem(
            `${item.name}`,
            "/images/default-store-img.png",
            `/prompts/show/${item.id}`
          )
        )
        if (promptOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle(t("Prompts")),
              options: promptOptionGroup,
            },
          ])
        }
      },
    },
  })

  const { refetch: refetchSchemas } = useList<ISchema>({
    resource: "json_schemas",
    config: {
      filters: [{ field: "q", operator: "contains", value }],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data: any) => {
        const schemaOptionGroup = data.map((item: any) =>
          renderItem(
            `${item.name}`,
            "/images/default-store-img.png",
            `/json_schemas/show/${item.id}`
          )
        )
        if (schemaOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle(t("JSON Schemas")),
              options: schemaOptionGroup,
            },
          ])
        }
      },
    },
  })

  const { refetch: refetchLLMConfigs } = useList<ILLMConfig>({
    resource: "llm_configs",
    config: {
      filters: [{ field: "q", operator: "contains", value }],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data: any) => {
        const llmConfigOptionGroup = data.map((item: any) =>
          renderItem(
            `${item.name}`,
            "/images/default-store-img.png",
            `/llm_configs/show/${item.id}`
          )
        )
        if (llmConfigOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle(t("LLM Configs")),
              options: llmConfigOptionGroup,
            },
          ])
        }
      },
    },
  })

  useEffect(() => {
    const getAccessToken = async () => {
      const accessToken = await getAccessTokenSilently({
        // authorizationParams: {
        //   audience: "https://zpipeliner-api/",
        // },
      })

      console.log("accessToken: ", accessToken)

      localStorage.setItem(TOKEN_KEY, accessToken)
      localStorage.setItem("auth0User", JSON.stringify(auth0User))
      // const getUserMetadata = async () => {
      //   const domain = "dev-wt7n772n8mijfpvk.us.auth0.com"

      //   console.log("user: ", user)
      //   console.log("auth0User: ", auth0User)

      //   try {
      //     const accessToken = await getAccessTokenSilently({
      //       authorizationParams: {
      //         audience: `https://${domain}/api/v2/`,
      //         scope: "read:current_user",
      //         refresh_token: auth0User?.refresh_token,
      //       },
      //     })

      //     const accessToken2 = await getAccessTokenSilently({
      //       authorizationParams: {
      //         audience: `https://${domain}/api/v2/`,
      //         scope: "test:read",
      //         refresh_token: auth0User?.refresh_token,
      //       },
      //     })

      //     console.log("accessToken: ", accessToken)
      //     console.log("accessToken2: ", accessToken2)
      //     console.log("auth0User: ", auth0User)

      //     const sub = get(auth0User, "sub", null)

      //     const userDetailsByIdUrl = `https://${domain}/api/v2/users/${sub}`

      //     console.log("userDetailsByIdUrl: ", userDetailsByIdUrl)

      //     const metadataResponse = await fetch(userDetailsByIdUrl, {
      //       headers: {
      //         Authorization: `Bearer ${accessToken}`,
      //       },
      //     })

      //     console.log("metadataResponse: ", metadataResponse)

      //     const userMetadataJson = await metadataResponse.json()

      //     console.log("userMetadataJson: ", userMetadataJson)

      //     const user_metadata = userMetadataJson.user_metadata

      //     console.log("user_metadata: ", user_metadata)
      //     setUserMetadata(user_metadata)
      //   } catch (e: any) {
      //     console.log(e.message)
      //   }
      // }
      // getUserMetadata()
    }
    getAccessToken()
  }, [getAccessTokenSilently, auth0User?.sub])

  //   useEffect(() => {
  //     setOptions([])
  //     refetchPipelines()
  //     refetchProcessors()
  //     refetchPrompts()
  //     refetchSchemas()
  //     refetchLLMConfigs()
  //   }, [value])

  //   const menuItems: MenuProps["items"] = [...(i18n.languages || [])]
  //     .sort()
  //     .map((lang: string) => ({
  //       key: lang,
  //       onClick: () => changeLanguage(lang),
  //       icon: (
  //         <span style={{ marginRight: 8 }}>
  //           <Avatar size={16} src={`/images/flags/${lang}.svg`} />
  //         </span>
  //       ),
  //       label: lang === "en" ? "English" : "German",
  //     }))

  return (
    <AntdHeader
      style={{
        backgroundColor: token.colorBgElevated,
        padding: "0 24px",
        position: "sticky",
        top: 0,
        zIndex: 1,
      }}
    >
      <Row
        align="middle"
        style={{
          justifyContent: screens.sm ? "space-between" : "end",
        }}
      >
        <Col xs={0} sm={12}>
          {/* <AutoComplete
            style={{
              width: "100%",
              maxWidth: "550px",
            }}
            options={options}
            filterOption={false}
            onSearch={debounce((value: string) => setValue(value), 300)}
          >
            <Input
              size="large"
              placeholder={t("search.placeholder")}
              suffix={<SearchOutlined />}
            />
          </AutoComplete> */}
        </Col>
        <Col>
          <Space size="middle" align="center">
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              type="default"
              icon={mode === "light" ? <IconMoon /> : <IconSun />}
              onClick={() => {
                setMode(mode === "light" ? "dark" : "light")
              }}
            />
            {/* <Dropdown
                            menu={{
                                items: menuItems,
                                selectedKeys: currentLocale
                                    ? [currentLocale]
                                    : [],
                            }}
                        >
                            <a
                                style={{ color: "inherit" }}
                                onClick={(e) => e.preventDefault()}
                            >
                                <Space>
                                    <Avatar
                                        size={16}
                                        src={`/images/flags/${currentLocale}.svg`}
                                    />
                                    <div
                                        style={{
                                            display: screens.lg
                                                ? "block"
                                                : "none",
                                        }}
                                    >
                                        {currentLocale === "en"
                                            ? "English"
                                            : "German"}
                                        <DownOutlined
                                            style={{
                                                fontSize: "12px",
                                                marginLeft: "6px",
                                            }}
                                        />
                                    </div>
                                </Space>
                            </a>
                        </Dropdown> */}

            <Text
              ellipsis
              strong
              style={{
                display: "flex",
              }}
            >
              {user?.name}
            </Text>
            <Avatar size="large" src={user?.avatar} alt={user?.name} />
          </Space>
        </Col>
      </Row>
    </AntdHeader>
  )
}
