import React from "react"
import { IResourceComponentsProps } from "@refinedev/core"
import { Create, useForm, useSelect } from "@refinedev/antd"
import { Form, Input, Select, InputNumber, Checkbox } from "antd"
import _ from 'lodash';


export const ProcessorCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm()

  const [selectedLLMConfig, setSelectedLLMConfig] = React.useState<any>(null)
  const [selectedJsonSchema, setSelectedJsonSchema] = React.useState<any>(null)
  const [selectedPrompt, setSelectedPrompt] = React.useState<any>(null)

  const { selectProps: llmConfigSelectProps } = useSelect({
    resource: "llm_configs",
    optionLabel: "name",
  })

  const { selectProps: jsonSchemaSelectProps } = useSelect({
    resource: "json_schemas",
    optionLabel: "name",
  })

  const { selectProps: promptSelectProps } = useSelect({
    resource: "prompts",
    optionLabel: "name",
  })

  const handleOnFinish = (values: any) => {
    console.log("handleOnFinish: ", values)
    console.log("selectedLLMConfig:", selectedLLMConfig)
    console.log("selectedJsonSchema:", selectedJsonSchema)
    console.log("selectedPrompt:", selectedPrompt)
    values = _.omit(values, ["llm_config", "json_schema", "prompt"])
    const gen_params = JSON.parse(values.gen_params)
    const metadata = JSON.parse(values.metadata)

    onFinish({
      ...values,
      llm_config_id:
        selectedLLMConfig !== undefined ? selectedLLMConfig : null,
      json_schema_id:
        selectedJsonSchema !== undefined ? selectedJsonSchema : null,
      prompt_id: selectedPrompt !== undefined ? selectedPrompt : null,
      gen_params: gen_params,
      metadata: metadata,
    })
  }

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        initialValues={{ ["max_tokens_per_chunk_buffer"]: 0 }}
        layout="vertical"
        onFinish={handleOnFinish}
      >
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Llm Config"
          name={"llm_config"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...llmConfigSelectProps} onChange={setSelectedLLMConfig} />
        </Form.Item>
        <Form.Item
          label="Json Schema"
          name={"json_schema"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...jsonSchemaSelectProps} onChange={setSelectedJsonSchema} />
        </Form.Item>
        <Form.Item
          label="Prompt"
          name={"prompt"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...promptSelectProps} onChange={setSelectedPrompt} />
        </Form.Item>
        <Form.Item
          label="Max Tokens Per Chunk Buffer"
          name={["max_tokens_per_chunk_buffer"]}
          rules={[
            {
              type: "number",
            },
          ]}
        >
          <InputNumber type="number" min={0} max={100000} defaultValue={0} />
        </Form.Item>
        <Form.Item
          label="Generation Params"
          name={["gen_params"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
        <Form.Item
          label="Is Active"
          valuePropName="checked"
          name={["is_active"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Checkbox defaultChecked checked>
            Is Active
          </Checkbox>
        </Form.Item>
        <Form.Item
          label="Metadata"
          name={["metadata"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
      </Form>
    </Create>
  )
}