"use client";

import { FunctionCallHandler } from "ai";
import React from "react";
import { TreeNodeId } from "../hooks/use-tree";
import { AnnotatedFunction } from "../types/annotated-function";
import { ChatCompletionCreateParams } from "openai/resources/chat";
import { DocumentPointer } from "../types";

/**
 * Interface for the configuration of the Pipeline Assistant API.
 */
export interface PipelineAssistantApiConfig {
    /**
     * The endpoint for the chat API.
     */
    chatApiEndpoint: string;

    /**
     * additional headers to be sent with the request
     * @default {}
     * @example
     * ```
     * {
     *   'Authorization': 'Bearer your_token_here'
     * }
     * ```
     */
    headers: Record<string, string>;

    /**
     * Additional body params to be sent with the request
     * @default {}
     * @example
     * ```
     * {
     *   'message': 'Hello, world!'
     * }
     * ```
     */
    body: Record<string, any>;

    /**
     * The API key to use for the Pipeline Assistant API.
     */
    apiKey: string;

}

export function pipelineAssistantApiConfigExtrapolator(config: PipelineAssistantApiConfig) {
    return {
        get chatApiEndpoint(): string {
            return `${config.chatApiEndpoint}`;
        },
        get headers(): Record<string, string> {
            return config.headers || {};
        },
        get body(): Record<string, any> {
            return config.body || {};
        },
        get apiKey(): string {
            return `${config.apiKey}`;
        }
    };
}

export interface PipelineAssistantContextParams {
    // function-calling
    entryPoints: Record<string, AnnotatedFunction<any[]>>;
    setEntryPoint: (id: string, entryPoint: AnnotatedFunction<any[]>) => void;
    removeEntryPoint: (id: string) => void;
    getChatCompletionFunctionDescriptions: () => ChatCompletionCreateParams.Function[];
    getFunctionCallHandler: () => FunctionCallHandler;

    // text context
    getContextString: (
        documents: DocumentPointer[],
        categories?: string[]
    ) => string;
    addContext: (
        context: string,
        parentId?: string,
        categories?: string[]
    ) => TreeNodeId;
    removeContext: (id: TreeNodeId) => void;

    // document context
    getDocumentsContext: (categories?: string[]) => DocumentPointer[];
    addDocumentContext: (
        documentPointer: DocumentPointer,
        categories?: string[]
    ) => TreeNodeId;
    removeDocumentContext: (documentId: string) => void;

    // api endpoints
    pipelineAssistantApiConfig: PipelineAssistantApiConfig;
}

const emptyPipelineAssistantContext: PipelineAssistantContextParams = {
    entryPoints: {},
    setEntryPoint: () => { },
    removeEntryPoint: () => { },
    getChatCompletionFunctionDescriptions: () => returnAndThrowInDebug([]),
    getFunctionCallHandler: () => returnAndThrowInDebug(async () => { }),

    getContextString: (documents: DocumentPointer[]) => returnAndThrowInDebug(""),
    addContext: () => "",
    removeContext: () => { },

    getDocumentsContext: () => returnAndThrowInDebug([]),
    addDocumentContext: () => returnAndThrowInDebug(""),
    removeDocumentContext: () => { },

    pipelineAssistantApiConfig: new (class implements PipelineAssistantApiConfig {
        get chatApiEndpoint(): string {
            throw new Error(
                "Remember to wrap your app in a `<PipelineAssistantProvider> {...} </PipelineAssistantProvider>` !!!"
            );
        }
        get headers(): Record<string, string> {
            return {};
        }
        get body(): Record<string, any> {
            return {};
        }
        get apiKey(): string {
            throw new Error(
                "Remember to wrap your app in a `<PipelineAssistantProvider> {...} </PipelineAssistantProvider>` !!!"
            );
        }
    })(),
};

export const PipelineAssistantContext =
    React.createContext<PipelineAssistantContextParams>(emptyPipelineAssistantContext);

function returnAndThrowInDebug<T>(value: T): T {
    throw new Error(
        "Remember to wrap your app in a `<PipelineAssistantProvider> {...} </PipelineAssistantProvider>` !!!"
    );
    return value;
}