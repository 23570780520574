import React, { useState } from "react"
import { IResourceComponentsProps, useList } from "@refinedev/core"
import { Edit, useForm, useSelect } from "@refinedev/antd"
import { Form, Input, Checkbox } from "antd"
import MultiSelectSort from "../../components/processorSelection"

interface OptionType {
  value: string
  label: string
}

export const PipelineEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm()

  const pipelinesData = queryResult?.data?.data

  //   const { selectProps: processorsSelectProps } = useSelect({
  //     resource: "processors",
  //     defaultValue: pipelinesData?.processors,
  //     optionLabel: "name",
  //     optionValue: "id",
  //   })

  const { selectProps: processorsSelectProps } = useSelect({
    resource: "processors",
    defaultValue: pipelinesData?.processors?.map((item: any) => item?.id),
    optionLabel: "name",
    debounce: 1000,
  })

  const processorList = useList({
    resource: "processors",
  })

  const getValues = () => {
    console.log("getValues")
    console.log(processorsSelectProps)
    if (
      //   processorsSelectProps.value == undefined ||
      //   processorsSelectProps.value == null ||
      processorsSelectProps.options == undefined
    ) {
      return []
    } else {
      const values = pipelinesData?.processors.map((item: any) => {
        return processorsSelectProps.options!!.find(
          (option) => option.value === item.id
        )
      })
      console.log("values:", values)
      return values
    }
  }

  const [selectedOptions, setSelectedOptions] = useState<any[]>(
    getValues() as any[]
  )

  // const { queryResult: qR } = useSelect({
  //   resource: "processors",
  //   defaultValue: pipelinesData?.processors,
  // })

  //   const value = pipelinesData?.processors.map((item: any) => {
  //     if (options && options.length > 0) {
  //     }
  //     return
  //   }

  //   const value = () => {
  //     console.log("value!")
  //     console.log("options: ", options)
  //     if (options === undefined) {
  //       return undefined
  //     }
  //     const values = pipelinesData?.processors.map((item: any) => {
  //       return options.find((option) => option.value === item)
  //     })
  //     console.log("values: ", values)
  //     return values
  //   }

  const handleOnFinish = (values: any) => {
    console.log("handleOnFinish: ", values)
    console.log("selectedOptions:", selectedOptions)
    // const processors = values.processors.map((processor: any) => {
    //   console.log("processor: ", processor)
    //   if (processor.value !== undefined) {
    //     const matches = processorList.data?.data.find(
    //       (item: any) => item.id === processor.value
    //     )
    //     if (matches !== undefined) {
    //       return matches.map((item: any) => {
    //         return { id: item.id }
    //       })
    //     }
    //     return []
    //   } else {
    //     return { id: processor.id }
    //   }
    // })

    if (selectedOptions === undefined) {
      onFinish({
        ...values,
      })
    } else {
      const processors = selectedOptions.map((processor: any) => {
        return { id: processor.value }
      })

      console.log("processors", processors)
      console.log("processorList:", processorList)
      onFinish({
        ...values,
        processors: processors,
        //   processors: selectedOptions.map((option: any) => option.value),
        //   processors: values.processors.map((processor: any) => processor.value),
      })
    }
  }

  console.log("props:", processorsSelectProps)

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
        {/* <Form {...formProps} layout="vertical"> */}
        <Form.Item
          label="Id"
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Processors"
          name={"processors"}
          rules={[
            {
              required: false,
            },
          ]}
          getValueProps={(value: any[]) => {
            return {
              value: value?.map((item) => item?.id),
            }
          }}
          getValueFromEvent={(selected: string[]) => {
            return selected?.map((item) => ({ id: item }))
          }}
        >
          {/* <MultiSelectSort
            options={
              processorsSelectProps.options !== undefined
                ? (processorsSelectProps.options as OptionType[])
                : []
            }
            values={getValues()}
            onChange={setSelectedOptions}
          />
           */}

          {/* <Select mode="multiple" {...processorsSelectProps} /> */}
          {processorsSelectProps.loading ? (
            <p>Loading...</p>
          ) : (
            <MultiSelectSort
              key={
                "processorsMS" +
                getValues()?.map((item: any) => item?.value.toString())
              }
              {...processorsSelectProps}
              options={processorsSelectProps.options as OptionType[]}
              values={getValues()}
              // values={selectedOptions}
              onChange={setSelectedOptions}
              getValueProps={(value: any[]) => {
                return {
                  value: value?.map((item) => item?.id),
                }
              }}
            />
          )}

          {/* <MultiSelectSort
            // options={
            //   processorsSelectProps.options !== undefined
            //     ? (processorsSelectProps.options as OptionType[])
            //     : []
            // }
            // values={getValues()}
            // onChange={setSelectedOptions}
            {...processorsSelectProps}
            options={processorsSelectProps.options as OptionType[]}
            onChange={setSelectedOptions}
            values={getValues()}
          /> */}
          {/* <Select mode="multiple" {...processorsSelectProps} /> */}
          {/* <OrderedSelect {...processorsSelectProps} /> */}
          {/* <SortableSelectInput {...processorsSelectProps} /> */}
        </Form.Item>
        {/* <Form.Item
          label="Created At"
          name={["createdAt"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Updated At"
          name={["updatedAt"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item> */}
        <Form.Item
          label="Is Active"
          valuePropName="checked"
          name={["is_active"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Checkbox>Is Active</Checkbox>
        </Form.Item>
      </Form>
    </Edit>
  )
}
