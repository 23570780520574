import { CrudFilters, CrudOperators } from "@refinedev/core"
import restDataProvider from "@refinedev/simple-rest"
import { stringify } from "query-string"
import { AxiosInstance } from "axios"
import { API_URL, JOB_STATUS_URL, PIPELINE_OUTPUT_URL } from "./constants"

type MethodTypes = "get" | "delete" | "head" | "options"
type MethodTypesWithBody = "post" | "put" | "patch"

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case "eq":
      return ""
    case "contains":
      return "__contains"
    default:
      throw new Error(`Operator ${operator} is not supported`)
  }
}

const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: string } = {}
  if (filters) {
    filters.map((filter: any) => {
      if (
        filter.operator !== "or" &&
        filter.operator !== "and" &&
        "field" in filter
      ) {
        const { field, operator, value } = filter

        const mappedOperator = mapOperator(operator)
        queryFilters[`${field}${mappedOperator}`] = value
      }
    })
  }

  return queryFilters
}

export const dataProvider = (axios: AxiosInstance): any => {
  return {
    ...restDataProvider(API_URL, axios as any),

    getList: async ({ resource, pagination, filters, meta }: any) => {
      const url = `${API_URL}/${resource}/`
      const { current = 1, pageSize = 10, mode = "server" } = pagination ?? {}
      const { headers: headersFromMeta, method } = meta ?? {}
      const requestMethod = (method as MethodTypes) ?? "get"
      const queryFilters = generateFilter(filters)

      const query: {
        _start?: number
        _end?: number
        _sort?: string
        _order?: string
      } = {}

      if (mode === "server") {
        query._start = (current - 1) * pageSize
        query._end = current * pageSize
      }

      const { data, headers } = await axios[requestMethod](
        `${url}?${stringify(query)}&${stringify(queryFilters)}`,
        {
          headers: headersFromMeta,
        }
      )

      const total = +headers["x-total-count"]

      return {
        data,
        total: total || data.length,
      }
    },
    getMany: async ({ resource, ids, meta }: any) => {
      const { headers, method } = meta ?? {}
      const requestMethod = (method as MethodTypes) ?? "get"

      const { data } = await axios[requestMethod](
        `${API_URL}/${resource}/?${stringify({ id: ids })}`,
        { headers }
      )

      return {
        data,
      }
    },
    getOne: async ({ resource, id, meta }: any) => {
      const url = `${API_URL}/${resource}/${id}/`

      const { headers, method } = meta ?? {}
      const requestMethod = (method as MethodTypes) ?? "get"

      const { data } = await axios[requestMethod](url, { headers })

      return {
        data,
      }
    },

    create: async ({ resource, variables, meta }: any) => {
      const url = `${API_URL}/${resource}/`

      const { headers } = meta ?? {}

      const { data } = await axios.post(url, variables, {
        headers,
      })

      return {
        data,
      }
    },
    update: async ({ resource, id, variables, meta }: any) => {
      const url = meta?.URLSuffix
        ? `${API_URL}/${resource}/${id}/${meta.URLSuffix}`
        : `${API_URL}/${resource}/${id}/`

      const { headers } = meta ?? {}

      const { data } = await axios.patch(url, variables, {
        headers,
      })

      return {
        data,
      }
    },

    deleteOne: async ({ resource, id, variables, meta }: any) => {
      const url = meta?.URLSuffix
        ? `${API_URL}/${resource}/${id}/${meta.URLSuffix}`
        : `${API_URL}/${resource}/${id}/`

      const { data } = await axios.delete(url, {
        data: variables,
      })

      return {
        data,
      }
    },

    update_or_create_pipeline: async (payload: any) => {
      const url = `${API_URL}/update_or_create_pipeline/`
      const { data } = await axios.post(url, payload)

      return {
        data,
      }
    },

    job_status: async (payload: any) => {
      const url = JOB_STATUS_URL
      const { data } = await axios.post(url, payload)

      return {
        data,
      }
    },

    get_pipeline_output: async (payload: any) => {
      const url = PIPELINE_OUTPUT_URL
      const { data } = await axios.post(url, payload)

      return {
        data,
      }
    },

    generate: async ({ resource, id, variables }: any) => {

      const url =
        "https://heblvk6f7j.execute-api.us-east-1.amazonaws.com/dev2/v1/ai/execute_pipeline"

      const { data } = await axios.post(url, variables)

      return {
        data,
      }
    },

    getFileText: async ({ resource, id, variables }: any) => {
      const inferenceUrl = API_URL.replace("/backend/", "/")
      const url = `${inferenceUrl}/ai/get_file_text/`
      console.log("url: ", url)

      const { data } = await axios.post(url, variables)

      return {
        data,
      }
    },
  }
}
