import React from "react"
import { createRoot } from "react-dom/client"
import { Auth0Provider } from "@auth0/auth0-react"
import App from "./App"
import dayjs from "dayjs"
import "./i18n"
import WeekDay from "dayjs/plugin/weekday"
import LocaleData from "dayjs/plugin/localeData"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
// import "./App.css"
import "./style.css"
import { PipelineAssistantProvider } from "./packages/pipeline-assistant/components/pipeline-assistant-provider";

dayjs.extend(WeekDay)
dayjs.extend(LocaleData)
dayjs.extend(LocalizedFormat)

const container = document.getElementById("root") as HTMLElement
const root = createRoot(container)

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY

root.render(
  // <React.StrictMode>
  <Auth0Provider
    domain="dev-wt7n772n8mijfpvk.us.auth0.com"
    clientId="PwRm9NnbSi45Dv5By8qgLH1U6yvFR2A0"
    useRefreshTokens={true}
    cacheLocation="localstorage"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://zpipeliner-api/",
    }}
  >
    <PipelineAssistantProvider apiKey={OPENAI_API_KEY} chatApiEndpoint="api/chat/completions">
      <App />
    </PipelineAssistantProvider>
  </Auth0Provider>
  // </React.StrictMode>
)