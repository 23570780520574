import React from "react"
import { IResourceComponentsProps, useShow, useOne } from "@refinedev/core"
import { Show, DateField } from "@refinedev/antd"
import { Avatar, Card, Col, Grid, Row, Space, Typography } from "antd"
import { UserOutlined, CalendarOutlined } from "@ant-design/icons"

const { useBreakpoint } = Grid

const { Title } = Typography

export const ProcessorShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow()
  const { data, isLoading } = queryResult

  const { xl } = useBreakpoint()

  const record = data?.data

  const { data: llmConfigData, isLoading: llmConfigIsLoading } = useOne({
    resource: "llm_configs",
    id: record?.llm_config?.id || "",
    queryOptions: {
      enabled: !!record,
    },
  })

  const { data: jsonSchemaData, isLoading: jsonSchemaIsLoading } = useOne({
    resource: "json_schemas",
    id: record?.json_schema?.id || "",
    queryOptions: {
      enabled: !!record,
    },
  })

  const { data: promptData, isLoading: promptIsLoading } = useOne({
    resource: "prompts",
    id: record?.prompt?.id || "",
    queryOptions: {
      enabled: !!record,
    },
  })

  return (
    <Show isLoading={isLoading}>
      {/* <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ""} />
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Llm Config</Title>
      {llmConfigIsLoading ? <>Loading...</> : <>{llmConfigData?.data?.name}</>}
      <Title level={5}>Json Schema</Title>
      {jsonSchemaIsLoading ? (
        <>Loading...</>
      ) : (
        <>{jsonSchemaData?.data?.name}</>
      )}
      <Title level={5}>Prompt</Title>
      {promptIsLoading ? <>Loading...</> : <>{promptData?.data?.name}</>}
      <Title level={5}>Max Tokens Per Chunk Buffer</Title>
      <NumberField value={record?.maxTokensPerChunkBuffer ?? ""} /> */}

      <Row gutter={[16, 16]} style={{}}>
        <Col xl={6} lg={24} xs={24}>
          <Card
            bordered={false}
            style={{
              height: "100%",
            }}
          >
            <Space
              direction="vertical"
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <Space
                direction="vertical"
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Avatar
                  size={120}
                  src={record?.avatar?.[0].url}
                  style={{
                    backgroundColor: "#fde3cf",
                  }}
                ></Avatar>
                <Typography.Title level={3}>{record?.name}</Typography.Title>
              </Space>
              <Space
                direction="vertical"
                style={{
                  width: "100%",
                  textAlign: xl ? "unset" : "center",
                }}
              >
                <Typography.Text>
                  <UserOutlined /> {`ID: ${record?.id}`}
                </Typography.Text>
                <Typography.Text>
                  <UserOutlined /> {`LLM Config: ${record?.llm_config.name}`}
                </Typography.Text>
                <Typography.Text>
                  <UserOutlined /> {`JSON Schema: ${record?.json_schema.name}`}
                </Typography.Text>
                <Typography.Text>
                  <UserOutlined /> {`Prompt: ${record?.prompt.name}`}
                </Typography.Text>
                <Typography.Text>
                  <UserOutlined />{" "}
                  {`Max Tokens / Chunk Buffer: ${record?.max_tokens_per_chunk_buffer}`}
                </Typography.Text>
                <Typography.Text>
                  <UserOutlined /> {`Gen Params: ${record?.gen_params}`}
                </Typography.Text>
                <Title level={5}>Created:</Title>
                <Typography.Text>
                  <CalendarOutlined /> <DateField value={record?.created_at} />
                </Typography.Text>
                <Title level={5}>Last Updated:</Title>
                <Typography.Text>
                  <CalendarOutlined /> <DateField value={record?.updated_at} />
                </Typography.Text>
              </Space>
            </Space>
          </Card>
        </Col>
        <Col xl={18} lg={24} xs={24}>
          {/* <Title level={3}>Default System Prompt</Title>
          <Editor
            height="550px"
            language="json"
            value={JSON.stringify(record?.default_system_prompt, null, 4)}
            options={{
              acceptSuggestionOnCommitCharacter: true,
              acceptSuggestionOnEnter: "on",
              accessibilitySupport: "auto",
              codeLens: true,
              colorDecorators: true,
              contextmenu: true,
              cursorBlinking: "blink",
              cursorStyle: "line",
              disableLayerHinting: false,
              disableMonospaceOptimizations: false,
              dragAndDrop: false,
              fixedOverflowWidgets: false,
              folding: true,
              foldingStrategy: "auto",
              fontLigatures: false,
              formatOnPaste: false,
              formatOnType: false,
              hideCursorInOverviewRuler: false,
              links: true,
              mouseWheelZoom: false,
              multiCursorMergeOverlapping: true,
              multiCursorModifier: "alt",
              overviewRulerBorder: true,
              overviewRulerLanes: 2,
              quickSuggestions: true,
              quickSuggestionsDelay: 100,
              renderControlCharacters: false,
              renderLineHighlight: "all",
              renderWhitespace: "none",
              revealHorizontalRightPadding: 30,
              roundedSelection: true,
              rulers: [],
              scrollBeyondLastColumn: 5,
              scrollBeyondLastLine: false,
              selectOnLineNumbers: true,
              selectionClipboard: true,
              selectionHighlight: true,
              smoothScrolling: false,
              suggestOnTriggerCharacters: true,
              wordBasedSuggestions: true,
              wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
              wordWrap: "off",
              wordWrapBreakAfterCharacters: "\t})]?|&,;",
              wordWrapBreakBeforeCharacters: "{([+",
              wordWrapColumn: 80,
              wrappingIndent: "none",
              readOnly: true,
              lineNumbers: "on",
              automaticLayout: true,
              theme: "light",
              showFoldingControls: "always",
            }}
          /> */}
        </Col>
      </Row>
    </Show>
  )
}
