// import util from 'util';
import OpenAI from 'openai';
import {
    ChatCompletionMessage,
    ChatCompletionChunk,
    CreateChatCompletionRequestMessage,
} from 'openai/resources/chat';


import { ReadableStream } from "web-streams-polyfill/ponyfill";
import type {
    OpenAIStreamingParams,
    OpenAIChatMessage,
    FetchRequestOptions,
    OpenAIChatRole,
    OpenAIChatCompletionChunk,
} from './types';
import { parse } from "best-effort-json-parser";
import { FunctionCallHandler } from "ai";

// Converts the OpenAI API params + chat messages list + an optional AbortSignal into a shape that
// the fetch interface expects.
export const getOpenAiRequestOptions = (
    { apiKey, model, functions, ...restOfApiParams }: OpenAIStreamingParams,
    messages: OpenAIChatMessage[],
    signal?: AbortSignal
): FetchRequestOptions => ({
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`,
    },
    method: 'POST',
    body: JSON.stringify({
        model,
        // Includes all settings related to how the user wants the OpenAI API to execute their request.
        ...restOfApiParams,
        messages,
        functions,
        stream: true,
    }),
    signal,
});

const CHAT_COMPLETIONS_URL = 'https://api.openai.com/v1/chat/completions';

const textDecoder = new TextDecoder('utf-8');



export const functionResponseGenerator = async (
    requestOpts: FetchRequestOptions,
    func_call: Object,
    onIncomingChunk: (contentChunk: string, roleChunk: OpenAIChatRole) => void,
    onCloseStream: (beforeTimestamp: number) => void,
    exp?: FunctionCallHandler | undefined
) => {
    const name = func_call["name"]
    const args = func_call["arguments"]
    const body = parse(requestOpts.body)
    const messages = body.messages
    // let result: Object = {}
    // if (name == "book_travel") {
    //     result = {
    //         "booking_status": "success",
    //         "booking_confirmation": "1234567890",
    //         "booking_details": {
    //             "flight": "AA123",
    //             "hotel": "Hilton",
    //             "car": "Toyota",
    //         }
    //     }
    // }
    // else if (name == "glitter_bomb") {
    //     result = {
    //         "glitter_bomb_status": "success",
    //         "glitter_bomb_confirmation": "1234567890",
    //         "glitter_bomb_details": {
    //             "delivery_date": "2023-11-23",
    //             "coupon_code": "GLITTERBOMB123",
    //             "glitter_bomb_message": "You've been glitter bombed!",
    //         }
    //     }
    // }
    // let newRequestOpts = requestOpts

    if (exp) {
        let response = {} as any
        try {
            response = await exp(messages, func_call)
        }
        catch (e) {
            console.log("Error calling function: ", e)
            response = {
                "messages": [
                    {
                        "content": "Error calling function: " + e,
                        "role": "function",
                        "name": name,
                    }
                ]
            }
        }


        console.log("exp response: ", response)
        if (response) {
            const newMessages = response["messages"]
            const newRequestOpts = {
                ...requestOpts,
                body: JSON.stringify({
                    ...body,
                    messages: newMessages.map((msg: any) => {
                        if ("id" in msg) {
                            delete msg["id"]
                        }
                        return msg
                    }
                    ),
                })
            }
            return await openAiStreamingDataHandler(
                newRequestOpts,
                // The handleNewData function will be called as new data is received.
                onIncomingChunk,
                // The closeStream function be called when the message stream has been completed.
                onCloseStream,
                exp
            );
        }
    }
    return

    // const newMessages = [
    //     ...messages,
    //     {
    //         "content": JSON.stringify(result),
    //         "role": "function",
    //         "name": name,
    //     }

    // ]

    // const newRequestOpts = {
    //     ...requestOpts,
    //     body: JSON.stringify({
    //         ...body,
    //         messages: newMessages,
    //     })
    // }







}

async function callFunction(
    function_call: ChatCompletionMessage.FunctionCall,
    exp: FunctionCallHandler,
    chatMessages: ChatCompletionMessage[],
): Promise<any> {
    const args = JSON.parse(function_call.arguments!);
    // switch (function_call.name) {
    //   case 'list':
    //     return await list(args['genre']);

    //   case 'search':
    //     return await search(args['name']);

    //   case 'get':
    //     return await get(args['id']);

    //   default:
    //     throw new Error('No function found');
    // }

    const messages = chatMessages.map((msg: any) => {
        if ("id" in msg) {
            delete msg["id"]
        }
        return msg
    })

    const result = await exp(messages, function_call)
    const newMessages = result["messages"]
    return newMessages[newMessages.length - 1]["content"]

}

export const openAiStreamingDataHandler2 = async (
    requestOpts: FetchRequestOptions,
    onIncomingChunk: (contentChunk: string, roleChunk: OpenAIChatRole) => void,
    onCloseStream: (beforeTimestamp: number) => void,
    experimental_onFunctionCall?: FunctionCallHandler | undefined
) => {
    const beforeTimestamp = Date.now();
    const openai = new OpenAI(
        {
            apiKey: requestOpts.headers["Authorization"]?.replace("Bearer ", ""),
        }
    );

    const body = parse(requestOpts.body)
    const messages = body.messages
    const functions = body.functions
    while (true) {
        const stream = await openai.chat.completions.create({
            model: 'gpt-4',
            messages,
            functions: functions,
            stream: true,
        });
        //   let writeLine = lineRewriter();
        let message = {} as ChatCompletionMessage;
        for await (const chunk of stream) {
            message = messageReducer(message, chunk);
            onIncomingChunk(message.content, message.role);
            //   writeLine(message);
        }
        // console.log();
        // messages.push(message);

        // If there is no function call, we're done and can exit this loop
        if (!message.function_call) {

            onCloseStream(beforeTimestamp);
            return message;
        }

        // If there is a function call, we generate a new message with the role 'function'.
        // const result = await callFunction(
        //     message.function_call,
        //     experimental_onFunctionCall!,
        //     messages
        // );
        // const newMessage = {
        //   role: 'function' as const,
        //   name: message.function_call.name!,
        //   content: result,
        // };
        // messages.push(newMessage);

        // console.log(newMessage);
        // console.log();

        await functionResponseGenerator(requestOpts, message.function_call, onIncomingChunk, onCloseStream, experimental_onFunctionCall)
        onCloseStream(beforeTimestamp);
        return message;

    }
}

function messageReducer(previous: ChatCompletionMessage, item: ChatCompletionChunk): ChatCompletionMessage {
    const reduce = (acc: any, delta: any) => {
        acc = { ...acc };
        for (const [key, value] of Object.entries(delta)) {
            if (acc[key] === undefined || acc[key] === null) {
                acc[key] = value;
            } else if (typeof acc[key] === 'string' && typeof value === 'string') {
                (acc[key] as string) += value;
            } else if (typeof acc[key] === 'object' && !Array.isArray(acc[key])) {
                acc[key] = reduce(acc[key], value);
            }
        }
        return acc;
    };

    return reduce(previous, item.choices[0]!.delta) as ChatCompletionMessage;
}

// function lineRewriter() {
//     let lastMessageLength = 0;
//     return function write(value: any) {
//         process.stdout.cursorTo(0);
//         process.stdout.moveCursor(0, -Math.floor((lastMessageLength - 1) / process.stdout.columns));
//         lastMessageLength = util.formatWithOptions({ colors: false, breakLength: Infinity }, value).length;
//         process.stdout.write(util.formatWithOptions({ colors: true, breakLength: Infinity }, value));
//     };
// }

// Takes a set of fetch request options and calls the onIncomingChunk and onCloseStream functions
// as chunks of a chat completion's data are returned to the client, in real-time.
export const openAiStreamingDataHandler = async (
    requestOpts: FetchRequestOptions,
    onIncomingChunk: (contentChunk: string, roleChunk: OpenAIChatRole) => void,
    onCloseStream: (beforeTimestamp: number) => void,
    experimental_onFunctionCall?: FunctionCallHandler | undefined
) => {
    // Record the timestamp before the request starts.
    const beforeTimestamp = Date.now();

    // Initiate the completion request
    const response = await fetch(CHAT_COMPLETIONS_URL, requestOpts);

    // If the response isn't OK (non-2XX HTTP code) report the HTTP status and description.
    if (!response.ok) {
        throw new Error(
            `Network response was not ok: ${response.status} - ${response.statusText}`
        );
    }

    // A response body should always exist, if there isn't one something has gone wrong.
    if (!response.body) {
        throw new Error('No body included in POST response object');
    }

    let content = '';
    let role = '';

    const reader = response.body.getReader();
    const stream = new ReadableStream({
        start(controller) {
            return pump();
            async function pump(): Promise<void> {
                return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;
                    }
                    controller.enqueue(value);
                    return pump();
                });
            }
        },
    });

    let function_call_detected = false
    let func_call = {
        "name": null,
        "arguments": "",
    }

    let partialLine = '';

    for await (const newData of stream) {
        // Decode the data
        const decodedData = textDecoder.decode(newData as Buffer);
        // Split the data into lines to process
        let lines = decodedData.split(/(\n){2}/);

        const combinedLines = partialLine.concat(lines[0])

        lines[0] = combinedLines

        partialLine = ''



        // Parse the lines into chat completion chunks
        let chunks: OpenAIChatCompletionChunk[] = lines
            // Remove 'data:' prefix off each line
            .map((line) => line.replace(/(\n)?^data:\s*/, '').trim())
            // Remove empty lines and "[DONE]"
            .filter((line) => line !== '' && line !== '[DONE]')
            // Parse JSON string
            // .map((line) => {
            //     console.log("Line: ", line)
            //     return parse(line)
            // });
            .reduce((accumulator, line) => {
                if (line.endsWith("}")) {
                    try {
                        // chunks.push(JSON.parse(line))
                        accumulator.push(JSON.parse(line));
                    }
                    catch (e) {
                        console.log("Error parsing line: ", line)
                        partialLine.concat(line)
                    }
                }
                else {
                    partialLine.concat(line)
                }

                // if (conditionToKeepItem) {
                //   // If condition is met, push the transformed item into the accumulator
                //   accumulator.push(transformedItem);
                // }
                return accumulator;
            }, []);
        // .forEach((line) => {
        //     if (line.endsWith("}")) {
        //         try {
        //             chunks.push(JSON.parse(line))
        //         }
        //         catch (e) {
        //             console.log("Error parsing line: ", line)
        //             partialLine.concat(line)
        //         }
        //     }
        //     else {
        //         partialLine.concat(line)
        //     }
        // })



        // Process each chunk and send an update to the registered handler.
        for (const chunk of chunks) {

            if ("choices" in chunk) {
                // Avoid empty line after single backtick
                const contentChunk: string = (
                    chunk.choices[0].delta.content ?? ''
                ).replace(/^`\s*/, '`');
                // Most times the chunk won't contain a role, in those cases set the role to ""
                let roleChunk: OpenAIChatRole = chunk.choices[0].delta.role ?? '';

                const deltas = chunk["choices"][0]["delta"]

                // Check if "function_call" is in the deltas
                if ("function_call" in deltas) {
                    roleChunk = ""
                    let function_call: Object = deltas["function_call"] ?? {}
                    // If it is, then we need to call the function
                    function_call_detected = true
                    // If "name" is in function_call
                    if ("name" in function_call) {
                        func_call["name"] = deltas["function_call"]["name"]
                    }
                    // If "arguments" is in function_call
                    if ("arguments" in function_call) {
                        func_call["arguments"] += deltas["function_call"]["arguments"]
                    }
                }

                if (function_call_detected && chunk.choices[0].finish_reason === 'function_call') {
                    // func_call["arguments"] = func_call["arguments"].replace(/(\n)?^data:\s*/, '').trim()
                    // func_call["arguments"] = parse(func_call["arguments"])
                    // Call the function

                    console.log("Calling function: ", func_call)



                    await functionResponseGenerator(requestOpts, func_call, onIncomingChunk, onCloseStream, experimental_onFunctionCall)



                    // Reset the function call
                    func_call = {
                        "name": null,
                        "arguments": "",
                    }
                    function_call_detected = false



                }
                else {
                    // Assign the new data to the rest of the data already received.
                    content = `${content}${contentChunk}`;
                    role = `${role}${roleChunk}`;


                    onIncomingChunk(contentChunk, roleChunk);
                }

            }
        }
    }

    onCloseStream(beforeTimestamp);

    // Return the fully-assembled chat completion.
    return { content, role } as OpenAIChatMessage;
};

export default openAiStreamingDataHandler;