import { useMemo, useContext } from "react";
import {
    PipelineAssistantContext,
    PipelineAssistantContextParams,
    pipelineAssistantApiConfigExtrapolator,
} from "../context/pipeline-assistant-context";
import { useChat } from "ai/react";
import { ChatRequestOptions, CreateMessage, Message } from "ai";
import { UseChatOptions } from "ai";

export interface UsePipelineAssistantChatOptions extends UseChatOptions {
    makeSystemMessage?: (contextString: string) => string;
}

export interface UsePipelineAssistantChatReturn {
    visibleMessages: Message[];
    append: (
        message: Message | CreateMessage,
        chatRequestOptions?: ChatRequestOptions
    ) => Promise<string | null | undefined>;
    reload: (
        chatRequestOptions?: ChatRequestOptions
    ) => Promise<string | null | undefined>;
    stop: () => void;
    isLoading: boolean;
    input: string;
    setInput: React.Dispatch<React.SetStateAction<string>>;
    initialMessagesWithContext: Message[];
    systemMessage: Message;
}

export function usePipelineAssistantChat({
    makeSystemMessage,
    ...options
}: UsePipelineAssistantChatOptions): UsePipelineAssistantChatReturn {
    const {
        getContextString,
        getChatCompletionFunctionDescriptions,
        getFunctionCallHandler,
        pipelineAssistantApiConfig,
    } = useContext(PipelineAssistantContext);

    const systemMessage: Message = useMemo(() => {
        const systemMessageMaker = makeSystemMessage || defaultSystemMessage;
        const contextString = getContextString([]);

        return {
            id: "system",
            content: systemMessageMaker(contextString),
            role: "system",
        };
    }, [getContextString, makeSystemMessage]);

    const initialMessagesWithContext = [systemMessage].concat(
        options.initialMessages || []
    );

    const functionDescriptions = useMemo(() => {
        return getChatCompletionFunctionDescriptions();
    }, [getChatCompletionFunctionDescriptions]);

    const { messages, append, reload, stop, isLoading, input, setInput } =
        useChat({
            ...options,
            api: pipelineAssistantApiConfigExtrapolator(pipelineAssistantApiConfig).chatApiEndpoint,
            id: options.id,
            initialMessages: initialMessagesWithContext,
            experimental_onFunctionCall: getFunctionCallHandler(),
            headers: { ...pipelineAssistantApiConfig.headers, ...options.headers },
            body: {
                id: options.id,
                functions: functionDescriptions,
                ...pipelineAssistantApiConfig.body,
                ...options.body,
            },
        });

    const visibleMessages = messages.filter(
        (message) => message.role === "user" || message.role === "assistant"
    );

    return {
        visibleMessages,
        append,
        reload,
        stop,
        isLoading,
        input,
        setInput,
        initialMessagesWithContext,
        systemMessage
    };
}

export function defaultSystemMessage(contextString: string): string {
    return `
Please act as an efficient, competent, conscientious, and industrious professional assistant.

You always answer with markdown formatting unless executing a function or specifically told otherwise.
You will be penalized if you do not answer with markdown when it would be possible.
The markdown formatting you support: headings, bold, italic, links, tables, lists, code blocks, and blockquotes.
You do not support images and never include images. You will be penalized if you render images.

You also support Mermaid formatting. You will be penalized if you do not render Mermaid diagrams when it would be possible.
The Mermaid diagrams you support: sequence, flow, class, state, er, gantt, userJourney, git, pie, mindmap, quadrantChart, and xyChart.
You must always first get an example of the Mermaid diagram first before rendering it by calling GetMermaidDiagramExampleByType. You will be penalized if you render a Mermaid diagram without first getting an example of it.

Help the user achieve their goals, and you do so in a way that is as efficient as possible, without unnecessary fluff, but also without sacrificing professionalism.
Always be polite and respectful, and prefer brevity over verbosity.

The user has provided you with the following context:
\`\`\`
${contextString}
\`\`\`

They have also provided you with functions you can call to initiate actions on their behalf, or functions you can call to receive more information.

Please assist them as best you can.

You can ask them for clarifying questions if needed, but don't be annoying about it. If you can reasonably 'fill in the blanks' yourself, do so.

If you would like to call a function, call it without saying anything else.
`;
}