import SpecialEditor from "./SpecialEditor"
import { Formik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { useDropzone } from "react-dropzone"
import toast from "react-hot-toast"
import { uploadFile } from "../../utils/uploadFile"
import { v4 as uuidv4 } from "uuid"
import { useDataProvider } from "@refinedev/core"


const MESSAGE_MAX_CHARACTERS = 120000000000000

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

const now = () => {
    return Math.floor(Date.now() / 1000)
}

const AdvancedChatInput = (props: any) => {

    const myDataProvider = useDataProvider()("default") as any

    const dropzone = useDropzone({
        noClick: true,
        noKeyboard: true,
        maxFiles: 1,
        onDrop: (acceptedFiles: any) => {
            setFiles(acceptedFiles)
        },
    })

    const editorRef = useRef(null)
    const [editor, setEditor] = useState(null)
    const [files, setFiles] = useState([])
    const [hasText, setHasText] = useState(false)
    const validate = () => {
        const errors = {};
        const realText = editor?.getText()
        if (realText && realText.trim().length > MESSAGE_MAX_CHARACTERS)
            errors['text'] = `Message is too long. Max ${MESSAGE_MAX_CHARACTERS} characters.`
        return errors
    }

    useEffect(() => {
        if (editorRef.current) {
            setEditor(editorRef.current.getEditor())
        }
    }
        , [editorRef])


    return (
        <div
            className="w-full px-5 pb-2 flex-shrink-0"
        >
            <div
                className={classNames(
                    dropzone.isDragActive ? "th-border-blue" : "th-border-selbg",
                    "w-full border rounded flex items-center th-bg-bg"
                )}
            >
                <Formik
                    initialValues={{
                        text: "",
                    }}
                    validate={validate}
                    enableReinitialize
                    onSubmit={async ({ text }, { setSubmitting, resetForm }) => {
                        console.log("submitting")
                        setSubmitting(true)
                        try {
                            const messageId = uuidv4()
                            const realText = editor?.getText()
                            console.log("messageId", messageId)
                            console.log("realText", realText)
                            console.log("files", files)
                            console.log("editor", editor)
                            console.log("editorRef", editorRef)
                            if (!realText?.trim() && !files?.length) return

                            let fileUploadResult
                            if (files?.length) {
                                console.log("uploading file")
                                fileUploadResult = await uploadFile(
                                    `Message/${messageId}/${now()}_file`,
                                    files[0],
                                    myDataProvider
                                )

                                console.log("fileUploadResult", fileUploadResult)

                            }

                            console.log("fileUploadResult", fileUploadResult)
                            if (props.onFileUpload) {
                                props.onFileUpload(fileUploadResult)
                            }


                            const response = {
                                objectId: messageId,
                                text: realText?.trim().length ? text : "",
                                ...(files?.length && {
                                    fileName: files[0]?.name,
                                    fileUploadResult,
                                }),
                            }

                            console.log("response", response)

                            if (props.onPostData) {
                                props.onPostData(response)
                            }
                            const el = document.getElementById("messages")!;
                            el.scrollTo(el.scrollHeight, 0);
                            resetForm()
                            setFiles([])
                        } catch (err: any) {
                            console.log("err", err)
                            toast.error(err.message)
                            resetForm()
                            setFiles([])
                        }
                        setSubmitting(false)
                    }}
                >
                    {({ values, setFieldValue, handleSubmit, errors, isSubmitting }) => (
                        <form
                            noValidate
                            onSubmit={handleSubmit}
                            className="w-full h-full flex items-center"
                        >

                            <SpecialEditor
                                files={files}
                                setFiles={setFiles}
                                editorRef={editorRef}
                                editor={editor}
                                text={values.text}
                                setFieldValue={setFieldValue}
                                placeholder={"Type your pipeline input or upload a file..."}
                                errors={errors}
                                isSubmitting={isSubmitting}
                                handleSubmit={handleSubmit}
                                setHasText={setHasText}
                                dropzone={dropzone}
                                disabled={props.disabled || false}
                            />


                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default AdvancedChatInput