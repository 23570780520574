import { AuthBindings } from "@refinedev/core"

import { TOKEN_KEY } from "./constants"
import { AxiosInstance } from "axios"

export const authProvider = (
  axiosInstance: AxiosInstance,
  user: any,
  getIdTokenClaims: any,
  logout: any
): AuthBindings => {
  return {
    login: async () => {
      return {
        success: true,
      }
    },
    logout: async () => {
      localStorage.removeItem(TOKEN_KEY)
      localStorage.removeItem("auth0user")
      logout({ logoutParams: { returnTo: window.location.origin } })
      return {
        success: true,
      }
    },
    onError: async (error: any) => {
      console.error(error)

      return {
        error,
      }
    },
    check: async () => {
      try {
        const token = await getIdTokenClaims()
        console.log("token: ", token)
        if (token) {
          return {
            authenticated: true,
          }
        } else {
          return {
            authenticated: false,
            error: new Error("No token found"),
            redirectTo: "/login",
            logout: true,
          }
        }
      } catch (error) {
        console.error(error)
        return {
          authenticated: false,
          error: new Error("No token found"),
          redirectTo: "/login",
          logout: true,
        }
      }
    },

    getPermissions: async () => null,
    getIdentity: async () => {
      if (user) {
        console.log("user: ", user)
        return {
          ...user,
          avatar: user.picture,
        }
      }
      return null
    },
  }
}
