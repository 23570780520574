import React from "react"
import { IResourceComponentsProps, BaseRecord } from "@refinedev/core"
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DateField,
  DeleteButton,
} from "@refinedev/antd"
import { Table, Space } from "antd"

export const LlmConfigList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  })

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="server_type" title="Server Type" />
        <Table.Column
          dataIndex={["base_inference_url"]}
          title="Base Inference Url"
          //   render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex="model_prompt_format"
          title="Model Prompt Format"
        />
        <Table.Column
          dataIndex="default_system_prompt"
          title="Default System Prompt"
        />
        <Table.Column dataIndex="max_input_length" title="Max Input Length" />
        <Table.Column dataIndex="model_params" title="Model Params" />
        <Table.Column dataIndex="environment_variables" title="ENV Variables" />
        <Table.Column
          dataIndex={["created_at"]}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex={["updated_at"]}
          title="Updated At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
