import React, { MouseEventHandler } from "react"

import Select, {
  components,
  MultiValueGenericProps,
  MultiValueProps,
  OnChangeValue,
  Props,
  ValueContainerProps,
} from "react-select"
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from "react-sortable-hoc"

interface GenericOption {
  readonly value: any
  readonly label: string
}

function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice()
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  )
  return slicedArray
}

const SortableMultiValue = SortableElement(
  (props: MultiValueProps<GenericOption>) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault()
      e.stopPropagation()
    }
    const innerProps = { ...props.innerProps, onMouseDown }
    console.log("props1:", props)
    return (
      <components.MultiValue
        {...props}
        innerProps={innerProps}
        key={"processorsValue"}
      />
    )
  }
)

const SortableMultiValueLabel = SortableHandle(
  (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />
)

const SortableSelect = SortableContainer(Select) as React.ComponentClass<
  Props<GenericOption, true> & SortableContainerProps
>

interface OrderedSelectProps {
  options: GenericOption[]
  values?: GenericOption[]
  onChange?: (value: any) => void
  getValueProps?: (value: any) => void
}

const SortableMultiValue2 = SortableElement(
  (props: MultiValueProps<GenericOption>) => (
    <components.MultiValue {...props} />
  )
)

const MultiSelectSort: React.FC<OrderedSelectProps> = ({
  options,
  values,
  onChange,
  getValueProps,
}) => {
  const [selected, setSelected] = React.useState<readonly GenericOption[]>(
    values || []
  )

  const handleChange = (
    selectedOptions: OnChangeValue<GenericOption, true>
  ) => {
    setSelected(selectedOptions)
    onChange && onChange(selectedOptions)
  }

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex)
    setSelected(newValue)
    onChange && onChange(newValue)
  }

  const ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<GenericOption>) => (
    <components.ValueContainer {...props}>
      {selected.map((value, index) => {
        return (
          <SortableMultiValue
            {...props}
            index={index}
          />
        )
      })}
      {children}
    </components.ValueContainer>
  )

  console.log("options:", options)
  console.log("value:", values)

  return (

    <SortableSelect
      useDragHandle
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      multi
      isMulti
      options={options}
      value={selected}
      onChange={handleChange}
      onInputChange={(value) => console.log("onInputChange: ", value)}
      components={{
        // @ts-ignore We're failing to provide a required index prop to SortableElement
        MultiValue: SortableMultiValue,
        // @ts-ignore We're failing to provide a required index prop to SortableElement
        MultiValueLabel: SortableMultiValueLabel,
      }}
      closeMenuOnSelect={false}
    />
  )
}

export default MultiSelectSort