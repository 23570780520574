import "antd/dist/reset.css"
import axios from "axios"
import "./style.css"
import { UploadOutlined } from "@ant-design/icons"
import { useState, FC } from "react"
import { Upload, Progress, UploadFile, message } from "antd"
import { uuidv4 } from "@antv/xflow-core"
import { API_URL, TOKEN_KEY } from "../../constants"
import { useDataProvider } from "@refinedev/core"

interface ImageUploaderProps {
  onUploadSuccess?: (url: string) => void
}

interface UploadChangeParam {
  file: UploadFile
  fileList: UploadFile[]
  event?: { percent: number }
}

const ImageUploader: FC<ImageUploaderProps> = ({ onUploadSuccess }) => {
  const [defaultFileList, setDefaultFileList] = useState<UploadFile[]>([])
  const [progress, setProgress] = useState<number>(0)
  const myDataProvider = useDataProvider()("default") as any

  const uploadImage = async (options: any) => {
    const { onSuccess, file } = options

    const fmData = new FormData()

    console.log("myDataProvider: ", myDataProvider)
    const additionalHeaders = axios.interceptors.response.use(
      (res: any) => {
        console.log(res.request._header)
        return res
      },
      (error: any) => Promise.reject(error)
    )

    const token = localStorage.getItem(TOKEN_KEY)

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (event: ProgressEvent) => {
        const percent = Math.floor((event.loaded / event.total) * 100)
        setProgress(percent)
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000)
        }
      },
    }

    const ext = file.name.split(".").pop()

    fmData.append("key", `Upload/${uuidv4()}.${ext}`)
    fmData.append("file", file)
    try {
      const res = await axios.post(
        API_URL.split("/v1")[0].concat("/upload_obj/"),
        fmData,
        config
      )

      onSuccess("Ok")
      console.log("server res: ", res)
      if (onUploadSuccess && res.data && res.data.url) {
        onUploadSuccess(res.data.url)
      }
    } catch (err) {
      console.log("Error: ", err)
    }
  }

  const handleOnChange = (info: UploadChangeParam) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList)
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`)
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`)
    }
    const { fileList } = info
    setDefaultFileList(fileList)
  }

  return (
    <div className="container">
      <Upload
        accept="*/*"
        customRequest={uploadImage}
        onChange={handleOnChange}
        listType="picture-card"
        defaultFileList={defaultFileList}
        className="image-upload-grid"
      >
        {defaultFileList.length >= 1 ? null : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <UploadOutlined
              style={{
                fontSize: 28,
                paddingLeft: 5,
              }}
            />
            Upload File
          </div>
        )}
      </Upload>
      {progress > 0 ? <Progress percent={progress} /> : null}
    </div>
  )
}

export default ImageUploader