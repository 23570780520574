import MDEditor, { commands } from "@uiw/react-md-editor"
import { Space, Row, Col, Typography, Avatar, Button, Card } from "antd"
import { inferJSONSchema } from "./helpers"
import ImageUploader from "components/fileUploader"
import {
  Courier,
  CourierInfoText,
  CourierBoxContainer,
} from "pages/pipelines/styled"
import React, { useCallback } from "react"
import { EventsTimeline } from "components/eventsTimeline"

const { Text } = Typography

const randomColor = () => {
  const hex = "0123456789ABCDEF"
  let color = "#"
  for (let i = 0; i < 6; i++) {
    color += hex[Math.floor(Math.random() * 16)]
  }
  return color
}

interface IInputOutputEditorsProps {
  editorText: string
  outputText: string
  handleEditorChange: (value: string) => void
  handleOutputChange: (value: string) => void
  handleGenerate: () => Promise<any>
  showRows: () => void
  handleUploadSuccess: (value: string) => Promise<any>
}

export const InputOutputEditors = (props: IInputOutputEditorsProps) => {
  const {
    editorText,
    outputText,
    handleEditorChange,
    handleOutputChange,
    handleGenerate,
    showRows,
    handleUploadSuccess,
  } = props
  const [editor, setEditor] = React.useState<string>(editorText ?? "")
  const [output, setOutput] = React.useState<string>(outputText ?? "")
  const [events, setEvents] = React.useState<any[]>([])
  const [isGenerating, setIsGenerating] = React.useState(false)
  const [requestId, setRequestId] = React.useState<string>("")

  const handleResult = useCallback((result: any) => {
    console.log("handleResult: ", result)
    setRequestId(result.data.request_id)
    setEvents([])
  }, [])

  const onEditorChange = React.useCallback(
    (value: string | undefined) => {
      setEditor(value ?? "")
      handleEditorChange(value ?? "")
    },
    [setEditor]
  )

  const onOutputChange = React.useCallback(
    (value: string | undefined) => {
      setOutput(value ?? "")
      handleOutputChange(value ?? "")
      setEvents((events) => [...events, JSON.parse(value ?? "")])
      setIsGenerating(false)
    },
    [setOutput]
  )

  const onUploadSuccess = React.useCallback(
    async (value: string | undefined) => {
      const result = await handleUploadSuccess(value ?? "")
      onEditorChange(result.data.file_text)
    },
    [setOutput]
  )

  return (
    <Space size={20} direction="vertical" style={{ width: "100%" }}>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col
          style={{
            flex: 1,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ height: "500px", width: "100%", flex: 1 }}>
            <Typography.Title
              style={{
                textAlign: "center",
                backgroundColor: "lightgray",
                color: "black",
              }}
              level={4}
            >
              Input Text
            </Typography.Title>

            <MDEditor
              value={editor}
              onChange={onEditorChange}
              height={500}
              preview="edit"
              commands={[...commands.getCommands(), inferJSONSchema]}
            />
          </div>
        </Col>
        <Col style={{ flex: 1, width: "100%" }}>
          <div style={{ height: "500px", width: "100%" }}>
            <Typography.Title
              style={{
                textAlign: "center",
                backgroundColor: "lightgray",
                color: "black",
              }}
              level={4}
            >
              Output Events
            </Typography.Title>
            {/* <MDEditor
              value={output}
              onChange={onOutputChange}
              height={500}
              preview="edit"
            /> */}
            <EventsTimeline
              handleOutputChange={onOutputChange}
              initialRequestId={requestId}
            />
          </div>
        </Col>
      </Row>
      <Card>
        <Row justify="center">
          <Col
            xl={12}
            lg={10}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-end",
            }}
          >
            <Courier>
              <Avatar
                size={58}
                src={""}
                style={{
                  backgroundColor: randomColor(),
                }}
              />
              <CourierInfoText>
                <Text style={{ fontSize: 16 }}>Pipeline Tester</Text>
                <Text
                  style={{
                    fontSize: 22,
                    fontWeight: 800,
                  }}
                >
                  The Full Monty
                </Text>
              </CourierInfoText>
            </Courier>
          </Col>

          <CourierBoxContainer
            xl={12}
            lg={14}
            md={14}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Button
              type="primary"
              onClick={showRows}
              style={{ marginRight: 20 }}
            >
              Show Rows
            </Button>
            <Button
              type="primary"
              style={{
                marginRight: 20,
                backgroundColor: isGenerating ? "red" : "#67be23",
              }}
              disabled={isGenerating}
              onClick={async () => {
                setIsGenerating(true)
                const result = await handleGenerate()

                handleResult(result)
              }}
            >
              {isGenerating ? "Running..." : "Generate"}
            </Button>

            <ImageUploader onUploadSuccess={onUploadSuccess} />
          </CourierBoxContainer>
        </Row>
      </Card>
    </Space>
  )
}
