import React from "react";
import { PipelineEditor } from "../../components/PipelineEditor/components/PipelineEditor";


export const PipelineEditorPage = () => {

    return (
        <>
            <PipelineEditor showVerticalList={false} showHorizontalList={true} showMagicMode={false} />
        </>
    );
};