import React, { useEffect } from "react"
import { Timeline, TimelineEvent } from "react-event-timeline"
import useWebSocket from "react-use-websocket"
import { JSX } from "react/jsx-runtime"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import "./timeline.css"
import { Col, Row } from "antd"
import MDEditor from "@uiw/react-md-editor"

const getSearchString = (event: any, searchFilter: string) => {
  var searchString = ""
  if (searchFilter == "all") {
    Object.keys(event).forEach(function (key) {
      let stringVal = ""
      if (typeof event[key] == "object") {
        stringVal = JSON.stringify(event[key])
      } else {
        stringVal = event[key].toString()
      }
      searchString += stringVal
    })
  } else {
    Object.keys(event).forEach(function (key) {
      if (key == searchFilter) {
        let stringVal = ""
        if (typeof event[key] == "object") {
          stringVal = JSON.stringify(event[key])
        } else {
          stringVal = event[key].toString()
        }
        searchString += stringVal
      }
    })
  }
  return searchString
}

const shouldShow = (
  event: any,
  searchTerm: string,
  searchFilter: string,
  topicFilters: string[]
) => {
  searchTerm = searchTerm.replace("*", "")
  let searchString = getSearchString(event, searchFilter)
  if (searchString.toLowerCase().search(searchTerm.toLowerCase()) !== -1) {
    if (topicFilters.indexOf(event.topic) != -1) {
      return true
    }
    return false
  }
  return false
}

const searchFilters = [
  "all",
  "topic",
  "datetime",
  "message",
  "subject",
  "message_attributes",
]

const topics = ["Zpipeliner-Results"]

interface IEventsTimelineProps {
  handleOutputChange?: (value: string) => void
  initialRequestId?: string
  initialEvents?: any[]
}

export const EventsTimeline = (props: IEventsTimelineProps) => {
  const { handleOutputChange, initialRequestId, initialEvents } = props
  const [events, setEvents] = React.useState<any[]>(initialEvents ?? [])
  const [requestId, setRequestId] = React.useState<string>(
    initialRequestId ?? ""
  )
  const [filters, setFilters] = React.useState<string[]>(topics)
  const [searchTerm, setSearchTerm] = React.useState<string>("")
  const [searchFilter, setSearchFilter] = React.useState<string>("all")
  const handle = useFullScreenHandle()
  const [showLogs, setShowLogs] = React.useState<boolean>(false)
  const [logEvents, setLogEvents] = React.useState<any[]>([])

  const search = (event: any) => {
    setSearchTerm(event.target.value)
  }

  const filterTopic = (topic: string) => {
    let newFilters = [...filters]
    if (filters.indexOf(topic) == -1) {
      newFilters.push(topic)
    } else {
      newFilters = filters.filter(function (filter) {
        return filter != topic
      })
    }

    setFilters(newFilters)
  }

  const getTopics = () => {
    let topicElements: JSX.Element[] = []
    topics.map(function (topic) {
      let selected = filters.indexOf(topic) != -1
      let className = "Filter-button"
      if (selected) {
        className += " Filter-button-selected"
      }
      topicElements.push(
        <button
          className={className}
          style={{ color: "rgb(141, 16, 128)" }}
          onClick={() => filterTopic(topic)}
          key={topic}
        >
          {topic}
        </button>
      )
    })
    return topicElements
  }

  const getSearchFilters = () => {
    var searchFilterOptions: JSX.Element[] = []
    searchFilters.forEach(function (filter) {
      let className = "Search-button"
      if (filter == searchFilter) {
        className += " Search-button-selected"
      }
      searchFilterOptions.push(
        <button
          className={className}
          // style={{color: "rgb(141, 16, 128)"}}
          onClick={() => setSearchFilter(filter)}
          key={filter}
        >
          {filter}
        </button>
      )
    })
    return searchFilterOptions
  }

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(
    "wss://qbqljzxguj.execute-api.us-east-1.amazonaws.com/prod",
    {
      onOpen: () => {
        sendMessage(
          JSON.stringify({
            message: "subscribe",
            topic: "Zpipeliner-Results",
          })
        )
      },
      shouldReconnect: (closeEvent) => true,
    }
  )

  const getLogEvents = () => {
    let evts: JSX.Element[] = []
    let topicFilters = [...filters]
    Object.keys(events).map(function (key: any) {
      const e = events[key]
      if (shouldShow(e, searchTerm, searchFilter, topicFilters)) {
        const markdownSource =
          "```json\n" + JSON.stringify(e.message, null, 2) + "\n```"
        evts.push(
          <TimelineEvent
            key={e.id + new Date().toISOString()}
            title={e.topic}
            // subtitle={e.subject}
            // createdAt={new Date(e.datetime).toDateString()}
            // icon={<i className="material-icons md-18">textsms</i>}
            // iconStyle={{ color: "rgb(141, 16, 128)" }}
            // bubbleStyle={{ border: "2px solid rgb(141, 16, 128)" }}
            // titleStyle={{ color: "rgb(141, 16, 128)" }}
            // subtitleStyle={{}}
            // cardHeaderStyle={{ padding: "2px" }}
          >
            <MDEditor.Markdown source={markdownSource} />
          </TimelineEvent>
        )
      }
    })

    return evts
  }

  const getEvents = () => {
    let evts: JSX.Element[] = []
    let topicFilters = [...filters]
    Object.keys(events).map(function (key: any) {
      const e = events[key]
      if (shouldShow(e, searchTerm, searchFilter, topicFilters)) {
        const markdownSource =
          "```json\n" + JSON.stringify(e, null, 2) + "\n```"
        evts.push(
          <TimelineEvent
            key={e.id + new Date().toISOString()}
            title={e.topic}
            subtitle={e.subject}
            createdAt={new Date(e.datetime).toDateString()}
            icon={<i className="material-icons md-18">textsms</i>}
            iconStyle={{ color: "rgb(141, 16, 128)" }}
            bubbleStyle={{ border: "2px solid rgb(141, 16, 128)" }}
            titleStyle={{ color: "rgb(141, 16, 128)" }}
            subtitleStyle={{}}
            cardHeaderStyle={{ padding: "2px" }}
          >
            <MDEditor.Markdown source={markdownSource} />
          </TimelineEvent>
        )
      }
    })

    return evts
  }

  useEffect(() => {
    setRequestId(initialRequestId ?? "")
  }, [initialRequestId])

  useEffect(() => {
    setEvents(initialEvents ?? [])
  }, [initialEvents])

  useEffect(() => {
    // lastMessage && setMessageHistory((prev) => prev.concat(lastMessage.data))
    // if (lastMessage?.data !== undefined) {
    //   const data = JSON.parse(lastMessage?.data)
    if (lastMessage?.data !== undefined) {
      const data = JSON.parse(lastMessage?.data)
      console.log("message: ", lastMessage)
      const event = {
        id: data.id,
        topic: data.topic,
        datetime: data.timestamp,
        message: JSON.parse(data.message),
        subject: data.subject,
        message_attributes: data.message_attributes,
      }
      console.log("event: ", event)
      console.log("requestId: ", requestId)
      if (event.message_attributes?.request_id?.Value === requestId) {
        if (event.subject.includes("Generation Log")) {
          setLogEvents((prevEvents) => [...prevEvents, event])
        } else {
          const newEvents = [...events, event]
          setEvents((prevEvents) => [...prevEvents, event])
          if (handleOutputChange !== undefined) {
            handleOutputChange(JSON.stringify(newEvents, null, 2) ?? "")
          }
        }
      }
    }
  }, [lastMessage])

  return (
    <>
      <FullScreen handle={handle}>
        <div
          className="Timeline"
          style={{
            height: handle.active ? "100%" : "500px",
            width: "100%",
            maxWidth: handle.active ? "100%" : "800px",
            // height: "100%",
            // minHeight: "500px",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Row style={{ display: "flex", flexDirection: "row" }}>
            <Col span={12} style={{ display: "flex", flexDirection: "row" }}>
              <input
                type="text"
                placeholder="Search"
                onChange={search}
                style={{
                  flex: 1,
                }}
              />
            </Col>
            <Col span={3} style={{ display: "flex", flexDirection: "row" }}>
              <button
                className="Clear-button"
                onClick={() => {
                  setEvents([])
                  setSearchTerm("")
                }}
                style={{
                  flex: 1,
                }}
              >
                Clear
              </button>
            </Col>
            <Col span={3} style={{ display: "flex", flexDirection: "row" }}>
              <button
                className="Clear-button"
                onClick={() => {
                  setShowLogs(!showLogs)
                }}
                style={{
                  flex: 1,
                }}
              >
                Logs
              </button>
            </Col>
            <Col span={6} style={{ display: "flex", flexDirection: "row" }}>
              <button
                className="FullScreen-button"
                onClick={handle.enter}
                style={{
                  flex: 1,
                }}
              >
                Full Screen
              </button>
            </Col>
          </Row>
          {/* <button className="Clear-button" onClick={() => setEvents([])}>
          Clear
        </button> */}
          <div className="Search-filters">{getSearchFilters()}</div>
          <div className="Timeline-Container" style={{ flex: 1 }}>
            <div className="filter-list">
              <Timeline
                style={{
                  height: "100%",
                  minHeight: "300px",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {showLogs ? getLogEvents() : getEvents()}
              </Timeline>
            </div>
            <div
              className="Timeline-Right-Side-Panel"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                height: "100%",
                minHeight: "340px",
              }}
            >
              {getTopics()}
            </div>
          </div>
          {/* <div className="Timeline-Bottom-tray"></div> */}
          <div className="Timeline-footer"></div>
        </div>
      </FullScreen>
    </>
  )
}
