import {
    DocumentTextIcon,
    PlayIcon,
    XMarkIcon
} from "@heroicons/react/24/outline"
import { PaperAirplaneIcon } from "@heroicons/react/24/solid"
import { AttachFileIcon } from "../icons/AttachFile"
import React, { useEffect, useMemo, useState } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { useParams } from "react-router-dom"


const defaultTheme = {
    themeColors: {
        foreground: "#000",
        background: "#fff",
        messageFontWeight: "normal",
        blue: "#007bff",
        red: "red",
        selectionBackground: "#007bff",
        brightBlack: "#000",
        brightWhite: "#fff",
    },
}

const useTheme = () => {
    const [theme, setTheme] = useState(defaultTheme)

    useEffect(() => {
        const themeColors = JSON.parse(localStorage.getItem("themeColors"))
        if (themeColors) {
            setTheme({ themeColors })
        }
    }, [])

    return theme
}

const Style = (props: any) => {
    return (
        <style
            dangerouslySetInnerHTML={{
                __html: props.css,
            }}
        />
    )
}

const MESSAGE_MAX_CHARACTERS = 120000000000000

const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(" ")
}

const hexToRgbA = (hex: string, opacity: string) => {
    if (!hex) return null
    let c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("")
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = `0x${c.join("")}`
        // @ts-ignore
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")},${opacity || "1"
            })`
    }
    throw new Error("Bad Hex")
}

const CustomToolbar = ({
    isSubmitting,
    errors,
    isFiles,
    editor,
    setHasText,
    openDropzone
}: any) => {
    const { themeColors } = useTheme()
    const realText = editor?.getText()
    const isText = realText?.trim()

    useEffect(() => {
        console.log("editor", editor)
        console.log("isText", isText)
        if (isText) {
            setHasText(true)
        } else {
            setHasText(false)
        }
    }, [isText])


    const sendDisabled = isSubmitting || (!isFiles && !isText)

    return (
        <div
            style={{}}
            id="toolbar"
            className="flex items-center justify-between w-full"
        >
            <div className="flex items-center">
                <button className="ql-bold" />
                <button className="ql-italic" />
                <button className="ql-strike" />
                <button className="ql-blockquote" />
                <button className="ql-code" />
                <button className="ql-list" value="ordered" />
                <button className="ql-list" value="bullet" />
                <button className="ql-code-block" />
                <button className="ql-link" />
            </div>
            <div className="ml-auto flex items-center space-x-2">
                <AttachFileIcon
                    style={{ color: "black", width: "20px", height: "20px" }}
                    className="h-5 w-5 cursor-pointer th-color-for"
                    onClick={() => {
                        openDropzone()
                    }}
                />

                <button
                    id="sendButton"
                    type="submit"
                    disabled={sendDisabled}
                    className={classNames(isSubmitting ? "opacity-50" : "")}
                    style={{
                        float: "right",
                        borderRadius: "5px",
                        backgroundColor:
                            // eslint-disable-next-line
                            errors.text && isText
                                ? themeColors?.red
                                : isText || isFiles
                                    ? themeColors?.blue
                                    : "transparent",
                    }}
                >
                    {!isSubmitting && (
                        <>
                            {errors.text && isText ? (
                                <span className="th-color-brwhite">
                                    {MESSAGE_MAX_CHARACTERS - isText.length}
                                </span>
                            ) : (
                                <PaperAirplaneIcon
                                    style={{ color: isText ? "white" : "gray", width: "15px", height: "15px" }}
                                    className={classNames(
                                        isText ? "th-color-brwhite" : "th-color-for"
                                    )}
                                />
                            )}
                        </>
                    )}
                    {isSubmitting && (
                        <svg
                            className="animate-spin h-5 w-5 th-color-for"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            />
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                        </svg>
                    )}
                </button>
            </div>
        </div>
    )
}

const FileThumbnail = ({ file, children }: any) => {
    if (file.type.includes("image/"))
        return (
            <div
                key={file.lastModified}
                className="bg-cover rounded h-20 w-20 relative group"
                style={{ backgroundImage: `url(${URL.createObjectURL(file)})` }}
            >
                {children}
            </div>
        )
    if (file?.type?.includes("video/") || file?.type?.includes("audio/"))
        return (
            <div
                key={file.lastModified}
                className="rounded h-20 w-44 relative group bg-gray-800 border border-gray-600 flex space-x-2 items-center p-1"
            >
                <PlayIcon className="h-9 w-9 text-blue-500 flex-shrink-0" />
                <div className="text-gray-300 font-bold text-sm truncate">
                    {file.name}
                </div>
                {children}
            </div>
        )
    return (
        <div
            key={file.lastModified}
            className="rounded h-20 w-44 relative group bg-gray-800 border border-gray-600 flex space-x-2 items-center p-1"
        >
            <DocumentTextIcon className="h-9 w-9 text-blue-500 flex-shrink-0" />
            <div className="text-gray-300 text-sm font-bold truncate">
                {file.name}
            </div>
            {children}
        </div>
    )
}

const FileViewer = ({ setFiles, files }: any) => {
    const filesViewer = useMemo(
        () => (
            <div
                className={classNames(
                    files?.length ? "" : "hidden",
                    "w-full h-24 px-3 flex py-2"
                )}
            >
                {files?.length &&
                    Array.from(files).map((file: any) => (
                        <FileThumbnail file={file} key={file.lastModified}>
                            <div className="absolute top-0 right-0 bg-gray-700 p-1 rounded-full transform translate-x-1 -translate-y-1 opacity-0 group-hover:opacity-100">
                                <XMarkIcon
                                    className="text-white h-3 w-3 cursor-pointer"
                                    onClick={() => {
                                        setFiles([])
                                    }}
                                />
                            </div>
                        </FileThumbnail>
                    ))}
            </div>
        ),
        [files]
    )
    return filesViewer
}

const SpecialEditor = (props: any) => {
    const {
        placeholder,
        setFieldValue,
        text,
        handleSubmit,
        isSubmitting,
        errors,
        editorRef,
        files,
        setFiles,
        editor,
        setHasText,
        dropzone,
        disabled
    } = props

    const { themeColors } = useTheme()
    const { channelId, dmId } = useParams()

    const modules = useMemo(
        () => ({
            toolbar: {
                container: "#toolbar",
            },
            clipboard: {
                matchVisual: false,
            },
            keyboard: {
                bindings: {
                    tab: false,
                    custom: {
                        key: 13,
                        shiftKey: true,
                        handler: () => {
                            handleSubmit()
                        },
                    },
                },
            },
        }),
        []
    )

    useEffect(() => {
        setFieldValue("text", "")
        document
            .querySelector("#chat-editor > div > div.ql-editor.ql-blank")
            ?.setAttribute("data-placeholder", placeholder)
    }, [placeholder])



    return (
        <>
            {disabled && (
                <div className="absolute w-full h-full bg-gray-200 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white rounded-lg p-4 flex flex-col space-y-4">
                        <div className="text-gray-700" style={{ fontSize: "large" }}>
                            Please select a Pipeline
                        </div>

                    </div>
                </div>
            )}
            <div className="flex flex-col w-full" style={{
                opacity: disabled ? "0.3" : "1",
                pointerEvents: disabled ? "none" : "auto"
            }}>
                <div {...dropzone.getRootProps()}>
                    <input {...dropzone.getInputProps()} />
                    <Style
                        css={`
              .editor .ql-editor {
                
                
                color: ${themeColors?.foreground};
                background-color: ${themeColors?.background};
                font-weight: ${themeColors?.messageFontWeight === "light"
                                ? "300"
                                : "400"};
              }
              .editor .ql-editor {
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
              }
              .quill > .ql-container > .ql-editor.ql-blank::before {
                color: ${themeColors?.foreground};
                font-style: normal;
                opacity: 0.7;
              }
              .ql-snow.ql-toolbar {
                background-color: ${themeColors?.background};
                border-color: ${dropzone.isDragActive
                                ? themeColors?.blue
                                : themeColors?.selectionBackground};
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
              }
  
              /* Code editor */
              .ql-snow .ql-editor pre.ql-syntax {
                background-color: ${themeColors?.brightBlack};
                color: ${themeColors?.brightWhite};
                border-color: ${hexToRgbA(themeColors?.background, "0.2")};
                border-width: 1px;
              }
              .ql-snow .ql-editor code,
              .ql-snow .ql-editor pre {
                background-color: ${themeColors?.brightBlack};
                color: ${themeColors?.brightWhite};
                border-color: ${hexToRgbA(themeColors?.background, "0.2")};
                border-width: 1px;
              }
  
              /* Toolbar icons */
              .ql-snow .ql-stroke {
                stroke: ${themeColors?.foreground};
              }
              .ql-snow .ql-fill,
              .ql-snow .ql-stroke.ql-fill {
                fill: ${themeColors?.foreground};
              }
  
              /* Link */
              .ql-snow .ql-editor a {
                color: ${themeColors?.blue};
                text-decoration: none;
              }
              .ql-snow .ql-editor a:hover {
                text-decoration: underline;
              }
            `}
                    />

                    <ReactQuill


                        onChange={(e) => {
                            console.log("e", e)
                            setFieldValue("text", e)
                        }}

                        value={text}
                        className="editor"
                        placeholder={placeholder}
                        modules={modules}
                        formats={[
                            "bold",
                            "italic",
                            "strike",
                            "list",
                            "code",
                            "link",
                            "blockquote",
                            "code-block",
                        ]}
                        theme="snow"
                        id="chat-editor"
                        ref={editorRef}
                    />
                    <FileViewer files={files} setFiles={setFiles} />
                    <CustomToolbar
                        isSubmitting={isSubmitting}
                        errors={errors}
                        isFiles={!!files?.length}
                        editor={editor}
                        setHasText={setHasText}
                        openDropzone={dropzone.open}
                    />
                </div>
            </div>
        </>
    )
}

export default SpecialEditor