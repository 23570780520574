import React, { useState } from "react"
import AdvancedChatInput from "../../components/inputBar/AdvancedChatInput"
import Messages from "./Messages"

interface MessageProps {
    text?: string;
    isEdited?: boolean;
    objectId?: string;
    senderId?: string;
    thumbnailURL?: string;
    fileURL?: string;
    mediaWidth?: number;
    mediaHeight?: number;
    createdAt?: string;
    fileType?: string;
    fileName?: string;
    fileSize?: number;
    sticker?: string;
}

interface ChatAreaProps {
    disableInput?: boolean;
    onGenerate?: () => void;
    onInputTextChange?: (text: string) => void;
    messages?: MessageProps[];
}

export const ChatArea: React.FC<ChatAreaProps> = (
    { disableInput = false, onGenerate, onInputTextChange, messages = [] }
) => {
    // const [messages, setMessages] = useState([
    //     // {
    //     //     objectId: "1",
    //     //     senderId: "1",
    //     //     text: "Hello",
    //     //     createdAt: "2021-09-01T18:00:00.000Z",

    //     // }
    // ]);

    const onPostData = (data: any) => {
        console.log("onPostData", data)
        var text = data.text
        if (data.fileUploadResult) {
            text = data.fileUploadResult.data.file_text
        }
        const message: MessageProps = {
            ...data,
            createdAt: new Date().toISOString(),
            text: text
        }
        if (onInputTextChange) {
            onInputTextChange(text)
        }
        // setMessages([message, ...messages])
    }

    const onFileUpload = (data: any) => {
        console.log("onFileUpload", data)
        // onInputTextChange(data.data.file_text)
    }

    return (
        <div className="row-span-2 flex flex-col overflow-hidden">
            <div className="min-h-0 flex-1 flex flex-col justify-end overflow-y-auto">
                <Messages lastRead={null} messages={messages} />
                <AdvancedChatInput onPostData={onPostData} onFileUpload={onFileUpload} disabled={disableInput} />
            </div>
        </div>
    )
}