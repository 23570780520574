import React, { useCallback, useEffect, useState } from "react"
import { Row, Col, Card, Typography, Button, Select, Space, Modal, Form, Tabs, Input, Switch, Dropdown, Drawer } from "antd"
import { useTranslation } from "react-i18next"
import ReactDOMServer from "react-dom/server"
import { useLocation } from 'react-router-dom';
import { ChatArea } from "./ChatArea"
import { v4 as uuidv4 } from 'uuid';
import { useDataProvider, useMany, useOne } from "@refinedev/core"
import MDEditor from "@uiw/react-md-editor"
import { IProcessorRow, llmConfigTemplates, processorTemplates } from "../LiveTester/Helpers"
import _ from "lodash"
import { HorizontalList } from "../../components/PipelineEditor/components/HorizontalList"
import { Editor } from "@monaco-editor/react";
import { CourierInfoText } from "../../styled/styled";
import { ChatComponent } from "./ChatComponent";
import {
    useMakePipelineAssistantReadable,
    useMakePipelineAssistantActionable
} from "../../packages/pipeline-assistant/hooks";
import { mermaidExamples } from "./mermaidExamples";
import useWebSocket from "react-use-websocket";



var JSON5 = require("json5")
const { Text } = Typography


function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}


interface GlobalSettings {
    llm_config: {
        server_type: string
        base_inference_url: string
        model_prompt_format: string
        max_input_length: number
        model_params: any
        environment_variables: any
    },
    max_tokens_per_chunk_buffer: number,
    gen_params: any
}


export const PipelineRunnerPage: React.FC = () => {
    const [form] = Form.useForm();
    const [globalSettings, setGlobalSettings] = useState<GlobalSettings>({
        llm_config: {
            server_type: "",
            base_inference_url: "",
            model_prompt_format: "",
            max_input_length: 0,
            model_params: "{}",
            environment_variables: "{}"
        },
        max_tokens_per_chunk_buffer: 0,
        gen_params: "{}"
    })
    const [applyGlobalSettings, setApplyGlobalSettings] = useState(false)
    const [selectedLLMConfigTemplate, setSelectedLLMConfigTemplate] =
        useState<any>(llmConfigTemplates[0])
    const { t } = useTranslation()
    const [inputText, setInputText] = useState<string>("")
    const [isGenerating, setIsGenerating] = useState(false)
    const [messages, setMessages] = useState<any[]>([])
    const [pipelines, setPipelines] = useState<any[]>([])
    const [snapshots, setSnapshots] = useState<any[]>([])
    const [rows, setRows] = useState<IProcessorRow[]>([])
    const [pipelineIds, setPipelineIds] = useState<string[]>([])
    const [snapshotIds, setSnapshotIds] = useState<string[]>([])
    const [modalOpen, setModalOpen] = useState(false)
    const [chatOpen, setChatOpen] = useState(false)
    const [selectedPipeline, setSelectedPipeline] = useState<any>(
        null
    )
    const [selectedSnapshot, setSelectedSnapshot] = useState<any>(
        null
    )
    const [selectedLLMConfigTemplate2, setSelectedLLMConfigTemplate2] =
        useState<any>(llmConfigTemplates[0])
    const [selectedProcessorTemplate, setSelectedProcessorTemplate] =
        useState<any>(processorTemplates[0])
    const [pipelineToUpdate, setPipelineToUpdate] = useState<any>(null)
    const [saveModalIsOpen, setSaveModalIsOpen] = useState<boolean>(false)
    const [saveType, setSaveType] = useState<string>("snapshot")
    const [saveSnapshotIdentifier, setSaveSnapshotIdentifier] = useState<string>("")
    const [saveSnapshotDescription, setSaveSnapshotDescription] = useState<string>("")

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const runLocal = queryParams.get('runLocal') === 'true' ? true : false;
    const [logEvents, setLogEvents] = React.useState<any[]>([])
    const [events, setEvents] = React.useState<any[]>([])

    const {
        sendMessage,
        sendJsonMessage,
        lastMessage,
        lastJsonMessage,
        readyState,
        getWebSocket,
    } = useWebSocket(
        "wss://qbqljzxguj.execute-api.us-east-1.amazonaws.com/prod",
        {
            onOpen: () => {
                sendMessage(
                    JSON.stringify({
                        message: "subscribe",
                        topic: "Zpipeliner-Results",
                    })
                )
            },
            shouldReconnect: (closeEvent) => true,
        }
    )


    const { data: pipelinesData, isLoading: pipelinesIsLoading } = useMany({
        resource: "pipelines",
        ids: pipelineIds,
    });

    const { data: snapshotsData, isLoading: snapshotsIsLoading, refetch: refetchSnapshots } = useMany({
        resource: `pipelines/${selectedPipeline?.value}/snapshots`,
        ids: snapshotIds,
        queryOptions: {
            enabled: selectedPipeline !== null,
        }
    });

    const { data: selectedSnapshotData, isLoading: selectedSnapshotIsLoading } = useOne({
        resource: `pipelines/${selectedPipeline?.value}/snapshots`,
        id: selectedSnapshot?.value,
        queryOptions: {
            enabled: selectedSnapshot !== null,
        }
    });


    const showChat = () => {
        setChatOpen(true)
    }
    const onCloseChat = () => {
        setChatOpen(false)
    }

    const transformPipelineDataForSnapshot = (data: any) => {

        let transformedData = {
            ..._.omit(data, ["processors"]),
            processors_attributes: {}
        }

        for (let i = 0; i < data.processors.length; i++) {
            const processor = data.processors[i]
            const transformedProcessor = {
                ..._.omit(processor, ["json_schema", "prompt", "llm_config"]),
                json_schema_attributes: {
                    ...processor.json_schema
                },
                prompt_attributes: {
                    ...processor.prompt
                },
                llm_config_attributes: {
                    ...processor.llm_config
                }
            }
            transformedData.processors_attributes[i.toString()] = transformedProcessor
        }

        return transformedData

    }

    const create_model_snapshot = async (model_type: string, model_data: any, snapshot_identifier: string, snapshot_metadata = null) => {
        const result = await myDataProvider.create_model_snapshot({
            model_type: model_type,
            model_data: transformPipelineDataForSnapshot(model_data),
            snapshot_identifier: snapshot_identifier,
            snapshot_metadata: snapshot_metadata,
            should_save_model: false
        })
        console.log("result:", result)
        return result
    }

    const create_pipeline_snapshot = async (identifier = null, metadata = null) => {
        const selectedPipelineId = selectedPipeline?.value
        let selectedPipelineData = pipelinesData?.data?.find((pipeline) => pipeline.id === selectedPipelineId)
        const snapshotIdentifier = identifier !== null ? identifier : uuidv4()
        const snapshotMetadata = metadata !== null ? metadata : {
            comments: ""
        }
        selectedPipelineData.processors = rows.map((row) => {
            return {
                id: row.id,
                name: row.name,
                llm_config: {
                    ...row.llm_config,
                    model_params: JSON5.parse(
                        row.llm_config?.model_params ?? "{}"
                    ),
                    environment_variables: JSON5.parse(
                        row.llm_config?.environment_variables ?? "{}"
                    ),
                    id: row.llm_config?.id,
                    name: "test"
                },
                json_schema: {
                    id: row.json_schema?.id,
                    name: row.json_schema?.name,
                    content: JSON5.parse(row.json_schema?.content ?? "{}"),
                },
                prompt: { ...row.prompt, name: "test", id: row.prompt?.id },
                max_tokens_per_chunk_buffer: row.max_tokens_per_chunk_buffer,
                gen_params: JSON5.parse(row.gen_params ?? "{}"),
            }
        })

        const result = await create_model_snapshot("Pipeline", selectedPipelineData, snapshotIdentifier, snapshotMetadata)
        console.log("result:", result)
    }

    const handleSavePipeline = async (pipeline_update_id = null) => {
        console.log("handleSavePipeline")
        console.log("pipeline_update_id:", pipeline_update_id)
        const should_update = pipeline_update_id !== null
        console.log("should_update:", should_update)
        const pipeline_id = pipeline_update_id ?? uuidv4()
        const existing_pipeline = should_update ? pipelinesData?.data?.find((pipeline) => pipeline.id === pipeline_id) : null
        console.log("existing_pipeline:", existing_pipeline)
        const pipeline_name = existing_pipeline?.name ?? "test"
        const result = await myDataProvider.update_or_create_pipeline({
            pipeline: {
                id: pipeline_id,
                name: pipeline_name,
                should_update: should_update,
                processors: rows.map((row) => {
                    const existing_processor = existing_pipeline?.processors?.find((processor) => processor.id === row.id)
                    return {
                        id: row.id,
                        name: row.name,
                        should_update: should_update && existing_processor !== null,
                        llm_config: {
                            ...row.llm_config,
                            should_update: should_update && existing_processor !== null,
                            model_params: JSON5.parse(
                                row.llm_config?.model_params ?? "{}"
                            ),
                            environment_variables: JSON5.parse(
                                row.llm_config?.environment_variables ?? "{}"
                            ),
                        },
                        json_schema: {
                            id: row.json_schema?.id,
                            name: row.json_schema?.name,
                            should_update: should_update && existing_processor !== null,
                            content: JSON5.parse(row.json_schema?.content ?? "{}"),
                        },
                        prompt: { ...row.prompt, should_update: should_update && existing_processor !== null },
                        max_tokens_per_chunk_buffer: row.max_tokens_per_chunk_buffer,
                        gen_params: JSON5.parse(row.gen_params ?? "{}"),
                    }
                }),
            },
        })
        console.log("result:", result)
    }

    const menuItems = [
        {
            key: "create",
            label: "Save As New"
        },
        {
            key: "update",
            label: "Update Existing"
        },
        {
            key: "snapshot",
            label: "Save Snapshot"
        }
    ]

    const handleSaveProcessor = async (processor, processor_update_id = null) => {
        const should_update = processor_update_id !== null
        const result = await myDataProvider.update_or_create_processor({
            processor: { ...processor, should_update: should_update }
        })
        console.log("result:", result)
    }

    const myDataProvider = useDataProvider()("default") as any

    const prepareSettings = (settings: any) => {
        console.log("prepareSettings: ", settings)

        const newSettings = JSON.parse(JSON.stringify(settings))

        const model_params = JSON5.parse(JSON5.stringify(settings.llm_config.model_params))
        const environment_variables = JSON5.parse(JSON5.stringify(settings.llm_config.environment_variables))
        const gen_params = JSON5.parse(JSON5.stringify(settings.gen_params))

        console.log("model_params:", model_params)
        console.log("environment_variables:", environment_variables)
        console.log("gen_params:", gen_params)


        return {
            llm_config: {
                server_type: newSettings.llm_config.server_type,
                base_inference_url: newSettings.llm_config.base_inference_url,
                model_prompt_format: newSettings.llm_config.model_prompt_format,
                max_input_length: newSettings.llm_config.max_input_length,
                model_params: model_params,
                environment_variables: environment_variables
            },
            max_tokens_per_chunk_buffer: newSettings.max_tokens_per_chunk_buffer,
            gen_params: gen_params
        }

    }

    const pipelineEditorParentId = useMakePipelineAssistantReadable("Pipeline Editor Context")

    const pipelineRunnerParentId = useMakePipelineAssistantReadable("Pipeline Runner Context")

    useMakePipelineAssistantReadable(
        "About: This is a tool that helps with designing pipelines to string together series of prompts, config settings, and additional details used to make an orchestrated set of calls to an LLM in order to achieve complex, multi-faceted requests.",
        pipelineEditorParentId,
        ["About", "Pipeline Editor"]
    );

    useMakePipelineAssistantReadable(
        `Important Conventions:
            - Templated variables within a prompt are denoted by single curly braces, e.g. {my_variable}. These variables will be replaced with representative values when the pipeline is run.
            - The initial input text that a user provides, is always referred to as {text}. So it is common that the first prompt in a pipeline will include {text} in some way.
            - The output of each processor will conform to the JSON schema that is specified in the processor's configuration.
            - A processor can reference the output of a previous processor by including the name of the previous processor in the prompt, e.g. {my_previous_processor}.
            - A processor can even reference a specific key in the output of a previous processor by including the name of the previous processor and the key, e.g. {my_previous_processor.my_key}.
            - Finally, when the pipeline runs, there is special logic just to handle prompts containing the input text (i.e. {text}) which does the following:
                1. Determines the length of the prompt (including the input text) and compares it to the max_input_length specified in the LLM config.
                2. If the length is greater than the max_input_length, then a map-reduce-like summarization strategy is used to compress the input text in order for the LLM to successfully handle the prompt.`,

        pipelineEditorParentId,
        ["Important Conventions", "Pipeline Editor"]
    );

    useMakePipelineAssistantReadable(
        "About: To run a pipeline, a user must first select a pipeline from the dropdown menu.  Once a pipeline is selected, the user can then enter text into the input box or upload a document (which will extract the text), and click the 'Generate' button to run the pipeline.",
        pipelineRunnerParentId,
        ["About", "Pipeline Runner"]
    );

    useMakePipelineAssistantReadable(
        JSON.stringify({
            "Selected Pipeline": selectedPipeline?.name ?? "null",
            "Row Schema": `IProcessorRow {
                id: string
                key: string
                row_type: string
                is_active: boolean
                name: string
                llm_config: {
                  id: string
                  server_type: string
                  base_inference_url: string
                  model_prompt_format: string
                  default_system_prompt: string
                  max_input_length: number
                  model_params: string
                  environment_variables: string
                } | null
                json_schema: {
                  id: string
                  name: string
                  content: string
                } | null
                prompt: {
                  id: string
                  text: string
                } | null
                max_tokens_per_chunk_buffer: number
                gen_params: string
              }`,
            rows: rows,

        }),
        pipelineEditorParentId,
        ["Pipeline Editor", "Selected Pipeline", "Row Schema", "Rows", "Processors"]
    );

    const modifyRows = () => {
        console.log("modifyRows: ", rows)
        const newRows = _.cloneDeep(rows)
        for (let i = 0; i < newRows.length; i++) {
            const row = newRows[i]
            if (row.row_type === "processor") {
                const overrides = applyGlobalSettings ? globalSettings : {}
                row.llm_config = {
                    ...row.llm_config,
                    model_params: JSON5.parse(row.llm_config.model_params),
                    environment_variables: JSON5.parse(row.llm_config.environment_variables)
                }
                row.gen_params = JSON5.parse(row.gen_params)
                newRows[i] = { ...row, ...overrides }
            }
        }
        console.log("newRows: ", newRows)
        return newRows
    }


    const handleGenerate2 = async (newRows) => {
        console.log("handleGenerate: ", newRows)
        try {
            const requestId = uuidv4()
            const result = await myDataProvider.generate({
                pipeline: {
                    id: uuidv4(),
                    name: "test",
                    processors: newRows.map((row) => {

                        // const overrides = applyGlobalSettings ? globalSettings : {}

                        return {

                            id: row.id,
                            name: row.name,
                            is_active: row.is_active,
                            llm_config: {
                                server_type: row.llm_config?.server_type ?? "",
                                base_inference_url: row.llm_config?.base_inference_url ?? "",
                                model_prompt_format: row.llm_config?.model_prompt_format ?? "",
                                default_system_prompt: row.llm_config?.default_system_prompt ?? "",
                                max_input_length: row.llm_config?.max_input_length ?? 0,

                                model_params: typeof row.llm_config?.model_params === "string" ? JSON.parse(
                                    row.llm_config?.model_params ?? "{}"
                                ) : row.llm_config?.model_params ?? {},
                                environment_variables: typeof row.llm_config?.environment_variables === "string" ? JSON.parse(
                                    row.llm_config?.environment_variables ?? "{}"
                                ) : row.llm_config?.environment_variables ?? {},
                            },
                            json_schema: {
                                name: row.json_schema?.name,
                                content: JSON5.parse(row.json_schema?.content ?? "{}"),
                            },
                            prompt: row.prompt,
                            max_tokens_per_chunk_buffer: row.max_tokens_per_chunk_buffer,
                            gen_params: typeof row.gen_params === "string" ? JSON.parse(
                                row.gen_params ?? "{}"
                            ) : row.gen_params ?? {},
                            // ...overrides
                        }
                    }),
                },
                input_text: inputText,
                request_id: requestId,
                request_type: null,
                run_locally: runLocal,
            })
            console.log("result:", result)

            return result.data
        } catch (err: any) {
            console.log(err)
            return {
                result: "Error",
                error: err.message,
                stacktrace: err.stack,
            }
        }
    }

    const handleGenerate = async (newRows) => {
        console.log("handleGenerate: ", newRows)
        try {
            const requestId = uuidv4()
            const pipeline_object = {
                id: selectedPipeline.value,
                name: selectedPipeline.name,
                processors: newRows.map((row) => {

                    // const overrides = applyGlobalSettings ? globalSettings : {}

                    return {

                        id: row.id,
                        name: row.name,
                        is_active: row.is_active,
                        llm_config: {
                            server_type: row.llm_config?.server_type ?? "",
                            base_inference_url: row.llm_config?.base_inference_url ?? "",
                            model_prompt_format: row.llm_config?.model_prompt_format ?? "",
                            default_system_prompt: row.llm_config?.default_system_prompt ?? "",
                            max_input_length: row.llm_config?.max_input_length ?? 0,

                            model_params: typeof row.llm_config?.model_params === "string" ? JSON.parse(
                                row.llm_config?.model_params ?? "{}"
                            ) : row.llm_config?.model_params ?? {},
                            environment_variables: typeof row.llm_config?.environment_variables === "string" ? JSON.parse(
                                row.llm_config?.environment_variables ?? "{}"
                            ) : row.llm_config?.environment_variables ?? {},
                        },
                        json_schema: {
                            name: row.json_schema?.name,
                            content: JSON5.parse(row.json_schema?.content ?? "{}"),
                        },
                        prompt: row.prompt,
                        max_tokens_per_chunk_buffer: row.max_tokens_per_chunk_buffer,
                        gen_params: typeof row.gen_params === "string" ? JSON.parse(
                            row.gen_params ?? "{}"
                        ) : row.gen_params ?? {},
                        // ...overrides
                    }
                }),
            }
            localStorage.setItem("pipeline_object", JSON.stringify(pipeline_object))
            const result = await myDataProvider.generate({
                resource: "pipelines",
                variables: {
                    pipeline: pipeline_object,
                    input_text: inputText,
                },
            })
            console.log("result:", result)

            return result.data
        } catch (err: any) {
            console.log(err)
            return {
                result: "Error",
                error: err.message,
                stacktrace: err.stack,
            }
        }
    }

    const getOutput = useCallback(async () => {
        const request_id = sessionStorage.getItem("request_id")
        const result = await myDataProvider.get_pipeline_output({
            request_id: request_id ?? "",
        })
        console.log("result from get_output: ", result)
        handleResult(result.data)
        sessionStorage.removeItem("request_id")
        sessionStorage.removeItem("job_id")
    }
        , [])


    const startPollingForResult = useCallback(() => {
        const interval = setInterval(async () => {
            const job_id = sessionStorage.getItem("job_id")
            const result = await myDataProvider.job_status({
                job_id: job_id ?? "",
            })

            console.log("result from poll: ", result)

            if (result.data.status === "Closed") {
                clearInterval(interval)
                getOutput()
            }
            else if (result.data.status === "Error") {
                clearInterval(interval)
                sessionStorage.removeItem("request_id")
                sessionStorage.removeItem("job_id")
                setIsGenerating(false)
            }
        }, 5000)
    }
        , [])

    const renderJsonValue = (value: any) => {
        if (typeof value === "string") {
            return value
        }
        else if (typeof value === "object") {
            return JSON.stringify(value)
        }
        else {
            return value
        }
    }

    const generateMessages = useCallback((result: any) => {
        var newMessages = [

        ]

        const processors = result.pipeline_config.processors
        for (let i = 0; i < processors.length; i++) {
            if (processors[i].is_active === false) {
                continue
            }
            const processor = processors[i]
            const processorOutput = result.output[processor.name]
            var msg = `# ${processor.name}\n\n`
            msg += `# Input\n\n`
            msg += "```\n" + processor.llm_config.default_system_prompt + "\n"
            msg += processor.prompt.text + "\n```\n\n"

            msg += `# Output\n\n`

            if (processorOutput === null) {
                msg += "```\n" + "null" + "\n```\n\n"
            }
            else {
                // Iterate through keys in output
                for (const [key, value] of Object.entries(processorOutput)) {
                    msg += `\n\n### ${key}:\n\n`
                    msg += `${renderJsonValue(value)}`
                }
            }

            const processorMessage = {
                objectId: uuidv4(),
                createdAt: new Date().getTime(),
                text: ReactDOMServer.renderToString(
                    <MDEditor.Markdown
                        className="ctext-wrap-content"
                        source={msg}

                    />
                ),

            }
            newMessages.push(processorMessage)
        }
        var myMessages = [...newMessages, ...messages]
        // sort by createdat, newest first
        myMessages.sort((a, b) => {
            return b.createdAt - a.createdAt
        })

        setMessages(myMessages)
    }, [])

    const handleResult = useCallback((result: any) => {
        console.log("handleResult: ", result)
        setIsGenerating(false)
        generateMessages(result)

    }, [])

    const handleSaveGlobalSettings = (values) => {
        console.log("values:", values)
        setGlobalSettings({
            gen_params: JSON5.parse(JSON5.stringify(values.gen_params, null, 4)),
            llm_config: {
                ...values.llm_config,
                model_params: JSON5.parse(JSON5.stringify(values.llm_config.model_params, null, 4)),
                environment_variables: JSON5.parse(JSON5.stringify(values.llm_config.environment_variables, null, 4))
            },
            max_tokens_per_chunk_buffer: values.max_tokens_per_chunk_buffer
        })
    }


    useMakePipelineAssistantActionable(
        {
            name: "GetMermaidDiagramExampleByType",
            description: "Get Mermaid Diagram Example By Type - Returns example syntax for the specified Mermaid diagram type. Should ALWAYS be called before trying to generate any user-requested Mermaid diagrams.",
            argumentAnnotations: [
                {
                    name: "diagramType",
                    description: "The type of Mermaid diagram to return an example of. Valid options: 'sequence', 'flow', 'class', 'state', 'er', 'gantt', 'userJourney', 'git', 'pie', 'mindmap', 'quadrantChart', and 'xyChart'.",
                    type: "string",
                    required: true,
                }

            ],
            implementation: async (diagramType: string) => {

                const mermaidSyntax = mermaidExamples[diagramType]

                const result = {
                    status: "Success",
                    diagramType: diagramType,
                    example: mermaidSyntax,
                }
                return result
            }
        },
        []
    );

    useMakePipelineAssistantActionable(
        {
            name: "EditProcessorRow",
            description: "Edit Pipeline Rows - Takes a new set of rows and updates the Pipeline with this new list.",
            argumentAnnotations: [
                {
                    name: "processorKey",
                    description: "The key of the processor to edit.",
                    type: "string",
                    required: true,
                },
                {
                    name: "processorJson",
                    description: "JSON representation of the new Processor to update the Pipeline with.  This will be parsed and will replace the existing IProcessorRow object.",
                    type: "string",
                    required: true,
                }
            ],
            implementation: async (processorKey: string, processorJson: string) => {

                setRows(prevRows => {
                    const newRows = _.cloneDeep(prevRows);
                    const row = newRows.find(row => row.key === processorKey);
                    const newProcessor = JSON.parse(processorJson)
                    const index = newRows.indexOf(row);
                    if (index !== -1) {
                        newRows[index] = newProcessor;
                    }
                    return newRows;
                });
                const result = {
                    status: "Successfully edited processor with key " + processorKey + " to new processor " + processorJson,
                }
                return result
            }
        },
        [rows]
    );
    useMakePipelineAssistantActionable(
        {
            name: "EditProcessorPosition",
            description: "Edit Processor Position - Moves a processor to a new position in the pipeline.",
            argumentAnnotations: [
                {
                    name: "processorKey",
                    description: "The key of the processor to edit.",
                    type: "string",
                    required: true,
                },
                {
                    name: "position",
                    description: "The new position of the processor.",
                    type: "number",
                    required: true,
                }
            ],
            implementation: async (processorKey: string, position: number) => {

                setRows(prevRows => {
                    const newRows = _.cloneDeep(prevRows);
                    const row = newRows.find(row => row.key === processorKey);
                    const index = newRows.indexOf(row);
                    if (index !== -1) {
                        newRows.splice(index, 1);
                        newRows.splice(position, 0, row);
                    }
                    return newRows;
                });
                const result = {
                    status: "Successfully edited processor with key " + processorKey + " to position " + position,
                }
                return result
            }
        },
        [rows]
    );
    useMakePipelineAssistantActionable(
        {
            name: "EditProcessorActiveState",
            description: "Edit Processor Active State - Sets the active state of a processor.",
            argumentAnnotations: [
                {
                    name: "processorKey",
                    description: "The key of the processor to edit.",
                    type: "string",
                    required: true,
                },
                {
                    name: "isActive",
                    description: "The new active state of the processor.",
                    type: "boolean",
                    required: true,
                }
            ],
            implementation: async (processorKey: string, isActive: boolean) => {
                console.log("setting processor active state")
                console.log("rows: ", rows)
                console.log()

                setRows(prevRows => {
                    const newRows = _.cloneDeep(prevRows);
                    const row = newRows.find(row => row.key === processorKey);
                    const index = newRows.indexOf(row);
                    if (index !== -1) {
                        newRows[index].is_active = isActive;
                    }
                    return newRows;
                });
                const result = {
                    status: "Successfully edited processor with key " + processorKey + " to active state " + isActive,
                }
                return result
            }
        },
        [rows]
    );
    useMakePipelineAssistantActionable(
        {
            name: "EditProcessorConfig",
            description: "Edit Processor Config - Edit the top-level config of a processor.",
            argumentAnnotations: [
                {
                    name: "processorKey",
                    description: "The key of the processor to edit.",
                    type: "string",
                    required: true,
                },
                {
                    name: "updates",
                    description: "JSON representation of the updates to the processor config (name, max_tokens_per_chunk_buffer, gen_params).",
                    type: "string",
                    required: true,
                }
            ],
            implementation: async (processorKey: string, updates: string) => {

                setRows(prevRows => {
                    const newRows = _.cloneDeep(prevRows);
                    const row = newRows.find(row => row.key === processorKey);
                    const index = newRows.indexOf(row);
                    const updatesObject = JSON.parse(updates)
                    if (index !== -1) {
                        newRows[index] = { ...row, ...updatesObject };
                    }
                    return newRows;
                });
                const result = {
                    status: "Successfully edited processor with key " + processorKey + " to new config " + updates,
                }
                return result
            }
        },
        [rows]
    );
    useMakePipelineAssistantActionable(
        {
            name: "EditProcessorLLMConfig",
            description: "Edit Processor LLM Config - Edit the LLM config of a processor.",
            argumentAnnotations: [
                {
                    name: "processorKey",
                    description: "The key of the processor to edit.",
                    type: "string",
                    required: true,
                },
                {
                    name: "updates",
                    description: "JSON representation of the updates to the processor LLM config (server_type, base_inference_url, model_prompt_format, default_system_prompt, max_input_length, model_params, environment_variables).",
                    type: "string",
                    required: true,
                }
            ],
            implementation: async (processorKey: string, updates: string) => {

                setRows(prevRows => {
                    const newRows = _.cloneDeep(prevRows);
                    const row = newRows.find(row => row.key === processorKey);
                    const index = newRows.indexOf(row);
                    const updatesObject = JSON.parse(updates)
                    if (index !== -1) {
                        newRows[index].llm_config = { ...row.llm_config, ...updatesObject };
                    }
                    return newRows;
                });
                const result = {
                    status: "Successfully edited processor with key " + processorKey + " to new LLM config " + updates,
                }
                return result
            }
        },
        [rows]
    );
    useMakePipelineAssistantActionable(
        {
            name: "EditProcessorJSONSchema",
            description: "Edit Processor JSON Schema - Edit the JSON Schema of a processor.",
            argumentAnnotations: [
                {
                    name: "processorKey",
                    description: "The key of the processor to edit.",
                    type: "string",
                    required: true,
                },
                {
                    name: "updates",
                    description: "JSON representation of the updates to the processor JSON Schema (name, content).",
                    type: "string",
                    required: true,
                }
            ],
            implementation: async (processorKey: string, updates: string) => {

                setRows(prevRows => {
                    const newRows = _.cloneDeep(prevRows);
                    const row = newRows.find(row => row.key === processorKey);
                    const index = newRows.indexOf(row);
                    const updatesObject = JSON.parse(updates)
                    if (index !== -1) {
                        newRows[index].json_schema = { ...row.json_schema, ...updatesObject };
                    }
                    return newRows;
                });
                const result = {
                    status: "Successfully edited processor with key " + processorKey + " to new JSON Schema " + updates,
                }
                return result
            }
        },
        [rows]
    );
    useMakePipelineAssistantActionable(
        {
            name: "EditProcessorPrompt",
            description: "Edit Processor Prompt - Edit the prompt of a processor.",
            argumentAnnotations: [
                {
                    name: "processorKey",
                    description: "The key of the processor to edit.",
                    type: "string",
                    required: true,
                },
                {
                    name: "updates",
                    description: "JSON representation of the updates to the processor prompt (text).",
                    type: "string",
                    required: true,
                }
            ],
            implementation: async (processorKey: string, updates: string) => {

                setRows(prevRows => {
                    const newRows = _.cloneDeep(prevRows);
                    const row = newRows.find(row => row.key === processorKey);
                    const index = newRows.indexOf(row);
                    const updatesObject = JSON.parse(updates)
                    if (index !== -1) {
                        newRows[index].prompt = { ...row.prompt, ...updatesObject };
                    }
                    return newRows;
                });
                const result = {
                    status: "Successfully edited processor with key " + processorKey + " to new prompt " + updates,
                }
                return result
            }
        },
        [rows]
    );
    useMakePipelineAssistantActionable(
        {
            name: "AddProcessor",
            description: "Add Processor - Add a new processor to the pipeline.",
            argumentAnnotations: [
                {
                    name: "processorJson",
                    description: "JSON representation of the new Processor to add to the Pipeline.  This will be parsed and will be added to the existing list of IProcessorRow objects.",
                    type: "string",
                    required: true,
                },
                {
                    name: "position",
                    description: "The position to add the new processor in the pipeline. Defaults to the end of the pipeline.",
                    type: "number",
                    required: false,
                }
            ],
            implementation: async (processorJson: string, position: number) => {


                setRows(prevRows => {
                    const newRows = _.cloneDeep(prevRows);
                    const newProcessor = JSON.parse(processorJson)
                    if (position === undefined) {
                        newRows.push(newProcessor)
                    }
                    else {
                        newRows.splice(position, 0, newProcessor)
                    }
                    return newRows;
                });
                const result = {
                    status: "Successfully added processor " + processorJson + " to position " + position,
                }
                return result
            }
        },
        [rows]
    );
    useMakePipelineAssistantActionable(
        {
            name: "CopyProcessor",
            description: "Copy Processor - Copy an existing processor in the pipeline.",
            argumentAnnotations: [
                {
                    name: "processorKey",
                    description: "The key of the processor to copy.",
                    type: "string",
                    required: true,
                },
                {
                    name: "position",
                    description: "The position to add the new processor in the pipeline. Defaults to the end of the pipeline.",
                    type: "number",
                    required: false,
                }
            ],
            implementation: async (processorKey: string, position: number) => {


                setRows(prevRows => {
                    const newRows = _.cloneDeep(prevRows);
                    const row = newRows.find(row => row.key === processorKey);
                    const newProcessor = _.cloneDeep(row)
                    newProcessor.id = uuidv4()
                    newProcessor.key = uuidv4()
                    if (position === undefined) {
                        newRows.push(newProcessor)
                    }
                    else {
                        newRows.splice(position, 0, newProcessor)
                    }
                    return newRows;
                });
                const result = {
                    status: "Successfully copied processor with key " + processorKey + " to position " + position,
                }
                return result
            }
        },
        [rows]
    );
    useMakePipelineAssistantActionable(
        {
            name: "DeleteProcessor",
            description: "Delete Processor - Delete an existing processor in the pipeline.",
            argumentAnnotations: [
                {
                    name: "processorKey",
                    description: "The key of the processor to delete.",
                    type: "string",
                    required: true,
                }
            ],
            implementation: async (processorKey: string) => {


                setRows(prevRows => {
                    const newRows = _.cloneDeep(prevRows);
                    const row = newRows.find(row => row.key === processorKey);
                    const index = newRows.indexOf(row);
                    if (index !== -1) {
                        newRows.splice(index, 1);
                    }
                    return newRows;
                });
                const result = {
                    status: "Successfully deleted processor with key " + processorKey,
                }
                return result
            }
        },
        [rows]
    );




    const resetRows = (except = null) => {
        setRows(rows.filter((row) => row.key === except));
    };

    const setRowsToProcessors = (processors) => {
        setRows(processors.map((processor) => ({
            id: processor.id,
            key: uuidv4(),
            row_type: "processor",
            is_active: processor.is_active,
            name: processor.name,
            llm_config: mapProcessorConfig(processor.llm_config),
            json_schema: mapJsonSchema(processor.json_schema),
            prompt: { text: processor.prompt?.text ?? "" },
            max_tokens_per_chunk_buffer: processor.max_tokens_per_chunk_buffer,
            gen_params: JSON5.stringify(processor.gen_params ?? {}, null, 4),
        })));
    };

    const mapProcessorConfig = (config) => ({
        server_type: config?.server_type ?? "",
        base_inference_url: config?.base_inference_url ?? "",
        model_prompt_format: config?.model_prompt_format ?? "",
        default_system_prompt: config?.default_system_prompt ?? "",
        max_input_length: config?.max_input_length ?? 0,
        model_params: JSON5.stringify(config?.model_params ?? {}, null, 4),
        environment_variables: JSON5.stringify(config?.environment_variables ?? {}, null, 4),
    });

    const mapJsonSchema = (schema) => ({
        name: schema?.name ?? "",
        content: JSON5.stringify(schema?.content ?? {}, null, 4),
    });


    const setPipelineOptionsFromData = (data: any) => {

        setPipelines(
            data?.map((pipeline) => {
                return {
                    name: pipeline.name,
                    value: pipeline.id,
                }
            })
        )
    }

    const setSnapshotOptionsFromData = (data: any) => {

        setSnapshots(
            data?.map((snapshot) => {
                return {
                    name: snapshot.id,
                    value: snapshot.id,
                }
            })
        )
    }

    const clearSnapshots = () => {
        // setSnapshots([])
        setSelectedSnapshot(null)
    }

    useEffect(() => {
        if (pipelinesData) {
            setPipelineOptionsFromData(pipelinesData.data);
        }
    }, [pipelinesData]);

    useEffect(() => {
        if (selectedPipeline) {
            const pipeline = pipelinesData?.data?.find(p => p.id === selectedPipeline.value);
            if (pipeline) {
                setRowsToProcessors(pipeline.processors ?? []);
                refetchSnapshots();
            }
        } else {
            resetRows();
            clearSnapshots();
        }
    }, [selectedPipeline, pipelinesData, refetchSnapshots]);

    useEffect(() => {
        if (snapshotsData) {
            setSnapshotOptionsFromData(snapshotsData.data);
        }
    }, [snapshotsData]);

    useEffect(() => {
        if (selectedSnapshotData) {
            if (selectedSnapshotData === null || selectedPipeline === null) {
                resetRows();
            } else {
                setRowsToProcessors(selectedSnapshotData.data?.processors ?? []);
            }
        }
    }, [selectedSnapshotData]);


    useEffect(() => {
        if (selectedLLMConfigTemplate?.value) {
            console.log("selectedLLMConfigTemplate:", selectedLLMConfigTemplate)
            const newGlobalSettings = { ...globalSettings }
            newGlobalSettings.llm_config.server_type = selectedLLMConfigTemplate.value.server_type
            newGlobalSettings.llm_config.base_inference_url = selectedLLMConfigTemplate.value.base_inference_url
            newGlobalSettings.llm_config.model_prompt_format = selectedLLMConfigTemplate.value.model_prompt_format
            newGlobalSettings.llm_config.max_input_length = selectedLLMConfigTemplate.value.max_input_length
            newGlobalSettings.llm_config.model_params = JSON.stringify(selectedLLMConfigTemplate.value.model_params)
            newGlobalSettings.llm_config.environment_variables = JSON5.stringify(selectedLLMConfigTemplate.value.environment_variables)
            console.log("newGlobalSettings:", newGlobalSettings)
            setGlobalSettings(newGlobalSettings)

        }
    }
        , [selectedLLMConfigTemplate])

    useEffect(() => {
        form.setFieldsValue(globalSettings)
    }, [form, globalSettings])



    return (
        <>

            <Drawer
                contentWrapperStyle={{
                }}
                title="Chat with Assistant"
                placement="right"
                onClose={onCloseChat}
                open={chatOpen}
                size={"large"}
            >
                <ChatComponent />
            </Drawer>
            <Modal
                open={saveModalIsOpen}
                title={saveType == "update" ? "Select Pipeline to Update" : "Create Pipeline Snapshot"}
                onOk={() => {
                    if (saveType == "update") {
                        console.log("pipelineToUpdate:", pipelineToUpdate)
                        handleSavePipeline(pipelineToUpdate?.value)
                        setSaveModalIsOpen(false)
                    }
                    else {
                        create_pipeline_snapshot(saveSnapshotIdentifier, {
                            notes: saveSnapshotDescription,
                        })
                        setSaveModalIsOpen(false)
                    }


                }}
                onCancel={() => {
                    setSaveModalIsOpen(false)

                }}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <CancelBtn />
                        <OkBtn />
                    </>
                )}
            >
                <>
                    {saveType == "update" ? (
                        <Select
                            placeholder="Select Pipeline"
                            allowClear
                            style={{ width: 300 }}
                            loading={pipelinesIsLoading}
                            onChange={(value) => {
                                console.log("value:", value)
                                console.log("pipelines:", pipelines)
                                setPipelineToUpdate(
                                    pipelines.find(
                                        (pipeline) => pipeline.value === value
                                    ) ?? null
                                )
                            }}
                            options={[
                                ...pipelines.map((pipeline) => {
                                    return {
                                        label: pipeline.name,
                                        value: pipeline.value,
                                    }
                                }),
                            ]}
                        />
                    ) : (
                        <>
                            <div className="w-full">
                                <form className="bg-white shadow-md rounded px-8 pt-4 mt-4 pb-8 mb-0">
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="snapshotIdentifier">
                                            Snapshot Identifier
                                        </label>

                                        <Input
                                            id="snapshotIdentifier"
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            placeholder="Enter Snapshot Identifier"
                                            allowClear

                                            onChange={(e) => {
                                                setSaveSnapshotIdentifier(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="snapshotDescription">
                                            Snapshot Description
                                        </label>

                                        <Input
                                            id="snapshotDescription"
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                            placeholder="Enter Snapshot Description"
                                            allowClear

                                            onChange={(e) => {
                                                setSaveSnapshotDescription(e.target.value)
                                            }}
                                        />

                                    </div>

                                </form>
                            </div>






                        </>
                    )}

                </>
            </Modal>
            <Modal
                title={
                    <>
                        <div className="flex items-center justify-between">
                            <Text strong>
                                {t("Edit Global Overrides")}
                            </Text>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center justify-between">
                                    <Text style={{ marginRight: "20px", fontWeight: 200 }}>LLM Config Templates</Text>
                                    <Space />

                                    <Select
                                        placeholder="Select Template"
                                        allowClear
                                        defaultValue={selectedLLMConfigTemplate.name}
                                        style={{ width: 300, marginRight: 20 }}
                                        onChange={(value) => {

                                            setSelectedLLMConfigTemplate(
                                                llmConfigTemplates.find(
                                                    (template) => template.name === value
                                                ) ?? {}
                                            )
                                        }}
                                        options={[
                                            ...llmConfigTemplates.map((template) => {
                                                return {
                                                    label: template.name,
                                                    value: template.name,
                                                }
                                            }),
                                        ]}
                                    />
                                    <Space />
                                </div>

                            </div>
                        </div>
                    </>
                }
                centered
                open={modalOpen}
                onOk={() => {
                    form.submit()
                    setModalOpen(false)
                }}
                onCancel={() => {
                    setModalOpen(false)
                    form.resetFields()
                }}

                width={"800px"}
                style={{
                    top: "100px"
                }}

            >
                <Form
                    layout={"vertical"}
                    form={form}
                    style={{
                        overflowX: "hidden",
                        overflowY: "auto",
                        height: "500px",
                        width: "100%"
                    }}
                    initialValues={
                        {
                            ...globalSettings,
                            llm_config: {
                                ...globalSettings.llm_config,
                                model_params: JSON.stringify(
                                    JSON5.parse(
                                        globalSettings.llm_config.model_params
                                    ),
                                    null,
                                    4
                                ),
                                environment_variables: JSON.stringify(
                                    JSON5.parse(
                                        globalSettings.llm_config.environment_variables
                                    ),
                                    null,
                                    4
                                )
                            },
                            gen_params: JSON.stringify(
                                JSON5.parse(
                                    globalSettings.gen_params
                                ),
                                null,
                                4
                            )
                        }
                    }
                    onFinish={() => { const values = form.getFieldsValue(true); handleSaveGlobalSettings(values) }}
                >
                    <Tabs className="card-tabs">
                        <Tabs.TabPane tab="LLM" key='llm'>

                            <Form.Item label="Server Type" name={["llm_config", "server_type"]}>
                                <Input
                                    value={globalSettings.llm_config.server_type}
                                />
                            </Form.Item>
                            <Form.Item label="Base Inference URL" name={["llm_config", "base_inference_url"]}>
                                <Input
                                    value={globalSettings.llm_config.base_inference_url}
                                />
                            </Form.Item>
                            <Form.Item label="Model Prompt Format" name={["llm_config", "model_prompt_format"]}>
                                <Input
                                    value={globalSettings.llm_config.model_prompt_format}
                                />
                            </Form.Item>
                            <Form.Item label="Max Input Length" name={["llm_config", "max_input_length"]}>
                                <Input
                                    value={globalSettings.llm_config.max_input_length}
                                />
                            </Form.Item>
                            <Form.Item label="Model Params" name={["llm_config", "model_params"]}>
                                <Editor
                                    height="150px"
                                    language="json"

                                    onChange={(value) => {
                                        form.setFieldValue(["llm_config", "model_params"], value)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Environment Variables" name={["llm_config", "environment_variables"]}>

                                <Editor
                                    height="150px"
                                    language="json"
                                    onChange={(value) => {
                                        form.setFieldValue(["llm_config", "environment_variables"], value)
                                    }}
                                />
                            </Form.Item>
                        </Tabs.TabPane>


                        <Tabs.TabPane tab="Additional" key='additional'>
                            <Form.Item label="Max Tokens/Chunk Buffer" name={"max_tokens_per_chunk_buffer"}>
                                <Input
                                    value={globalSettings.max_tokens_per_chunk_buffer}
                                />
                            </Form.Item>
                            <Form.Item label="Generation Params" name={"gen_params"}>
                                <Editor
                                    height="350px"
                                    language="json"
                                    onChange={(value) => {
                                        form.setFieldValue(["gen_params"], value)

                                    }}
                                />
                            </Form.Item>
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            </Modal>

            <Row gutter={[8, 8]}>

                <Col md={24}>

                    <Row gutter={[16, 16]}>
                        <Col xl={10} lg={24} md={24} sm={24} xs={24}>

                        </Col>
                        <Col xl={7} lg={12} md={24} sm={24} xs={24}>

                        </Col>
                        <Col xl={7} lg={12} md={24} sm={24} xs={24}>

                        </Col>
                    </Row>
                </Col>
                <Col xl={17} lg={16} md={24} sm={24} xs={24}>

                    <Card
                        bodyStyle={{
                            height: 550,
                            padding: 0,
                        }}
                        title={
                            <>
                                <div className="flex items-center justify-between">
                                    <Text strong>
                                        {t("Pipeline Runner")}
                                    </Text>
                                    <div className="flex items-center justify-between">

                                        <div className="flex items-center justify-between">
                                            <Text style={{ marginRight: "20px", fontWeight: 200 }}>Load Pipeline</Text>
                                            <Space />
                                            <Select
                                                placeholder="Select Pipeline"
                                                allowClear
                                                style={{ width: 300 }}
                                                loading={pipelinesIsLoading}
                                                onChange={(value) => {
                                                    console.log("value:", value)
                                                    setSelectedPipeline(
                                                        pipelines.find(
                                                            (pipeline) => pipeline.value === value
                                                        ) ?? null
                                                    )
                                                }}
                                                options={[
                                                    ...pipelines.map((pipeline) => {
                                                        return {
                                                            label: pipeline.name,
                                                            value: pipeline.value,
                                                        }
                                                    }),
                                                ]}
                                            />
                                        </div>
                                        {selectedPipeline && snapshots.length > 0 && (
                                            <div className="flex items-center justify-between" style={{
                                                marginLeft: "20px"
                                            }}>
                                                <Text style={{ marginRight: "20px", fontWeight: 200 }}>Load Snapshot</Text>
                                                <Space />

                                                <Select
                                                    placeholder="Select Snapshot"
                                                    allowClear
                                                    style={{ width: 300 }}
                                                    loading={snapshotsIsLoading}
                                                    value={selectedSnapshot?.value}
                                                    onChange={(value) => {
                                                        console.log("value:", value)
                                                        setSelectedSnapshot(
                                                            snapshots.find(
                                                                (snapshot) => snapshot.value === value
                                                            ) ?? null
                                                        )
                                                    }}
                                                    options={[
                                                        ...snapshots.map((snapshot) => {
                                                            return {
                                                                label: snapshot.name,
                                                                value: snapshot.value,
                                                            }
                                                        }),
                                                    ]}
                                                />
                                            </div>
                                        )}

                                        <Button
                                            type="dashed"
                                            style={{
                                                marginLeft: 20,
                                            }}
                                            onClick={() => {
                                                setMessages([])
                                            }}
                                        >
                                            Clear History
                                        </Button>
                                        <Button
                                            type="primary"
                                            style={{
                                                marginLeft: 20,
                                                backgroundColor: isGenerating ? "red" : "#67be23",
                                            }}
                                            disabled={isGenerating || !selectedPipeline || !inputText}
                                            onClick={async () => {
                                                setIsGenerating(true)
                                                const newRows = modifyRows()
                                                const result = await handleGenerate(newRows)
                                                if (runLocal) {
                                                    handleResult(result)
                                                }
                                                else {
                                                    console.log("result: ", result)
                                                    sessionStorage.setItem("job_id", result.job_id)
                                                    sessionStorage.setItem("request_id", result.request_id)

                                                    // startPollingForResult()
                                                }
                                            }}
                                        >
                                            {isGenerating ? "Running..." : "Generate"}
                                        </Button>
                                    </div>
                                </div>
                            </>
                        }
                    >
                        <div
                            className={classNames(
                                "h-full grid overflow-hidden"
                            )}
                            style={{ zoom: 1.2 }}
                        >
                            <ChatArea
                                disableInput={selectedPipeline === null}
                                onInputTextChange={(text) => {
                                    setInputText(text)
                                }}
                                messages={messages}
                            />
                        </div>
                    </Card>
                </Col>
                <Col xl={7} lg={8} md={24} sm={24} xs={24}>
                    <Card
                        bodyStyle={{
                            height: 550,
                            overflowY: "scroll",
                        }}
                        title={
                            <Text strong style={{ textTransform: "capitalize" }}>
                                {t("Input Text")}
                            </Text>
                        }
                    >
                        <MDEditor.Markdown source={inputText} />
                    </Card>
                </Col>


                <Col span={24}>
                    <Card
                        headStyle={{
                            border: "lightgray",
                            borderWidth: "thin",
                            borderStyle: "solid",

                        }}
                        title={
                            <>
                                <div className="flex items-center justify-between">
                                    <Text strong>
                                        {t("Pipeline")}
                                    </Text>
                                    <div className="flex items-center justify-between">
                                        <div style={{
                                            paddingRight: "20px",
                                            paddingBottom: "5px",
                                            paddingTop: "5px"
                                        }} className="flex items-center justify-between">

                                            <CourierInfoText>
                                                <Text style={{ fontSize: 12, fontWeight: 200 }}>Default LLM Config</Text>
                                                <Select
                                                    defaultValue={selectedLLMConfigTemplate2.name}
                                                    style={{ width: 300 }}
                                                    onChange={(value) => {
                                                        setSelectedLLMConfigTemplate2(
                                                            llmConfigTemplates.find(
                                                                (template) => template.name === value
                                                            ) ?? {}
                                                        )
                                                    }}
                                                    options={[
                                                        ...llmConfigTemplates.map((template) => {
                                                            return {
                                                                label: template.name,
                                                                value: template.name,
                                                            }
                                                        }),
                                                    ]}
                                                />
                                            </CourierInfoText>

                                            <CourierInfoText>
                                                <Text style={{ fontSize: 12, fontWeight: 200 }}>Processor Template</Text>
                                                <Select
                                                    defaultValue={selectedProcessorTemplate.name}
                                                    style={{ width: 300 }}
                                                    onChange={(value) => {
                                                        setSelectedProcessorTemplate(
                                                            processorTemplates.find(
                                                                (template) => template.name === value
                                                            ) ?? {}
                                                        )
                                                    }}
                                                    options={[
                                                        ...processorTemplates.map((template) => {
                                                            return {
                                                                label: template.name,
                                                                value: template.name,
                                                            }
                                                        }),
                                                    ]}
                                                />
                                            </CourierInfoText>




                                            <CourierInfoText>
                                                <Text style={{ fontSize: 12, fontWeight: 200 }}>Add Processor</Text>
                                                <Button
                                                    style={{
                                                        textAlign: "center",
                                                        flex: 1,
                                                    }}
                                                    type="dashed"
                                                    onClick={() => {
                                                        setRows((oldRows) => [
                                                            ...oldRows,
                                                            {
                                                                id: uuidv4(),
                                                                key: uuidv4(),
                                                                row_type: "custom",
                                                                is_active: true,
                                                                name: selectedProcessorTemplate.name,
                                                                llm_config: {
                                                                    id: uuidv4(),
                                                                    server_type: selectedLLMConfigTemplate.value.server_type,
                                                                    base_inference_url:
                                                                        selectedLLMConfigTemplate.value.base_inference_url,
                                                                    model_prompt_format:
                                                                        selectedLLMConfigTemplate.value.model_prompt_format,
                                                                    default_system_prompt:
                                                                        selectedProcessorTemplate.value.default_system_prompt,
                                                                    max_input_length:
                                                                        selectedLLMConfigTemplate.value.max_input_length,
                                                                    model_params: JSON5.stringify(
                                                                        selectedLLMConfigTemplate.value.model_params,
                                                                        null,
                                                                        4
                                                                    ),
                                                                    environment_variables: JSON5.stringify(
                                                                        selectedLLMConfigTemplate.value.environment_variables,
                                                                        null,
                                                                        4
                                                                    ),
                                                                },
                                                                json_schema: {
                                                                    id: uuidv4(),
                                                                    name: selectedProcessorTemplate.value.json_schema.name,
                                                                    content: JSON5.stringify(
                                                                        selectedProcessorTemplate.value.json_schema.content,
                                                                        null,
                                                                        4
                                                                    ),
                                                                },
                                                                prompt: {
                                                                    id: uuidv4(),
                                                                    text: selectedProcessorTemplate.value.prompt,
                                                                },
                                                                max_tokens_per_chunk_buffer: 0,
                                                                gen_params: JSON5.stringify(
                                                                    selectedProcessorTemplate.value.gen_params,
                                                                    null,
                                                                    4
                                                                ),
                                                            },
                                                        ])
                                                    }}
                                                >
                                                    {t("Create")}
                                                </Button>
                                            </CourierInfoText>

                                            <CourierInfoText>
                                                <Text style={{ fontSize: 12, fontWeight: 200 }}>Save Pipeline</Text>
                                                <>
                                                    <Dropdown.Button
                                                        menu={{
                                                            items: menuItems,
                                                            onClick: (e) => {
                                                                console.log("e:", e)
                                                                if (e.key === "create") {
                                                                    setSaveType("create")
                                                                    handleSavePipeline()
                                                                }
                                                                else if (e.key === "update") {
                                                                    setSaveType("update")
                                                                    setSaveModalIsOpen(true)
                                                                }
                                                                else if (e.key == "snapshot") {
                                                                    setSaveType("snapshot")
                                                                    setSaveModalIsOpen(true)
                                                                }

                                                            }
                                                        }}
                                                        style={{
                                                            textAlign: "center",
                                                            flex: 1,
                                                        }}
                                                        type="dashed"
                                                    >
                                                        {t("Save Options")}
                                                    </Dropdown.Button>
                                                </>

                                            </CourierInfoText>

                                        </div>

                                        <div style={{
                                            paddingRight: "20px",
                                            paddingBottom: "5px",
                                            paddingTop: "5px"
                                        }} className="flex items-center justify-between">
                                            <CourierInfoText>
                                                <Text style={{ fontSize: 12, fontWeight: 200 }}>View Overrides</Text>
                                                <Button
                                                    type="dashed"
                                                    style={{

                                                    }}

                                                    onClick={() => {
                                                        setModalOpen(true)
                                                    }
                                                    }
                                                >
                                                    {"Global Overrides"}
                                                </Button>
                                            </CourierInfoText>
                                            <CourierInfoText>
                                                <Text style={{ fontSize: 12, fontWeight: 200 }}>Apply Overrides</Text>
                                                <Switch onChange={() => {
                                                    setApplyGlobalSettings(!applyGlobalSettings)
                                                }} />
                                            </CourierInfoText>
                                            <CourierInfoText>

                                                <Button type="primary" onClick={showChat}>
                                                    Open Chat
                                                </Button>
                                            </CourierInfoText>

                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    >
                        <HorizontalList rows={rows} onChangeRows={setRows} onSaveProcessor={null} editable={true} />
                    </Card>

                    <Card>
                        <Row justify="center">
                            <Col
                                xl={12}
                                lg={10}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-end",
                                }}
                            >

                            </Col>

                            <Col
                                xl={12}
                                lg={14}
                                md={14}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                }}
                            >


                            </Col>


                        </Row>
                    </Card>
                </Col>
            </Row>

        </>
    )
}
