import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DragElement } from "./HorizontalDragElement";


var JSON5 = require("json5")


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const grid = 8;

const getListStyle = (isDraggingOver, itemsLength) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    display: "flex",
    padding: grid,
    width: '100%',
});

interface HorizontalListProps {
    rows: any[]
    onChangeRows: (rows: any[]) => void
    onSaveProcessor: (processor: any) => void
    editable?: boolean
}

export const HorizontalList = (props: HorizontalListProps) => {
    const { rows, onChangeRows, onSaveProcessor, editable = true } = props
    const [items, setItems] = useState(rows);
    console.log("editable", editable)

    const getAvailableTemplateVariables = (index) => {
        const availableTemplateVariables = []
        for (let i = 0; i < index; i++) {
            const processor = items[i]
            if (processor.is_active === true) {
                availableTemplateVariables.push(processor.name)
                const schema = JSON5.parse(items[i].json_schema.content)
                const properties = schema.properties
                for (const property in properties) {
                    availableTemplateVariables.push(processor.name + "." + property)
                }
            }

        }
        // @ts-ignore
        return [...new Set(availableTemplateVariables)]
    }

    const onEdit = (newItem) => {
        const newItems = [...items]
        const [selectedItem, position] = items.reduce((acc, item, index) => {
            if (item.id === newItem.id) {
                acc = [item, index]
            }
            return acc
        }
            , [null, -1])
        if (selectedItem) {
            newItems[position] = newItem
        }
        setItems(newItems)
    }

    const onAdd = () => {
    }

    const onCopy = (id) => {
        console.log(id)
        const [selectedItem, position] = items.reduce((acc, item, index) => {
            if (item.id === id) {
                acc = [item, index]
            }
            return acc
        }
            , [null, -1])
        if (selectedItem) {
            setItems((items) => {
                const newItems = [...items]
                newItems.splice(position + 1, 0, { ...selectedItem, id: Math.random() })
                return newItems
            })
        }


    }


    const onRemove = (id) => {
        console.log(id)
        setItems((items) => items.filter((item) => item.id !== id))
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(newItems);
    };

    useEffect(() => {
        props.onChangeRows(items)
        console.log(items)
    }
        , [items])

    useEffect(() => {
        setItems(rows)
    }
        , [rows])

    return (
        <>

            <div style={{ overflow: "scroll", height: "100%" }}>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <>
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver, items.length)}
                                    {...provided.droppableProps}
                                >

                                    {items.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                            {(provided, snapshot) => (
                                                <DragElement
                                                    provided={provided}
                                                    snapshot={snapshot}
                                                    item={item}
                                                    onAdd={onAdd}
                                                    onRemove={onRemove}
                                                    onCopy={onCopy}
                                                    onEdit={onEdit}
                                                    availableTemplateVariables={getAvailableTemplateVariables(index)}
                                                    onSaveProcessor={onSaveProcessor}
                                                    editable={editable}
                                                />
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            </>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>

        </>
    );
};