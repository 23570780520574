import React from "react"
import { inferSchema } from "@jsonhero/schema-infer"
import { jsonrepair } from "jsonrepair"
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
// import { uuidv4 } from "@antv/xflow-core"
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)

var JSON5 = require("json5")

export const randomUniqueName = () => {
  var uuid = uuidv4()
  // UUID minus dashes
  return `Schema${uuid.replace(/-/g, "")}`
}

export const DraggableWrapper = (props: {
  [x: string]: any
  children: any
}) => {
  const { children, ...restProps } = props
  return (
    <SortableContext
      items={
        children[1] instanceof Array
          ? children[1].map((child) => child.key)
          : []
      }
      strategy={verticalListSortingStrategy}
      {...restProps}
    >
      <tbody {...restProps}>{children}</tbody>
    </SortableContext>
  )
}

export const DraggableRow = (props: { [x: string]: any; children?: any }) => {
  const { attributes, listeners, setNodeRef, isDragging, overIndex, index } =
    useSortable({
      id: props["data-row-key"],
    })
  const isOver = overIndex === index
  const { children, ...restProps } = props
  const isData = children instanceof Array
  const style = {
    ...restProps?.style,
    ...(isData && isDragging ? { background: "#80808038" } : {}),
    ...(isData && isOver ? { borderTop: "5px solid #ec161638" } : {}),
  }

  return (
    <tr ref={setNodeRef} {...attributes} {...restProps} style={style}>
      {children instanceof Array
        ? children.map((child) => {
          const { children, key, ...restProps } = child
          return key === "dragHandle" ? (
            <td {...listeners} {...restProps}>
              {child}
            </td>
          ) : (
            <td {...restProps}>{child}</td>
          )
        })
        : children}
    </tr>
  )
}

export interface IProcessorRow {
  id: string
  key: string
  row_type: string
  is_active: boolean
  name: string
  llm_config: {
    id: string
    server_type: string
    base_inference_url: string
    model_prompt_format: string
    default_system_prompt: string
    max_input_length: number
    model_params: string
    environment_variables: string
  } | null
  json_schema: {
    id: string
    name: string
    content: string
  } | null
  prompt: {
    id: string
    text: string
  } | null
  max_tokens_per_chunk_buffer: number
  gen_params: string
}

export const inferJSONSchema = {
  name: "infer JSON Schema",
  keyCommand: "inferJSONSchema",
  buttonProps: { "aria-label": "Infer JSON Schema" },
  icon: (
    <svg
      viewBox="0 0 16 16"
      width="12px"
      height="12px"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <!-- Mask Definition --> */}
      <defs>
        <mask id="cutout-mask">
          <rect x="0" y="0" width="16" height="16" fill="white" />
          <path
            d="M5 6 Q5 4 7 4 Q9 4 9 6 Q9 8 7 8 Q5 8 5 6 M11 10 Q11 12 9 12 Q7 12 7 10 Q7 8 9 8 Q11 8 11 10"
            fill="black"
          />
          <path d="M7 7 L9 9" stroke="black" strokeWidth="1.5" />
          <circle cx="8" cy="12" r="1.5" fill="black" />
        </mask>
      </defs>

      {/* <!-- Background Circle with Mask Applied --> */}
      <circle
        cx="8"
        cy="8"
        r="8"
        fill="currentColor"
        mask="url(#cutout-mask)"
      />
    </svg>
  ),
  execute: (state: any, api: any) => {
    const currentText = state.text
    const currentCleanedText = currentText.trim()
    const parsedText = JSON5.parse(jsonrepair(currentCleanedText))
    const schema = inferSchema(parsedText).toJSONSchema()
    const jsonSchema = {
      title: randomUniqueName(),
      ...(schema as any),
    }
    const schemaText = JSON5.stringify(jsonSchema, null, 4)
    const newSelection = currentText + "\n\n" + schemaText
    api.replaceSelection(newSelection)
  },
}

export const convertToArticle = {
  name: "convert to article",
  keyCommand: "convertToArticle",
  buttonProps: { "aria-label": "Convert to Article" },
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 1024 1024" className="icon" version="1.1">
      <path transform="translate(0, 100)" d="M229.8 191.8h556c12.8 0 23.2 10.4 23.2 23.2v590.8c0 12.8-10.4 23.2-23.2 23.2h-556c-12.8 0-23.2-10.4-23.2-23.2V215c0-12.8 10.4-23.2 23.2-23.2z" fill="#FFFFFF" />
      <path transform="translate(0, 100)" d="M784.6 828.9H231c-13.4 0-24.4-10.9-24.4-24.4V216.1c0-13.4 10.9-24.4 24.4-24.4h553.6c13.4 0 24.4 10.9 24.4 24.4v588.4c-0.1 13.5-11 24.4-24.4 24.4z m-507.3-46.4h461c13.4 0 24.4-10.9 24.4-24.4V262.5c0-13.4-10.9-24.4-24.4-24.4h-461c-13.4 0-24.4 10.9-24.4 24.4v495.7c0 13.4 10.9 24.3 24.4 24.3z" fill="#333333" />
      <path transform="translate(0, 100)" d="M369.2 296h287.9c25.9 0 46.8 21 46.8 46.8V459c0 25.9-21 46.8-46.8 46.8H369.2c-25.9 0-46.8-21-46.8-46.8V342.9c0-25.9 21-46.9 46.8-46.9z" fill="#F4CE26" />
      <path transform="translate(0, 100)" d="M657.7 505.9H368.8c-25.6 0-46.3-20.8-46.3-46.3V342.4c0-25.5 20.8-46.3 46.3-46.3h288.9c25.6 0 46.3 20.8 46.3 46.3v117.2c0 25.5-20.8 46.3-46.3 46.3zM368.8 342.4v117.2h288.9V342.4H368.8zM345.6 666.7h335.2c12.8 0 23.2 10.4 23.2 23.2 0 12.8-10.4 23.2-23.2 23.2H345.6c-12.8 0-23.2-10.4-23.2-23.2 0-12.8 10.4-23.2 23.2-23.2zM438.3 562.5h144.5c12.8 0 23.2 10.4 23.2 23.2 0 12.8-10.4 23.2-23.2 23.2H438.3c-12.8 0-23.2-10.4-23.2-23.2 0-12.9 10.4-23.2 23.2-23.2z" fill="#333333" />
    </svg>


  ),
  execute: async (state: any, api: any) => {
    const currentText = state.text
    const currentCleanedText = currentText.trim()
    const parsedText = JSON5.parse(jsonrepair(currentCleanedText))
    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    const config = {
      headers: {
        "content-type": "application/json",
        "X-CSRF-Token": csrfToken,
        // Authorization: `Bearer ${token}`,
      }
    }
    var result = null
    try {
      const res = await axios.post(
        "/newsroom/zpipeliner/convert_to_object/",
        {
          object_type: "Article",
          object_init_params: {
            headline: parsedText.headline,
            summary: parsedText.summary,
            body: parsedText.body,
            status: "Pending",
            // title: parsedText.title,
            tags: parsedText.tags,
            sections: [],
            reporter: parsedText.reporter,
            // pitcher: parsedText.pitcher,
            // brands: parsedText.brands,
            // byline_reporter: parsedText.byline_reporter,
            // publisher_name: parsedText.publisher_name,
            publisher_id: 1,
            audit_events: [],
            due_on: dayjs.utc().add(1, "day").format()
          }
        },
        config
      )
      const { origin } = window.location;
      console.log("server res: ", res)
      const article_preview_link = origin + "/newsroom/articles/" + res.data._id["$oid"] + "/preview"
      result = "[Preview this Article](" + article_preview_link + ")"
    }
    catch (err) {
      result = "Error: " + err
      console.log("Error: ", err)
    }


    // const schema = inferSchema(parsedText).toJSONSchema()
    // const jsonSchema = {
    //   title: randomUniqueName(),
    //   ...(schema as any),
    // }
    // const schemaText = JSON5.stringify(jsonSchema, null, 4)
    const newSelection = result

    api.replaceSelection(newSelection)
  },
}

export const defaultSchemaContent = {
  type: "object",
  title: "Schema",
  required: ["result"],
  properties: {
    result: {
      type: "string",
      title: "Result",
      description: "result",
    },
  },
}

export const defaultPromptText = `Please summarize the following content:\n\n{text}`

export const defaultGenParams = {}

export const defaultModelParams = {}

export const defaultEnvVariables = {}

export const llmConfigTemplates = [
  {
    name: "OpenAI GPT-3.5",
    value: {
      server_type: "openai",
      base_inference_url: "https://api.openai.com",
      model_prompt_format: "none",
      default_system_prompt:
        "You are an advanced AI legal news assistant working with Law360 to help with reader personalization, custom content curation, and generating in-depth insights to drive engagement and a deep understanding of Law360's customers. Always respond in the format that is requested. Do not include additional commentary unless explicitly asked for.",
      max_input_length: 16000,
      model_params: {
        "completion_model_name": "gpt-3.5-turbo-16k",
        "chat_completion_model_name": "gpt-3.5-turbo-16k",
      },
      environment_variables: {
        // OPENAI_API_KEY: "your-key-here",
      },
    },
  },
  {
    name: "Claude Violet Relx",
    value: {
      server_type: "claudevioletrelx",
      base_inference_url: "",
      model_prompt_format: "none",
      default_system_prompt:
        "You are an advanced AI legal news assistant working with Law360 to help with reader personalization, custom content curation, and generating in-depth insights to drive engagement and a deep understanding of Law360's customers. Always respond in the format that is requested. Do not include additional commentary unless explicitly asked for.",
      max_input_length: 20000,
      model_params: {},
      environment_variables: {
        // ANTHROPIC_API_KEY: "your-key-here",
      },
    },
  },
  {
    name: "Dolphin 2.2 Vllm",
    value: {
      server_type: "vllm-openai-lite",
      base_inference_url: "http://52.90.249.179:8000",
      model_prompt_format: "chatml",
      default_system_prompt:
        "You are an advanced AI legal news assistant working with Law360 to help with reader personalization, custom content curation, and generating in-depth insights to drive engagement and a deep understanding of Law360's customers. Always respond in the format that is requested. Do not include additional commentary unless explicitly asked for.",
      max_input_length: 9000,
      model_params: {
        "temperature": 0.2,
        "top_p": 0.95,
        "top_k": 50
      },
      environment_variables: {
        // TOGETHER_API_KEY: "your-key-here",
      },
    },
  },
  // {
  //   name: "Mistrallite TGI",
  //   value: {
  //     server_type: "text-generation-inference",
  //     base_inference_url: "http://54.163.31.53:443",
  //     model_prompt_format: "mistrallite",
  //     default_system_prompt:
  //       "You are an advanced AI legal news assistant working with Law360 to help with reader personalization, custom content curation, and generating in-depth insights to drive engagement and a deep understanding of Law360's customers. Always respond in the format that is requested. Do not include additional commentary unless explicitly asked for.",
  //     max_input_length: 20000,
  //     model_params: {
  //       "max_new_tokens": 2500,
  //       "temperature": null,
  //       "top_p": null,
  //       "top_k": null,
  //       "typical_p": null,
  //     },
  //     environment_variables: {},
  //   },
  // },
  {
    name: "Text Generation WebUI (Alpaca) Config",
    value: {
      server_type: "text-generation-webui",
      base_inference_url: "https://localhost.zpipeliner.com",
      model_prompt_format: "alpaca",
      default_system_prompt:
        "You are an advanced AI legal news assistant working with Law360 to help with reader personalization, custom content curation, and generating in-depth insights to drive engagement and a deep understanding of Law360's customers. Always respond in the format that is requested. Do not include additional commentary unless explicitly asked for.",
      max_input_length: 4000,
      model_params: {},
      environment_variables: {},
    },
  },
]

export const processorTemplates = [
  {
    name: "Summarization",
    value: {
      default_system_prompt:
        "You are an advanced AI assistant that specializes in content summarization. You are always helpful, you follow all instructions closely, and you avoid making extra comments.",
      prompt: "Summarize the following content:\n\n {text}",
      json_schema: {
        name: "Summarization",
        content: {
          type: "object",
          title: "Summary",
          required: ["summary"],
          properties: {
            summary: {
              type: "string",
              title: "Summary",
              description: "The generated summary.",
            },
          },
        },
      },
      gen_params: {
        temperature: 0.1,
        max_tokens: 300,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
        stop: ["\n", " Human:", " AI:"],
      },
    },
  },
  {
    name: "Tagging",
    value: {
      default_system_prompt:
        "You are an advanced AI assistant that specializes in content tagging. You are always helpful, you follow all instructions closely, and you avoid making extra comments.",
      prompt: "Tag the following content:\n\n {text}",
      json_schema: {
        name: "Tagging",
        content: {
          type: "object",
          title: "Tags",
          required: ["tags"],
          properties: {
            tags: {
              type: "array",
              title: "Tags",
              description: "The generated tags.",
            },
          },
        },
      },
      gen_params: {
        temperature: 0.1,
        max_tokens: 300,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
        stop: ["\n", " Human:", " AI:"],
      },
    },
  },
  {
    name: "Entity Extraction",
    value: {
      default_system_prompt:
        "You are an advanced AI assistant that specializes in entity extraction. You are always helpful, you follow all instructions closely, and you avoid making extra comments.",
      prompt: "Extract entities from the following content:\n\n {text}",
      json_schema: {
        name: "Entity Extraction",
        content: {
          type: "object",
          title: "Entities",
          required: ["entities"],
          properties: {
            entities: {
              type: "array",
              title: "Entities",
              description: "The generated entities.",
            },
          },
        },
      },
      gen_params: {
        temperature: 0.1,
        max_tokens: 300,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
        stop: ["\n", " Human:", " AI:"],
      },
    },
  },
  {
    name: "Article Generation",
    value: {
      default_system_prompt:
        "You are an advanced AI assistant that specializes in article generation. You are always helpful, you follow all instructions closely, and you avoid making extra comments.",
      prompt: "Generate an article about the following topic:\n\n {text}",
      json_schema: {
        name: "Article Generation",
        content: {
          type: "object",
          title: "Article",
          required: ["article"],
          properties: {
            article: {
              type: "string",
              title: "Article",
              description: "The generated article.",
            },
          },
        },
      },
      gen_params: {
        max_tokens: 1500,
        temperature: 0.7,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
        stop: ["\n", " Human:", " AI:"],
      },
    },
  },
]