import React from 'react';
import ReactDOM from 'react-dom/client';
import './chat.css';
// import "@copilotkit/react-ui/styles.css";

import { useChatCompletion } from '../../hooks/openai';
import { PipelineAssistantContext, UsePipelineAssistantChatOptions, usePipelineAssistantChat } from '../../packages/pipeline-assistant';
import { useMarkdownProcessor } from "../../hooks/use-markdown-processor";
// import { OpenAIContext } from '../../context/openAIProvider';

interface Props {
    children: string;
}


export const AssistantMessage = ({ children }: Props) => {
    const content = useMarkdownProcessor(children) as any;
    // const content = children;

    return (
        // <li className="flex flex-col flex-1 min-w-0 gap-1 ml-6 selection:bg-emerald-300 selection:text-emerald-900">
        //     <div className="p-2 lg:p-6 border-2 border-emerald-200 rounded-lg bg-emerald-50 text-emerald-900 min-w-0 [&>*:first-child]:mt-0 [&>*:last-child]:mb-0">
        //         {content}
        //     </div>
        // </li>
        <li className="flex flex-col flex-1 min-w-0 gap-1 ml-6">
            <div className="p-2 lg:p-6 border-2 rounded-lg min-w-0 [&>*:first-child]:mt-0 [&>*:last-child]:mb-0">
                {content}
            </div>
        </li>
    );
};


const formatDate = (date: Date) =>
    date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short',
    });

export const ChatComponent = ({
    id,
    initialMessages,
    makeSystemMessage
}: UsePipelineAssistantChatOptions) => {
    const { visibleMessages, append, reload, stop, isLoading, input, setInput, initialMessagesWithContext, systemMessage } = usePipelineAssistantChat({ id, initialMessages, makeSystemMessage });
    const { pipelineAssistantApiConfig, entryPoints, getChatCompletionFunctionDescriptions, getContextString, getDocumentsContext, getFunctionCallHandler } = React.useContext(PipelineAssistantContext);
    const [promptText, setPromptText] = React.useState('');
    const textareaRef = React.useRef(null);
    const messagesEndRef = React.useRef(null)
    const [shouldRegenerate, setShouldRegenerate] = React.useState(false)
    const [regenerateContent, setRegenerateContent] = React.useState('')
    const { messages, submitPrompt, resetMessages, abortResponse, loading, setMessages, addInitialMessages } = useChatCompletion(
        {
            model: 'gpt-4-1106-preview',
            apiKey: pipelineAssistantApiConfig.apiKey,
            functions: getChatCompletionFunctionDescriptions(),
            function_call: "auto",
            temperature: 0.9,
        },
        getFunctionCallHandler()
    );

    const regenerate = (i) => {
        console.log("regenerate", i)
        // remove all messages after i and including i and then pop the last message and submit it
        const newMessages = messages.slice(0, i - 1)

        setShouldRegenerate(true)
        setRegenerateContent(messages[i - 1].content)
        setMessages(newMessages)


    }

    const onSend = (e) => {
        e.preventDefault();
        submitPrompt([{ content: promptText, role: 'user' }], initialMessagesWithContext);
        setPromptText('');
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
        textareaRef.current?.focus();
    };

    const userMessage = (message: any, key: any) => {
        return (
            <div className="flex flex-row px-2 py-4 sm:px-4" key={key}>
                <img
                    className="mr-2 flex h-8 w-8 rounded-full sm:mr-4"
                    src="https://dummyimage.com/256x256/363536/ffffff&text=U"
                />

                <div className="flex max-w-3xl items-center">
                    <p>{message}</p>
                </div>
            </div>
        );
    }

    const assistantMessage = (message: any, key: any) => {
        return (
            <>
                <div className="mb-2 flex w-full flex-row justify-end gap-x-2 text-slate-500" key={"button" + key}>
                    {/* <button className="hover:text-blue-600">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path
                                d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3"
                            ></path>
                        </svg>
                    </button> */}
                    <button className="hover:text-blue-600" type="button" onClick={() => regenerate(key)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path
                                d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747"
                            ></path>
                            <path d="M20 4v5h-5"></path>
                        </svg>
                    </button>
                    <button className="hover:text-blue-600" type="button" onClick={() => {
                        console.log("entryPoints", entryPoints)
                        console.log("getChatCompletionFunctionDescriptions", getChatCompletionFunctionDescriptions())
                        console.log("getContextString", getContextString([]))
                        console.log("getDocumentsContext", getDocumentsContext())
                        console.log("id", id)
                        console.log("initialMessages", initialMessages)
                        console.log("makeSystemMessage", makeSystemMessage)
                        console.log("visibleMessages", visibleMessages)
                        console.log("initialMessagesWithContext", initialMessagesWithContext)
                        console.log("systemMessage", systemMessage)
                        console.log("messages", messages)

                    }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path
                                d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"
                            ></path>
                            <path
                                d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"
                            ></path>
                        </svg>
                    </button>
                </div >
                <div
                    className="mb-4 flex rounded-xl bg-slate-50 px-2 py-6 dark:bg-slate-900 sm:px-4" key={key}
                >
                    <img
                        className="mr-2 flex h-8 w-8 rounded-full sm:mr-4"
                        src="https://dummyimage.com/256x256/354ea1/ffffff&text=A"
                    />

                    <div className="flex max-w-3xl items-center rounded-xl">
                        <AssistantMessage>{message}</AssistantMessage>
                    </div>
                </div>
            </>
        );
    }

    const renderMessage = (msg: any, i: number) => {
        if (msg.role === 'user') {
            return userMessage(msg.content, i)
        } else if (msg.role === 'assistant') {
            return assistantMessage(msg.content, i)
        }
    }


    // When content is added to the chat window, make sure we scroll to the bottom so the most
    // recent content is visible to the user.
    React.useEffect(() => {
        // window.scrollTo(0, document.body.scrollHeight);
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
        if (shouldRegenerate) {

            submitPrompt([{ content: regenerateContent, role: 'user' }], initialMessagesWithContext);
            setShouldRegenerate(false)
            setRegenerateContent('')
        }
    }, [messages]);

    return (
        <>
            {/* Prompt Messages Container - Modify the height according to your need */}
            <div className="flex h-full w-full flex-col">

                {/* Top Bar with Clear Messages Button */}
                <div className="flex items-center justify-between px-4 py-2 border-b border-slate-200 dark:border-slate-700">
                    {/* <h2 className="text-lg font-medium text-slate-900 dark:text-slate-300">
                        Chat
                    </h2> */}
                    <div></div>
                    <button
                        onClick={() => {
                            resetMessages();
                        }}
                        className="text-sm font-medium text-slate-900 dark:text-slate-300 hover:text-blue-500 dark:hover:text-blue-500"
                    >
                        Clear Messages
                    </button>
                </div>



                {/* Prompt Messages */}
                <div
                    className="flex-1 overflow-y-auto overflow-x-hidden rounded-xl bg-slate-200 p-4 text-sm leading-6 text-slate-900 dark:bg-slate-800 dark:text-slate-300 sm:text-base sm:leading-7"
                    style={{
                        wordBreak: 'break-word',

                    }}
                >
                    {messages.map((msg, i) => renderMessage(msg, i))}
                    <div ref={messagesEndRef} />

                </div>
                {/* Prompt message input */}

                <form className="mt-2">
                    <label htmlFor="chat-input" className="sr-only">Enter your prompt</label>
                    <div className="relative">
                        <button
                            type="button"
                            className="absolute inset-y-0 left-0 flex items-center pl-3 text-slate-500 hover:text-blue-500 dark:text-slate-400 dark:hover:text-blue-500"
                        >
                            <svg
                                aria-hidden="true"
                                className="h-5 w-5"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path
                                    d="M9 2m0 3a3 3 0 0 1 3 -3h0a3 3 0 0 1 3 3v5a3 3 0 0 1 -3 3h0a3 3 0 0 1 -3 -3z"
                                ></path>
                                <path d="M5 10a7 7 0 0 0 14 0"></path>
                                <path d="M8 21l8 0"></path>
                                <path d="M12 17l0 4"></path>
                            </svg>
                            <span className="sr-only">Use voice input</span>
                        </button>
                        <textarea
                            ref={textareaRef}
                            id="chat-input"
                            className="block w-full resize-none rounded-xl border-none bg-slate-200 p-4 pl-10 pr-20 text-sm text-slate-900 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-slate-800 dark:text-slate-200 dark:placeholder-slate-400 dark:focus:ring-blue-500 sm:text-base"
                            placeholder="Enter your prompt"
                            rows={1}
                            value={promptText}
                            onChange={(event) => {
                                setPromptText(event.target.value);
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' && !event.shiftKey) {
                                    event.preventDefault();
                                    setTimeout(() => {
                                        textareaRef.current?.focus();
                                    }, 0);
                                    if (!(messages.length > 0 && messages[messages.length - 1].meta.loading)) {
                                        onSend(event);
                                    }

                                }

                            }}

                            autoFocus
                            required
                        ></textarea>
                        {loading ? (
                            <button

                                onClick={abortResponse}
                                className="absolute bottom-2 right-2.5 rounded-lg bg-red-700 px-4 py-2 text-sm font-medium text-slate-200 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 sm:text-base"
                            >
                                Stop <span className="sr-only">Send message</span>
                            </button>
                        ) : (
                            <button
                                disabled={
                                    messages.length > 0 && messages[messages.length - 1].meta.loading
                                }
                                onClick={onSend}
                                className="absolute bottom-2 right-2.5 rounded-lg bg-blue-700 px-4 py-2 text-sm font-medium text-slate-200 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 sm:text-base"
                            >
                                Send <span className="sr-only">Send message</span>
                            </button>
                        )}

                    </div>
                </form>
            </div>
        </>
    );
};