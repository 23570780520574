import React from "react"
import { IResourceComponentsProps } from "@refinedev/core"
import { Edit, useForm } from "@refinedev/antd"
import { Form, Input } from "antd"

export const LlmConfigEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm()

  const llmConfigsData = queryResult?.data?.data

  const handleOnFinish = (values: any) => {
    const model_params = JSON.stringify(JSON.parse(values.model_params))
    const environment_variables = JSON.stringify(
      JSON.parse(values.environment_variables)
    )
    onFinish({
      ...values,
      model_params: model_params,
      environment_variables: environment_variables,
    })
  }

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
        <Form.Item
          label="Id"
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Server Type"
          name={["server_type"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Base Inference Url"
          name={["base_inference_url"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Model Prompt Format"
          name={["model_prompt_format"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Default System Prompt"
          name={["default_system_prompt"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
        <Form.Item
          label="Max Input Length"
          name={["max_input_length"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Model Params"
          name={["model_params"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
        <Form.Item
          label="ENV Variables"
          name={["environment_variables"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
        {/* <Form.Item
          label="Created At"
          name={["created_at"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Updated At"
          name={["updated_at"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item> */}
      </Form>
    </Edit>
  )
}
