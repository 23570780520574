import React from "react"
import { IResourceComponentsProps } from "@refinedev/core"
import { Edit, useForm, useSelect } from "@refinedev/antd"
import { Checkbox, Form, Input, Select } from "antd"
import _ from "lodash"

export const ProcessorEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm()

  const processorsData = queryResult?.data?.data

  console.log("processorsData: ", processorsData)

  const { selectProps: llmConfigSelectProps } = useSelect({
    resource: "llm_configs",
    defaultValue: processorsData?.llm_config,
    optionLabel: "name",
    debounce: 1000,
  })

  const { selectProps: jsonSchemaSelectProps } = useSelect({
    resource: "json_schemas",
    defaultValue: processorsData?.json_schema,
    optionLabel: "name",
  })

  const { selectProps: promptSelectProps } = useSelect({
    resource: "prompts",
    defaultValue: processorsData?.prompt,
    optionLabel: "name",
  })

  console.log("llmConfigSelectProps: ", llmConfigSelectProps)

  const handleOnFinish = (values: any) => {
    const gen_params = JSON.parse(
      values.gen_params || '{}'
    )
    const metadata = JSON.parse(
      (values.metadata == null || typeof values.metadata === "object") ? JSON.stringify(values.metadata || {}, null, 2) : values.metadata
    )
    values = {
      ...values,
      llm_config_id: values.llm_config.id,
      json_schema_id: values.json_schema.id,
      prompt_id: values.prompt.id,
      gen_params: gen_params,
      metadata: metadata,
    }
    values = _.omit(values, ["llm_config", "json_schema", "prompt"])
    onFinish({
      ...values,
      gen_params: gen_params,
    })
  }

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
        <Form.Item
          label="Id"
          name={["id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Llm Config"
          name={"llm_config"}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value: any) => {
            return {
              value: value?.id,
            }
          }}
          getValueFromEvent={(selected: any) => {
            return {
              id: selected,
            }
          }}
        >
          <Select {...llmConfigSelectProps} />
        </Form.Item>
        <Form.Item
          label="Json Schema"
          name={"json_schema"}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value: any) => {
            return {
              value: value?.id,
            }
          }}
          getValueFromEvent={(selected: any) => {
            return {
              id: selected,
            }
          }}
        >
          <Select {...jsonSchemaSelectProps} />
        </Form.Item>
        <Form.Item
          label="Prompt"
          name={"prompt"}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value: any) => {
            return {
              value: value?.id,
            }
          }}
          getValueFromEvent={(selected: any) => {
            return {
              id: selected,
            }
          }}
        >
          <Select {...promptSelectProps} />
        </Form.Item>
        <Form.Item
          label="Max Tokens Per Chunk Buffer"
          name={"max_tokens_per_chunk_buffer"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Generation Params"
          name={["gen_params"]}
          rules={[
            {
              required: false,
            },
          ]}
          getValueProps={(value) => ({
            value: (value == null || typeof value === "object") ? JSON.stringify(value || {}, null, 2) : value,
          })}
        // getValueProps={(value) => ({
        //   value: value ? JSON.stringify(value) : undefined,
        // })}
        //   getValueFromEvent={(value) => {
        //     return value ? JSON.parse(value) : {}
        //   }}
        //   getValueProps={(value) => ({
        //     value: value ? JSON.stringify(value) : undefined,
        //   })}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
        <Form.Item
          label="Is Active"
          valuePropName="checked"
          name={["is_active"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Checkbox>Is Active</Checkbox>
        </Form.Item>
        <Form.Item
          label="Metadata"
          name={["metadata"]}
          rules={[
            {
              required: false,
            },
          ]}

          getValueProps={(value) => ({
            value: (value == null || typeof value === "object") ? JSON.stringify(value || {}, null, 2) : value,
          })}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
      </Form>
    </Edit>
  )
}