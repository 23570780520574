import React, { useState } from "react"
import { IResourceComponentsProps, useList } from "@refinedev/core"
import { Create, useForm, useSelect } from "@refinedev/antd"
import { Form, Input, Checkbox } from "antd"
import MultiSelectSort from "../../components/processorSelection"

interface OptionType {
  value: string
  label: string
}

export const PipelineCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm()
  const [selectedOptions, setSelectedOptions] = useState<any[]>([])

  const { selectProps: processorsSelectProps } = useSelect({
    resource: "processors",
    optionLabel: "name",
    debounce: 1000,
  })

  const processorList = useList({
    resource: "processors",
  })

  //   const handleOnFinish = (values: any) => {
  //     console.log("handleOnFinish: ", values)
  //     console.log("selectedOptions:", selectedOptions)
  //     const processors = values.processors.map((processor: any) => {
  //       if (processor.value !== undefined) {
  //         return processorList.data?.data.find(
  //           (item: any) => item.id === processor.value
  //         )
  //       } else {
  //         return processor
  //       }
  //     })
  //     console.log("processors", processors)
  //     console.log("processorList:", processorList)
  //     onFinish({
  //       ...values,
  //       processors: processors,
  //       //   processors: selectedOptions.map((option: any) => option.value),
  //       //   processors: values.processors.map((processor: any) => processor.value),
  //     })
  //   }

  const handleOnFinish = (values: any) => {
    // console.log("selectedOptions:", selectedOptions)
    // console.log("handleOnFinish: ", values)
    // console.log("selectedOptions:", selectedOptions)
    // const processors = values.processors.map((processor: any) => {
    //   console.log("processor: ", processor)
    //   if (processor.value !== undefined) {
    //     const matches = processorList.data?.data.find(
    //       (item: any) => item.id === processor.value
    //     )
    //     if (matches !== undefined) {
    //       return matches.map((item: any) => {
    //         return { id: item.id }
    //       })
    //     }
    //     return []
    //   } else {
    //     return { id: processor.id }
    //   }
    // })

    const processors = selectedOptions.map((processor: any) => {
      return { id: processor.value }
    })

    console.log("processors", processors)
    console.log("processorList:", processorList)
    onFinish({
      ...values,
      processors: processors,
      is_active: values.is_active === undefined ? true : values.is_active,
      //   processors: selectedOptions.map((option: any) => option.value),
      //   processors: values.processors.map((processor: any) => processor.value),
    })
  }

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Processors"
          name={"processors"}
          rules={[
            {
              required: false,
            },
          ]}
          getValueProps={(value: any[]) => {
            return {
              value: value?.map((item) => item?.id),
            }
          }}
          getValueFromEvent={(selected: string[]) => {
            return selected?.map((item) => ({ id: item }))
          }}
        >
          {processorsSelectProps.loading ? (
            <p>Loading...</p>
          ) : (
            <MultiSelectSort
              key={"processorsMSCreate"}
              {...processorsSelectProps}
              options={processorsSelectProps.options as OptionType[]}
              values={[]}
              // values={selectedOptions}
              onChange={setSelectedOptions}
              getValueProps={(value: any[]) => {
                return {
                  value: value?.map((item) => item?.id),
                }
              }}
            />
          )}
          {/* <MultiSelectSort
            options={
              processorsSelectProps.options !== undefined
                ? (processorsSelectProps.options as OptionType[])
                : []
            }
            values={[]}
            onChange={setSelectedOptions}
          /> */}
          {/* <Select mode="multiple" {...processorsSelectProps} /> */}
        </Form.Item>
        {/* <Form.Item
          label="Created At"
          name={["createdAt"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Updated At"
          name={["updatedAt"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item> */}
        <Form.Item
          label="Is Active"
          valuePropName="checked"
          name={["is_active"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Checkbox defaultChecked checked>
            Is Active
          </Checkbox>
        </Form.Item>
      </Form>
    </Create>
  )
}
