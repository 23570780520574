import React from "react"
import { IResourceComponentsProps } from "@refinedev/core"
import { Create, useForm } from "@refinedev/antd"
import { Form, Input } from "antd"

export const JsonSchemaCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm()

  const handleOnFinish = (values: any) => {
    const content = JSON.parse(values.content)
    onFinish({
      ...values,
      content: content,
      // processors: selectedOptions.map((option: any) => option.value),
      // processors: values.processors.map((processor: any) => processor.value),
    })
  }

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Content"
          name={["content"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
        {/* <Form.Item
          label="Created At"
          name={["created_at"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Updated At"
          name={["updated_at"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item> */}
      </Form>
    </Create>
  )
}
