import MDEditor, { commands } from "@uiw/react-md-editor"
import { Row, Col, Typography } from "antd"
import { Courier, CourierInfoText } from "pages/pipelines/styled"
import React from "react"
import { inferJSONSchema } from "./helpers"

const { Text } = Typography

interface IProcessorEditorsProps {
  recordKey: string
  defaultSystemPrompt: string
  jsonSchema: string
  prompt: string
  genParams: string
  modelParams: string
  envVariables: string
  onDefaultSystemPromptChange: (value: string, recordKey: string) => void
  onJsonSchemaChange: (value: string, recordKey: string) => void
  onPromptChange: (value: string, recordKey: string) => void
  onGenParamsChange: (value: string, recordKey: string) => void
  onModelParamsChange: (value: string, recordKey: string) => void
  onEnvVariablesChange: (value: string, recordKey: string) => void
}

const ProcessorEditors = (props: IProcessorEditorsProps) => {
  const {
    recordKey,
    defaultSystemPrompt,
    jsonSchema,
    prompt,
    genParams,
    modelParams,
    envVariables,
    onDefaultSystemPromptChange,
    onJsonSchemaChange,
    onPromptChange,
    onGenParamsChange,
    onModelParamsChange,
    onEnvVariablesChange,
  } = props
  const [defaultSystemPromptText, setDefaultSystemProptText] =
    React.useState<string>(defaultSystemPrompt ?? "")
  const [jsonSchemaText, setJsonSchemaText] = React.useState<string>(
    jsonSchema ?? ""
  )
  const [promptText, setPromptText] = React.useState<string>(prompt ?? "")

  const [genParamsText, setGenParamsText] = React.useState<string>(
    genParams ?? ""
  )

  const [modelParamsText, setModelParamsText] = React.useState<string>(
    modelParams ?? ""
  )

  const [envVariablesText, setEnvVariablesText] = React.useState<string>(
    envVariables ?? ""
  )

  const handleDefaultSystemPromptChange = React.useCallback(
    (value: string | undefined) => {
      setDefaultSystemProptText(value ?? "")
      onDefaultSystemPromptChange(value ?? "", recordKey)
    },
    [setDefaultSystemProptText]
  )

  const handleJsonSchemaChange = React.useCallback(
    (value: string | undefined) => {
      setJsonSchemaText(value ?? "")
      onJsonSchemaChange(value ?? "", recordKey)
    },
    [setJsonSchemaText]
  )

  const handlePromptChange = React.useCallback(
    (value: string | undefined) => {
      setPromptText(value ?? "")
      onPromptChange(value ?? "", recordKey)
    },
    [setPromptText]
  )

  const handleGenParamsChange = React.useCallback(
    (value: string | undefined) => {
      setGenParamsText(value ?? "")
      onGenParamsChange(value ?? "", recordKey)
    },
    [setGenParamsText]
  )

  const handleModelParamsChange = React.useCallback(
    (value: string | undefined) => {
      setModelParamsText(value ?? "")
      onModelParamsChange(value ?? "", recordKey)
    },
    [setModelParamsText]
  )

  const handleEnvVariablesChange = React.useCallback(
    (value: string | undefined) => {
      setEnvVariablesText(value ?? "")
      onEnvVariablesChange(value ?? "", recordKey)
    },
    [setEnvVariablesText]
  )

  return (
    <Row
      key={"processor_editors"}
      justify="center"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Col
        md={12}
        style={{
          textAlign: "center",
          width: "100%",
          backgroundColor: "lightgray",
        }}
      >
        <Courier
          style={{
            flex: 1,
            textAlign: "center",
            width: "100%",
          }}
        >
          <CourierInfoText
            style={{
              flex: 1,
              textAlign: "center",
              width: "100%",
            }}
          >
            <Text style={{ fontSize: 16, textAlign: "center" }}>
              Default System Prompt
            </Text>
            <MDEditor
              value={defaultSystemPromptText}
              onChange={handleDefaultSystemPromptChange}
              height={500}
              preview="edit"
              commands={[...commands.getCommands(), inferJSONSchema]}
            />
          </CourierInfoText>
        </Courier>
      </Col>
      <Col
        md={12}
        style={{
          textAlign: "center",
          width: "100%",
          backgroundColor: "lightgray",
        }}
      >
        <Courier
          style={{
            flex: 1,
            textAlign: "center",
            width: "100%",
          }}
        >
          <CourierInfoText
            style={{
              flex: 1,
              textAlign: "center",
              width: "100%",
            }}
          >
            <Text style={{ fontSize: 16, textAlign: "center" }}>
              JSON Schema
            </Text>
            <MDEditor
              value={jsonSchemaText}
              onChange={handleJsonSchemaChange}
              height={500}
              preview="edit"
              commands={[...commands.getCommands(), inferJSONSchema]}
            />
          </CourierInfoText>
        </Courier>
      </Col>
      <Col
        md={12}
        style={{
          textAlign: "center",
          width: "100%",
          backgroundColor: "lightgray",
        }}
      >
        <Courier
          style={{
            flex: 1,
            textAlign: "center",
            width: "100%",
          }}
        >
          <CourierInfoText
            style={{
              flex: 1,
              textAlign: "center",
              width: "100%",
            }}
          >
            <Text style={{ fontSize: 16, textAlign: "center" }}>
              Generation Params
            </Text>
            <MDEditor
              value={genParamsText}
              onChange={handleGenParamsChange}
              height={500}
              preview="edit"
              //   commands={[...commands.getCommands(), inferJSONSchema]}
            />
          </CourierInfoText>
        </Courier>
      </Col>
      <Col
        md={12}
        style={{
          textAlign: "center",
          width: "100%",
          backgroundColor: "lightgray",
        }}
      >
        <Courier
          style={{
            flex: 1,
            textAlign: "center",
            width: "100%",
          }}
        >
          <CourierInfoText
            style={{
              flex: 1,
              textAlign: "center",
              width: "100%",
            }}
          >
            <Text style={{ fontSize: 16, textAlign: "center" }}>Prompt</Text>
            <MDEditor
              value={promptText}
              onChange={handlePromptChange}
              height={500}
              preview="edit"
              commands={[...commands.getCommands(), inferJSONSchema]}
            />
          </CourierInfoText>
        </Courier>
      </Col>
      <Col
        md={12}
        style={{
          textAlign: "center",
          width: "100%",
          backgroundColor: "lightgray",
        }}
      >
        <Courier
          style={{
            flex: 1,
            textAlign: "center",
            width: "100%",
          }}
        >
          <CourierInfoText
            style={{
              flex: 1,
              textAlign: "center",
              width: "100%",
            }}
          >
            <Text style={{ fontSize: 16, textAlign: "center" }}>
              Model Params
            </Text>
            <MDEditor
              value={modelParamsText}
              onChange={handleModelParamsChange}
              height={500}
              preview="edit"
              //   commands={[...commands.getCommands(), inferJSONSchema]}
            />
          </CourierInfoText>
        </Courier>
      </Col>
      <Col
        md={12}
        style={{
          textAlign: "center",
          width: "100%",
          backgroundColor: "lightgray",
        }}
      >
        <Courier
          style={{
            flex: 1,
            textAlign: "center",
            width: "100%",
          }}
        >
          <CourierInfoText
            style={{
              flex: 1,
              textAlign: "center",
              width: "100%",
            }}
          >
            <Text style={{ fontSize: 16, textAlign: "center" }}>
              ENV Variables
            </Text>
            <MDEditor
              value={envVariablesText}
              onChange={handleEnvVariablesChange}
              height={500}
              preview="edit"
              //   commands={[...commands.getCommands(), inferJSONSchema]}
            />
          </CourierInfoText>
        </Courier>
      </Col>
    </Row>
  )
}

export default React.memo(ProcessorEditors)
