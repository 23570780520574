import { inferSchema } from "@jsonhero/schema-infer"
import { jsonrepair } from "jsonrepair"
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { uuidv4 } from "@antv/xflow-core"

var JSON5 = require("json5")

export const randomUniqueName = () => {
  var uuid = uuidv4()
  // UUID minus dashes
  return `Schema${uuid.replace(/-/g, "")}`
}

export const DraggableWrapper = (props: {
  [x: string]: any
  children: any
}) => {
  const { children, ...restProps } = props
  return (
    <SortableContext
      items={
        children[1] instanceof Array
          ? children[1].map((child) => child.key)
          : []
      }
      strategy={verticalListSortingStrategy}
      {...restProps}
    >
      <tbody {...restProps}>{children}</tbody>
    </SortableContext>
  )
}

export const DraggableRow = (props: { [x: string]: any; children?: any }) => {
  const { attributes, listeners, setNodeRef, isDragging, overIndex, index } =
    useSortable({
      id: props["data-row-key"],
    })
  const isOver = overIndex === index
  const { children, ...restProps } = props
  const isData = children instanceof Array
  const style = {
    ...restProps?.style,
    ...(isData && isDragging ? { background: "#80808038" } : {}),
    ...(isData && isOver ? { borderTop: "5px solid #ec161638" } : {}),
  }

  return (
    <tr ref={setNodeRef} {...attributes} {...restProps} style={style}>
      {children instanceof Array
        ? children.map((child) => {
            const { children, key, ...restProps } = child
            return key === "dragHandle" ? (
              <td {...listeners} {...restProps}>
                {child}
              </td>
            ) : (
              <td {...restProps}>{child}</td>
            )
          })
        : children}
    </tr>
  )
}

export interface IProcessorRow {
  id: string
  key: string
  name: string
  llm_config: {
    server_type: string
    base_inference_url: string
    model_prompt_format: string
    default_system_prompt: string
    max_input_length: number
    model_params: string
    environment_variables: string
  } | null
  json_schema: {
    name: string
    content: string
  } | null
  prompt: {
    text: string
  } | null
  max_tokens_per_chunk_buffer: number
  gen_params: string
}

export const inferJSONSchema = {
  name: "infer JSON Schema",
  keyCommand: "inferJSONSchema",
  buttonProps: { "aria-label": "Infer JSON Schema" },
  icon: (
    <svg
      viewBox="0 0 16 16"
      width="12px"
      height="12px"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <!-- Mask Definition --> */}
      <defs>
        <mask id="cutout-mask">
          <rect x="0" y="0" width="16" height="16" fill="white" />
          <path
            d="M5 6 Q5 4 7 4 Q9 4 9 6 Q9 8 7 8 Q5 8 5 6 M11 10 Q11 12 9 12 Q7 12 7 10 Q7 8 9 8 Q11 8 11 10"
            fill="black"
          />
          <path d="M7 7 L9 9" stroke="black" stroke-width="1.5" />
          <circle cx="8" cy="12" r="1.5" fill="black" />
        </mask>
      </defs>

      {/* <!-- Background Circle with Mask Applied --> */}
      <circle
        cx="8"
        cy="8"
        r="8"
        fill="currentColor"
        mask="url(#cutout-mask)"
      />
    </svg>
  ),
  execute: (state: any, api: any) => {
    const currentText = state.text
    const currentCleanedText = currentText.trim()
    const parsedText = JSON5.parse(jsonrepair(currentCleanedText))
    const schema = inferSchema(parsedText).toJSONSchema()
    const jsonSchema = {
      title: randomUniqueName(),
      ...(schema as any),
    }
    const schemaText = JSON5.stringify(jsonSchema, null, 4)
    const newSelection = currentText + "\n\n" + schemaText
    api.replaceSelection(newSelection)
  },
}

export const defaultSchemaContent = {
  type: "object",
  title: "Schema",
  required: ["result"],
  properties: {
    result: {
      type: "string",
      title: "Result",
      description: "result",
    },
  },
}

export const defaultPromptText = `Please summarize the following content:\n\n{text}`

export const defaultGenParams = {}

export const defaultModelParams = {}

export const defaultEnvVariables = {}
