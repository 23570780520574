"use client";

import { useContext, useEffect, useRef } from "react";
import { PipelineAssistantContext } from "../context/pipeline-assistant-context";

/**
 * Adds the given information to the PipelineAssistant context to make it readable by PipelineAssistant.
 * @param information - The information to be added to the PipelineAssistant context.
 * @param parentId - The ID of the parent context, if any.
 * @param categories - An array of categories to control which context are visible where. Particularly useful with PipelineAssistantTextarea (see `useMakeAutosuggestionFunction`)
 * @returns The ID of the added context.
 */
export function useMakePipelineAssistantReadable(
    information: string,
    parentId?: string,
    categories?: string[]
): string | undefined {
    const { addContext, removeContext } = useContext(PipelineAssistantContext);
    const idRef = useRef<string>();

    useEffect(() => {
        const id = addContext(information, parentId, categories);
        idRef.current = id;

        return () => {
            removeContext(id);
        };
    }, [information, parentId, addContext, removeContext]);

    return idRef.current;
}