import { AuthProps } from "@refinedev/antd"
import { Link } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { Row, Col, Layout, Card, Typography, Button, Space } from "antd"
import { CSSProperties } from "react"

const layoutStyles: CSSProperties = {}

const authWrapperProps = {
  style: {
    background:
      "radial-gradient(50% 50% at 50% 50%,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, 0.5) 100%),url('images/login-bg.png')",
    backgroundSize: "cover",
  },
}

const renderAuthContent = (content: React.ReactNode) => {
  return (
    <div
      style={{
        maxWidth: 408,
        margin: "auto",
      }}
    >
      <Link to="/">
        <img
          style={{ marginBottom: 26 }}
          src="/images/login.svg"
          alt="Logo"
          width="100%"
        />
      </Link>
      {content}
    </div>
  )
}

export const Login: React.FC<AuthProps> = ({ type, formProps }) => {
  const { loginWithRedirect } = useAuth0()
  const PageTitle = (
    <Typography.Title
      level={3}
      style={{
        textAlign: "center",
        marginBottom: 0,
      }}
    >
      Login
    </Typography.Title>
  )
  const CardContent = (
    <Card
      style={{
        borderRadius: 12,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Space
        direction="vertical"
        align="center"
        size="large"
        style={{
          width: "100%",
        }}
      >
        <Button
          type="primary"
          size="middle"
          onClick={() => loginWithRedirect()}
          style={{ width: "240px" }}
        >
          Sign In / Register
        </Button>
      </Space>
    </Card>
  )

  return (
    <Layout {...(authWrapperProps ?? {})}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          {renderAuthContent ? (
            renderAuthContent(CardContent)
          ) : (
            <>
              {PageTitle}
              {CardContent}
            </>
          )}
        </Col>
      </Row>
    </Layout>
  )
}
