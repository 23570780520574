import React from "react"
import { IResourceComponentsProps } from "@refinedev/core"
import { Create, useForm } from "@refinedev/antd"
import { Form, Input } from "antd"

export const LlmConfigCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm()

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Server Type"
          name={["server_type"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Base Inference Url"
          name={["base_inference_url"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Model Prompt Format"
          name={["model_prompt_format"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Default System Prompt"
          name={["default_system_prompt"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
        <Form.Item
          label="Max Input Length"
          name={["max_input_length"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Model Params"
          name={["model_params"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
        <Form.Item
          label="ENV Variables"
          name={["enviroment_variables"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
        {/* <Form.Item
          label="Created At"
          name={["created_at"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Updated At"
          name={["updated_at"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item> */}
      </Form>
    </Create>
  )
}
