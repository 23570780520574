
export const TOKEN_KEY = "refine-auth"
export const API_URL = "https://backend.zpipeliner.com/backend/v1"
// export const API_URL = "http://127.0.0.1:8000/backend/v1"
export const WEBSOCKET_URL =
  "wss://qbqljzxguj.execute-api.us-east-1.amazonaws.com/prod"
export const HANDLE_FILE_URL = "/get_file_text"
export const GENERATE_URL = "/generate"
export const JOB_STATUS_URL = "/job_status"
export const PIPELINE_OUTPUT_URL = "/get_pipeline_output"
export const UPDATE_OR_CREATE_PIPELINE_URL = "/update_or_create_pipeline"
export const UPDATE_OR_CREATE_PROCESSOR_URL = "/update_or_create_processor"
export const CREATE_MODEL_SNAPSHOT_URL = "/newsroom/zpipeliner/create_model_snapshot"