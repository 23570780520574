import { ReactNode, useEffect, useState } from "react"
import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
  useUpdate,
  useApiUrl,
  useDataProvider,
} from "@refinedev/core"
import { List, Title } from "@refinedev/antd"
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons"
import {
  Row,
  Col,
  Button,
  Steps,
  Grid,
  Space,
  Avatar,
  Typography,
  Card,
  Table,
  Skeleton,
} from "antd"
import dayjs from "dayjs"
import MDEditor, { commands } from "@uiw/react-md-editor"
import { inferSchema } from "@jsonhero/schema-infer"
import { RunPipelineIcon } from "../../components/icons"
import { usePipelineCustomKbarActions } from "../../hooks"
import {
  Courier,
  CourierBoxContainer,
  CourierInfoBox,
  CourierInfoBoxText,
  CourierInfoText,
  PageHeader,
  Product,
  ProductFooter,
  ProductText,
} from "./styled"
import React from "react"
import axios from "axios"
import ImageUploader from "components/fileUploader"
import { jsonrepair } from "jsonrepair"
import useWebSocket from "react-use-websocket"
import { WEBSOCKET_URL } from "../../constants"
import { EventsTimeline } from "components/eventsTimeline"

var JSON5 = require("json5")
const dJSON = require("dirty-json")

const { useBreakpoint } = Grid
const { Text } = Typography
// const { Title } = Typography

interface IEvent {
  date: string
  status: string
}

interface IProcessor {
  id: number
  name: string
  llm_config: {
    id: number
    name: string
    server_type: string
    base_inference_url: string
    model_prompt_format: string
    default_system_prompt: string
    max_input_length: number
  } | null
  json_schema: {
    id: number
    name: string
  } | null
  prompt: {
    id: number
    name: string
  } | null
  max_tokens_per_chunk_buffer: number
  created_at: string
  updated_at: string
  images: {
    id: number
    url: string
  }[]
}

const inferJSONSchema = {
  name: "infer JSON Schema",
  keyCommand: "inferJSONSchema",
  buttonProps: { "aria-label": "Infer JSON Schema" },
  icon: (
    <svg
      viewBox="0 0 16 16"
      width="12px"
      height="12px"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <!-- Mask Definition --> */}
      <defs>
        <mask id="cutout-mask">
          <rect x="0" y="0" width="16" height="16" fill="white" />
          <path
            d="M5 6 Q5 4 7 4 Q9 4 9 6 Q9 8 7 8 Q5 8 5 6 M11 10 Q11 12 9 12 Q7 12 7 10 Q7 8 9 8 Q11 8 11 10"
            fill="black"
          />
          <path d="M7 7 L9 9" stroke="black" stroke-width="1.5" />
          <circle cx="8" cy="12" r="1.5" fill="black" />
        </mask>
      </defs>

      {/* <!-- Background Circle with Mask Applied --> */}
      <circle
        cx="8"
        cy="8"
        r="8"
        fill="currentColor"
        mask="url(#cutout-mask)"
      />
    </svg>
  ),
  execute: (state: any, api: any) => {
    const currentText = state.text
    const currentCleanedText = currentText.trim()
    const parsedText = JSON5.parse(jsonrepair(currentCleanedText))
    const schema = inferSchema(parsedText).toJSONSchema()
    const schemaText = JSON5.stringify(schema, null, 4)
    const newSelection = currentText + "\n\n" + schemaText
    api.replaceSelection(newSelection)
  },
}

const ProcessorEditors = (props: any) => {
  const [defaultSystemPromptText, setDefaultSystemProptText] =
    React.useState<string>(
      props.processor?.llm_config?.default_system_prompt ?? ""
    )
  const [jsonSchemaText, setJsonSchemaText] = React.useState<string>(
    JSON.stringify(props.processor?.json_schema?.content, null, 4) ?? ""
  )
  const [promptText, setPromptText] = React.useState<string>(
    props.processor?.prompt?.text ?? ""
  )

  const [genText, setGenText] = React.useState<string>(
    JSON.stringify(props.processor?.gen_params, null, 4) ?? ""
  )

  const [modelParamsText, setModelParamsText] = React.useState<string>(
    JSON.stringify(props.processor?.llm_config?.model_params, null, 4) ?? ""
  )

  const [envVarsText, setEnvVarsText] = React.useState<string>(
    JSON.stringify(
      props.processor?.llm_config?.environment_variables,
      null,
      4
    ) ?? ""
  )

  return (
    <>
      <Row
        justify="center"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Col
          md={12}
          style={{
            // flex: 1,
            textAlign: "center",
            width: "100%",
            backgroundColor: "lightgray",
          }}
        >
          <Courier
            style={{
              flex: 1,
              textAlign: "center",
              width: "100%",
            }}
          >
            <CourierInfoText
              style={{
                flex: 1,
                textAlign: "center",
                width: "100%",
              }}
            >
              <Text style={{ fontSize: 16, textAlign: "center" }}>
                Default System Prompt
              </Text>
              <MDEditor
                value={defaultSystemPromptText}
                onChange={(value, event) =>
                  setDefaultSystemProptText(value ?? "")
                }
                height={500}
                preview="edit"
              />
              {/* <Text>ID #{record?.id}</Text> */}
            </CourierInfoText>
          </Courier>
        </Col>
        <Col
          md={12}
          style={{
            // flex: 1,
            textAlign: "center",
            width: "100%",
            backgroundColor: "lightgray",
          }}
        >
          <Courier
            style={{
              flex: 1,
              textAlign: "center",
              width: "100%",
            }}
          >
            <CourierInfoText
              style={{
                flex: 1,
                textAlign: "center",
                width: "100%",
              }}
            >
              <Text style={{ fontSize: 16, textAlign: "center" }}>
                Generation Params
              </Text>
              <MDEditor
                value={genText}
                onChange={(value, event) => setGenText(value ?? "")}
                height={500}
                preview="edit"
              />
              {/* <Text>ID #{record?.id}</Text> */}
            </CourierInfoText>
          </Courier>
        </Col>
        <Col
          md={12}
          style={{
            // flex: 1,
            textAlign: "center",
            width: "100%",
            backgroundColor: "lightgray",
          }}
        >
          <Courier
            style={{
              flex: 1,
              textAlign: "center",
              width: "100%",
            }}
          >
            <CourierInfoText
              style={{
                flex: 1,
                textAlign: "center",
                width: "100%",
              }}
            >
              <Text style={{ fontSize: 16, textAlign: "center" }}>
                JSON Schema
              </Text>
              <MDEditor
                value={jsonSchemaText}
                onChange={(value, event) => setJsonSchemaText(value ?? "")}
                height={500}
                preview="edit"
              />
              {/* <Text>ID #{record?.id}</Text> */}
            </CourierInfoText>
          </Courier>
        </Col>
        <Col
          md={12}
          style={{
            // flex: 1,
            textAlign: "center",
            width: "100%",
            backgroundColor: "lightgray",
          }}
        >
          <Courier
            style={{
              flex: 1,
              textAlign: "center",
              width: "100%",
            }}
          >
            <CourierInfoText
              style={{
                flex: 1,
                textAlign: "center",
                width: "100%",
              }}
            >
              <Text style={{ fontSize: 16, textAlign: "center" }}>Prompt</Text>
              <MDEditor
                value={promptText}
                onChange={(value, event) => setPromptText(value ?? "")}
                height={500}
                preview="edit"
              />
              {/* <Text>ID #{record?.id}</Text> */}
            </CourierInfoText>
          </Courier>
        </Col>
        <Col
          md={12}
          style={{
            // flex: 1,
            textAlign: "center",
            width: "100%",
            backgroundColor: "lightgray",
          }}
        >
          <Courier
            style={{
              flex: 1,
              textAlign: "center",
              width: "100%",
            }}
          >
            <CourierInfoText
              style={{
                flex: 1,
                textAlign: "center",
                width: "100%",
              }}
            >
              <Text style={{ fontSize: 16, textAlign: "center" }}>
                Model Params
              </Text>
              <MDEditor
                value={modelParamsText}
                onChange={(value, event) => setModelParamsText(value ?? "")}
                height={500}
                preview="edit"
              />
              {/* <Text>ID #{record?.id}</Text> */}
            </CourierInfoText>
          </Courier>
        </Col>
        <Col
          md={12}
          style={{
            // flex: 1,
            textAlign: "center",
            width: "100%",
            backgroundColor: "lightgray",
          }}
        >
          <Courier
            style={{
              flex: 1,
              textAlign: "center",
              width: "100%",
            }}
          >
            <CourierInfoText
              style={{
                flex: 1,
                textAlign: "center",
                width: "100%",
              }}
            >
              <Text style={{ fontSize: 16, textAlign: "center" }}>
                ENV Variables
              </Text>
              <MDEditor
                value={envVarsText}
                onChange={(value, event) => setEnvVarsText(value ?? "")}
                height={500}
                preview="edit"
              />
              {/* <Text>ID #{record?.id}</Text> */}
            </CourierInfoText>
          </Courier>
        </Col>
      </Row>
      {/* <Row
        justify="center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        
      </Row>
      <Row
        justify="center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        
      </Row> */}
    </>
  )
}

interface ImageUploaderProps {
  onUploadSuccess?: (url: string) => void
}

interface RcCustomRequestOptions {
  onProgress?: (progressEvent: { percent: number }) => void
  onSuccess?: (response: any, file: File) => void
  onError?: (error: any, response?: any) => void
  file: File
  filename?: string
  withCredentials?: boolean
  action: string
  headers?: Record<string, string>
  method?: string
  data?: object
}

export const PipelineShow: React.FC<IResourceComponentsProps> = () => {
  const [editorText, setEditorText] = React.useState<string>("")
  const [outputText, setOutputText] = React.useState<string>("")
  const [defaultSystemPromptText, setDefaultSystemProptText] =
    React.useState<string>("")
  const [jsonSchemaText, setJsonSchemaText] = React.useState<string>("")
  const [promptText, setPromptText] = React.useState<string>("")
  const t = useTranslate()
  const screens = useBreakpoint()
  const { queryResult } = useShow()
  const { data } = queryResult
  const { mutate } = useUpdate()
  const record = data?.data
  const apiUrl = useApiUrl()
  const httpClient = axios.create()

  const [defaultFileList, setDefaultFileList] = useState([])
  const [isGenerating, setIsGenerating] = useState(false)
  const [progress, setProgress] = useState(0)
  const [messageHistory, setMessageHistory] = useState([])
  const [events, setEvents] = useState<any[]>([])
  const [requestId, setRequestId] = useState<string>("")
  const myDataProvider = useDataProvider()("default") as any

  //   const myDataProvider = dataProvider(axiosInstance)

  const onOutputChange = React.useCallback(
    (value: string | undefined) => {
      setOutputText(value ?? "")
      setEvents((events) => [...events, JSON.parse(value ?? "")])
      setIsGenerating(false)
    },
    [setOutputText]
  )

  const handleResult = React.useCallback((result: any) => {
    console.log("handleResult: ", result)
    setRequestId(result.data.request_id)
    setEvents([])
  }, [])

  const getFileTextFromUrl = async (url: string) => {
    const result = await myDataProvider.getFileText({
      resource: "pipelines",
      variables: {
        file_url: url,
      },
    })
    console.log("result:", result)

    setEditorText(result.data.file_text)
  }

  const handleUploadSuccess = async (url: string) => {
    console.log("handleUploadSuccess")
    console.log(url)
    await getFileTextFromUrl(url)
  }

  const fakeStatus = {
    id: 5,
    text: "Cancelled",
  }

  const fakeEvents = [
    {
      date: "2023-07-24T03:44:15.376Z",
      status: "Pending",
    },
    {
      date: "2023-07-24T03:52:15.376Z",
      status: "Ready",
    },
    {
      date: "2023-07-24T03:55:15.376Z",
      status: "On The Way",
    },
    {
      date: "2023-07-24T03:58:15.376Z",
      status: "Cancelled",
    },
  ]

  const canAcceptPipeline = fakeStatus.text === "Pending"
  const canRejectPipeline =
    fakeStatus.text === "Pending" ||
    fakeStatus.text === "Ready" ||
    fakeStatus.text === "On The Way"
  //   const canAcceptPipeline = true
  //   const canRejectPipeline = true

  const randomColor = () => {
    const hex = "0123456789ABCDEF"
    let color = "#"
    for (let i = 0; i < 6; i++) {
      color += hex[Math.floor(Math.random() * 16)]
    }
    return color
  }

  const currentBreakPoints = Object.entries(screens)
    .filter((screen) => !!screen[1])
    .map((screen) => screen[0])

  const RenderPipelineSteps = () => {
    const notFinishedCurrentStep = (event: IEvent, index: number) =>
      event.status !== "Cancelled" &&
      event.status !== "Delivered" &&
      fakeEvents.findIndex((el: any) => el.status === fakeStatus?.text) ===
        index

    const stepStatus = (event: IEvent, index: number) => {
      if (!event.date) return "wait"
      if (event.status === "Cancelled") return "error"
      if (notFinishedCurrentStep(event, index)) return "process"
      return "finish"
    }

    const handleMutate = (status: { id: number; text: string }) => {
      if (record) {
        mutate({
          resource: "pipelines",
          id: record?.id ? record?.id.toString() : "",
          values: {
            status,
          },
        })
      }
    }

    // @ts-ignore We're failing to provide a required index prop to SortableElement
    usePipelineCustomKbarActions(record)

    return (
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={t("ID")}
        subTitle={`#${record?.id ?? ""}`}
        extra={[
          <Button
            disabled={!canAcceptPipeline}
            key="accept"
            icon={<CheckCircleOutlined />}
            type="primary"
            onClick={() =>
              handleMutate({
                id: 2,
                text: "Ready",
              })
            }
          >
            {t("Accept")}
          </Button>,
          <Button
            disabled={!canRejectPipeline}
            key="reject"
            danger
            icon={<CloseCircleOutlined />}
            onClick={() =>
              handleMutate({
                id: 5,
                text: "Cancelled",
              })
            }
          >
            {t("Reject")}
          </Button>,
        ]}
      >
        <Steps
          direction={
            currentBreakPoints.includes("lg") ? "horizontal" : "vertical"
          }
          current={(fakeEvents ?? []).findIndex(
            (el: any) => el.status === fakeStatus?.text
          )}
        >
          {(fakeEvents ?? []).map((event: IEvent, index: number) => (
            <Steps.Step
              status={stepStatus(event, index)}
              key={index}
              title={t(`${event.status}`)}
              icon={notFinishedCurrentStep(event, index) && <LoadingOutlined />}
              description={event.date && dayjs(event.date).format("L LT")}
              //   description={event.date && dayjs(event.date).format("L LT")}
            />
          ))}
        </Steps>
        {!record && <Skeleton paragraph={{ rows: 1 }} />}
      </PageHeader>
    )
  }

  const courierInfoBox = (
    text: string,
    icon: ReactNode,
    value?: string,
    extraBoxStyle?: any,
    extraTextStyle?: any
  ) => (
    <CourierInfoBox style={extraBoxStyle ?? {}}>
      {icon}
      <CourierInfoBoxText>
        <Text style={{ color: "#ffffff", ...extraTextStyle }}>
          {text.toUpperCase()}
        </Text>
        <Text style={{ color: "#ffffff" }}>{value}</Text>
      </CourierInfoBoxText>
    </CourierInfoBox>
  )

  //   const RunPipeline = () => {
  //     const apiUrl = useApiUrl()
  //     const { data: runPipelineData, isLoading } = useCustom<any>({
  //       url: `${apiUrl}/pipelines/${record?.id}/run`,
  //       method: "post",
  //       config: {
  //         payload: {
  //           text: editorText,
  //         },
  //       },
  //     })
  //   }

  const renderCourierInfo = () => (
    <Card>
      <Row justify="center">
        <Col xl={12} lg={10}>
          <Courier>
            <Avatar
              size={58}
              src={""}
              style={{
                backgroundColor: randomColor(),
              }}
            />
            <CourierInfoText>
              <Text style={{ fontSize: 16 }}>Pipeline</Text>
              <Text
                style={{
                  fontSize: 22,
                  fontWeight: 800,
                }}
              >
                {record?.name}
              </Text>
              <Text>ID #{record?.id}</Text>
            </CourierInfoText>
          </Courier>
        </Col>

        <CourierBoxContainer xl={12} lg={14} md={14}>
          <ImageUploader onUploadSuccess={handleUploadSuccess} />

          <Button
            type="primary"
            disabled={isGenerating}
            style={{
              borderRadius: 10,
              borderWidth: 1,
              borderColor: "black",
              borderStyle: "solid",
              backgroundColor: isGenerating ? "darkred" : "darkgreen",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              paddingRight: 35,
              height: 60,
              width: 175,
              textAlign: "center",
            }}
            onClick={async () => {
              setIsGenerating(true)
              //   const dataProvider = useDataProvider()
              //   const defaultDataProvider = dataProvider();
              const result = await myDataProvider.generate({
                resource: "pipelines",
                variables: {
                  pipeline: record,
                  input_text: editorText,
                },
              })
              console.log("result:", result)

              handleResult(result)
            }}
          >
            {isGenerating
              ? courierInfoBox(
                  t("Running..."),
                  <RunPipelineIcon
                    color="white"
                    style={{ color: "#ffff", fontSize: 32 }}
                  />,
                  undefined,
                  { backgroundColor: "red" },
                  undefined
                  // "15:05"
                )
              : courierInfoBox(
                  t("Run Pipeline"),
                  <RunPipelineIcon
                    color="white"
                    style={{ color: "#ffff", fontSize: 32 }}
                  />,
                  undefined,
                  { backgroundColor: "#67be23" },
                  undefined
                  // "15:05"
                )}
          </Button>
        </CourierBoxContainer>
      </Row>
    </Card>
  )

  const renderExpandedProcessor = (record: IProcessor) => (
    <Card>
      <Row
        justify="center"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Col style={{ flex: 1 }}>
          <Courier style={{ width: "100%", textAlign: "center" }}>
            <CourierInfoText style={{ width: "100%", textAlign: "center" }}>
              <Text style={{ fontSize: 16 }}>Server Type</Text>
              <Text
                style={{
                  fontSize: 22,
                  fontWeight: 800,
                }}
              >
                {record?.llm_config?.server_type}
              </Text>
              {/* <Text>ID #{record?.id}</Text> */}
            </CourierInfoText>
          </Courier>
        </Col>
        <Col style={{ flex: 1 }}>
          <Courier style={{ width: "100%", textAlign: "center" }}>
            <CourierInfoText style={{ width: "100%", textAlign: "center" }}>
              <Text style={{ fontSize: 16 }}>Base Inference URL</Text>
              <Text
                style={{
                  fontSize: 22,
                  fontWeight: 800,
                }}
              >
                {record?.llm_config?.base_inference_url}
              </Text>
              {/* <Text>ID #{record?.id}</Text> */}
            </CourierInfoText>
          </Courier>
        </Col>
        <Col style={{ flex: 1 }}>
          <Courier style={{ width: "100%", textAlign: "center" }}>
            <CourierInfoText style={{ width: "100%", textAlign: "center" }}>
              <Text style={{ fontSize: 16 }}>Model Prompt Format</Text>
              <Text
                style={{
                  fontSize: 22,
                  fontWeight: 800,
                }}
              >
                {record?.llm_config?.model_prompt_format}
              </Text>
              {/* <Text>ID #{record?.id}</Text> */}
            </CourierInfoText>
          </Courier>
        </Col>
      </Row>
      <ProcessorEditors processor={record} />
    </Card>
  )

  const renderDeliverables = () => (
    <List
      headerProps={{ style: { marginTop: 20 } }}
      canCreate={false}
      title={
        <Text style={{ fontSize: 22, fontWeight: 800 }}>{t("Processors")}</Text>
      }
    >
      <Table
        pagination={false}
        dataSource={
          record?.processors.map((processor: IProcessor) => ({
            ...processor,
            key: processor.id,
          })) ?? []
        }
        footer={(_data) => (
          <ProductFooter>
            {/* <Text>{t("orders.deliverables.mainTotal")}</Text>
            <Text>{record?.amount}$</Text> */}
          </ProductFooter>
        )}
        expandable={{
          expandedRowRender: (record) =>
            renderExpandedProcessor(record as IProcessor),
          rowExpandable: (record) => record.name !== "Not Expandable",
          expandRowByClick: false,
        }}
      >
        <Table.Column<IProcessor>
          defaultSortOrder="descend"
          sorter={(a: IProcessor, b: IProcessor) => (a.name > b.name ? 1 : -1)}
          dataIndex="name"
          title={t("Names")}
          render={(value, record) => (
            <Product>
              <Avatar
                size={{
                  md: 30,
                  lg: 58,
                  xl: 58,
                  xxl: 58,
                }}
                src={""}
                style={{
                  backgroundColor: randomColor(),
                }}
              />
              <ProductText>
                <Text style={{ fontSize: 22, fontWeight: 800 }}>{value}</Text>
                <Text>#{record.id}</Text>
                <Text>Created: {dayjs(record.created_at).format("L LT")}</Text>
                <Text>Updated: {dayjs(record.updated_at).format("L LT")}</Text>
              </ProductText>
            </Product>
          )}
        />
        {/* <Table.Column
          title={t("LLM Config")}
          dataIndex="llm_config"
          render={() => <Text style={{ fontWeight: 800 }}>{"1x"}</Text>}
        /> */}
        <Table.Column
          defaultSortOrder="descend"
          sorter={(a: IProcessor, b: IProcessor) => (a.name > b.name ? 1 : -1)}
          dataIndex="llm_config"
          title={t("LLM Config")}
          align="center"
          render={(value) => (
            <Text style={{ fontWeight: 800 }}>{value.name}</Text>
          )}
        />
        <Table.Column
          defaultSortOrder="descend"
          sorter={(a: IProcessor, b: IProcessor) => (a.name > b.name ? 1 : -1)}
          dataIndex="json_schema"
          title={t("JSON Schema")}
          align="center"
          render={(value) => (
            <Text style={{ fontWeight: 800 }}>{value.name}</Text>
          )}
        />
        <Table.Column
          defaultSortOrder="descend"
          sorter={(a: IProcessor, b: IProcessor) => (a.name > b.name ? 1 : -1)}
          dataIndex="prompt"
          title={t("Prompt")}
          align="center"
          render={(value) => (
            <Text style={{ fontWeight: 800 }}>{value.name}</Text>
          )}
        />

        <Table.Column
          dataIndex="max_tokens_per_chunk_buffer"
          title={t("Max Tks/Chnk Buffer")}
          align="center"
          render={(value) => <Text style={{ fontWeight: 800 }}>{value}</Text>}
        />
      </Table>
    </List>
  )

  return (
    <>
      <Space size={20} direction="vertical" style={{ width: "100%" }}>
        {RenderPipelineSteps()}
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col style={{ flex: 1, width: "100%" }}>
            <div style={{ height: "500px", width: "100%" }}>
              <Typography.Title
                style={{
                  textAlign: "center",
                  backgroundColor: "lightgray",
                  color: "black",
                }}
                level={4}
              >
                Input Text
              </Typography.Title>
              {/* <CodeEditor
                minHeight={285}
                name="imagePromptParams"
                language="js"
                placeholder="Please enter JS code."
                value={editorText}
                onChange={(evn) => setEditorText(evn.target.value)}
                padding={15}
                style={{
                  fontSize: 12,
                  backgroundColor: "#f5f5f5",
                  fontFamily:
                    "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                }}
              /> */}
              <MDEditor
                value={editorText}
                onChange={(value, event) => setEditorText(value ?? "")}
                height={500}
                preview="edit"
                commands={[...commands.getCommands(), inferJSONSchema]}
              />
            </div>
          </Col>
          <Col style={{ flex: 1, width: "100%" }}>
            <div style={{ height: "500px", width: "100%" }}>
              <Typography.Title
                style={{
                  textAlign: "center",
                  backgroundColor: "lightgray",
                  color: "black",
                }}
                level={4}
              >
                Output Events
              </Typography.Title>
              <EventsTimeline
                handleOutputChange={onOutputChange}
                initialRequestId={requestId}
              />
            </div>
          </Col>
        </Row>
        {renderCourierInfo()}
      </Space>
      {renderDeliverables()}
    </>
  )
}
