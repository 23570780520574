import axios from "axios"
// import { HANDLE_FILE_URL } from "../constants"
import { v4 as uuidv4 } from 'uuid';
import { API_URL, TOKEN_KEY } from "../constants"


const uploadFile = async (key: any, file: any, myDataProvider: any) => {
    console.log("uploadFile", key, file)
    const fmData = new FormData()
    const token = localStorage.getItem(TOKEN_KEY)
    const config = {
        headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        }
    }
    const ext = file.name.split(".").pop()
    fmData.append("key", `Upload/${uuidv4()}.${ext}`)
    fmData.append("file", file)

    try {
        const res = await axios.post(
            API_URL.split("/v1")[0].concat("/upload_obj/"),
            fmData,
            config
        )

        console.log("server res: ", res)
        if (res.data && res.data.url) {
            const result = await myDataProvider.getFileText({
                resource: "pipelines",
                variables: {
                    file_url: res.data.url,
                },
            })

            return result
        }
    } catch (err) {
        console.log("Error: ", err)
    }


    // console.log("server res: ", res)

    // return res

}


export { uploadFile }