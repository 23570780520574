import React, { useRef } from "react";
import { Dialog } from "../components/dialog";
import "highlight.js/styles/tokyo-night-dark.css";
import mermaid from "mermaid";
import { createElement, Fragment, useEffect, useMemo, useState } from "react";
import rehypeHighlight from "rehype-highlight";
import rehypeReact from "rehype-react";
import remarkGfm from "remark-gfm";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import { unified } from "unified";
import { Button, Card } from "antd";
import { Copy, FilePng, FileSvg } from "@phosphor-icons/react";
import "./highlightjs-copy.css";


const formatTripleBackticks = (inputString: string) => {
    let count = 0;
  
    return inputString.replace(/(```)/g, (match, p1, offset, string) => {
      count++;
      if (count % 2 === 1) {
        return (offset > 0 && string[offset - 1] !== '\n') ? '\n' + p1 : p1;
      } else {
        return (offset + p1.length < string.length && string[offset + p1.length] !== '\n') ? p1 + '\n' : p1;
      }
    });
  }

export const useMarkdownProcessor = (content: string) => {
    console.log("useMarkdownProcessor content:", content)
  useEffect(() => {
    mermaid.initialize({ startOnLoad: false, ...{
        "theme": "base",
        "themeCSS": ".label foreignObject { overflow: visible; font-size: 90%; }",
        "themeVariables": {
          "darkMode": true,
          "background": "#1e1e1e",
          "fontFamily": "Gill Sans, sans-serif",
          "fontSize": "15px",
          "primaryColor": "#141619",
          "primaryTextColor": "#cccccc",
          "secondaryColor": "#330066",
          "primaryBorderColor": "#00ccff",
          "secondaryBorderColor": "#ffcc00",
          "secondaryTextColor": "#ffcc00",
          "tertiaryColor": "#555",
          "tertiaryBorderColor": "#d4d4d4",
          "tertiaryTextColor": "#d4d4d4",
          "noteBkgColor": "#555555",
          "noteTextColor": "#ffffff",
          "noteBorderColor": "#777777",
          "lineColor": "#00ccff",
          "textColor": "#d4d4d4",
          "mainBkg": "#262626",
          "errorBkgColor": "#ff5555",
          "errorTextColor": "#1e1e1e",
          "nodeBorder": "#7AA8D0",
          "clusterBkg": "#3d3d3d",
          "clusterBorder": "#d4d4d4",
          "defaultLinkColor": "#d4d4d4",
          "titleColor": "#ffffff",
          "edgeLabelBackground": "#3d3d3d",
          "nodeTextColor": "#ffffff",
          "actorBkg": "#262626",
          "actorBorder": "#81B1DB",
          "actorTextColor": "#d4d4d4",
          "actorLineColor": "#d4d4d4",
          "signalColor": "#ffcc66",
          "signalTextColor": "#1e1e1ez",
          "labelBoxBkgColor": "#262626",
          "labelBoxBorderColor": "#d4d4d4",
          "labelTextColor": "#d4d4d4",
          "loopTextColor": "#d4d4d4",
          "activationBorderColor": "#d4d4d4",
          "activationBkgColor": "#3d3d3d",
          "sequenceNumberColor": "#d4d4d4",
          "pie1": "#0f0",
          "pie2": "#00f",
          "pie3": "#f0f",
          "pie4": "#0f0",
          "pie5": "#00f",
          "pie6": "#f0f",
          "pie7": "#0f0",
          "pie8": "#00f",
          "pie9": "#f0f",
          "pie10": "#0f0",
          "pie11": "#00f",
          "pie12": "#f0f",
          "pieStrokeWidth": "2px",
          "pieOuterStrokeWidth": "2px",
          "pieOpacity": "0.7",
          "labelColor": "#fff",
          "altBackground": "#222",
          "classText": "#fff",
          "fillType0": "#141619",
          "fillType1": "#330066"
        }
      }
      });
  }, []);

  const preprocessedContent = formatTripleBackticks(content);

  return useMemo(() => {
    return (
      unified()
        .use(remarkParse)
        .use(remarkGfm)
        .use(remarkRehype)
        .use(rehypeHighlight as any, { ignoreMissing: true })
        .use(rehypeReact as any, {
          createElement,
          Fragment,
          components: {
            a: ({ href, children }: JSX.IntrinsicElements["a"]) => (
              <a href={href} target="_blank" rel="noreferrer" className="...">
                {children}
              </a>
            ),
            h1: ({ children, id }: JSX.IntrinsicElements["h1"]) => (
              <h1 className="..." id={id}>
                {children}
              </h1>
            ),
            h2: ({ children, id }: JSX.IntrinsicElements["h2"]) => (
              <h2 className="..." id={id}>
                {children}
              </h2>
            ),
            h3: ({ children, id }: JSX.IntrinsicElements["h3"]) => (
              <h3 className="..." id={id}>
                {children}
              </h3>
            ),
            h4: ({ children, id }: JSX.IntrinsicElements["h4"]) => (
              <h4 className="..." id={id}>
                {children}
              </h4>
            ),
            h5: ({ children, id }: JSX.IntrinsicElements["h5"]) => (
              <h5 className="..." id={id}>
                {children}
              </h5>
            ),
            h6: ({ children, id }: JSX.IntrinsicElements["h6"]) => (
              <h6 className="..." id={id}>
                {children}
              </h6>
            ),
            p: ({ children }: JSX.IntrinsicElements["p"]) => {
              return <p className="...">{children}</p>;
            },
            strong: ({ children }: JSX.IntrinsicElements["strong"]) => (
              <strong className="...">{children}</strong>
            ),
            em: ({ children }: JSX.IntrinsicElements["em"]) => (
              <em>{children}</em>
            ),
            code: CodeBlock,
            pre: ({ children }: JSX.IntrinsicElements["pre"]) => {
              return (
                <div className="...">
                  <pre className="...">{children}</pre>
                </div>
              );
            },
            ul: ({ children }: JSX.IntrinsicElements["ul"]) => (
              <ul className="...">{children}</ul>
            ),
            ol: ({ children }: JSX.IntrinsicElements["ol"]) => (
              <ol className="...">{children}</ol>
            ),
            li: ({ children }: JSX.IntrinsicElements["li"]) => (
              <li className="...">{children}</li>
            ),
            table: ({ children }: JSX.IntrinsicElements["table"]) => (
              <div className="...">
                <table className="...">{children}</table>
              </div>
            ),
            thead: ({ children }: JSX.IntrinsicElements["thead"]) => (
              <thead className="...">{children}</thead>
            ),
            th: ({ children }: JSX.IntrinsicElements["th"]) => (
              <th className="...">{children}</th>
            ),
            td: ({ children }: JSX.IntrinsicElements["td"]) => (
              <td className="...">{children}</td>
            ),
            blockquote: ({ children }: JSX.IntrinsicElements["blockquote"]) => (
              <blockquote className="...">{children}</blockquote>
            ),
          },
        })
        .processSync(preprocessedContent).result
    );
  }, [preprocessedContent]);
};

const CodeBlock = ({ children, className }: JSX.IntrinsicElements["code"]) => {
  const [showMermaidPreview, setShowMermaidPreview] = useState(false);
  const codeBlockRef = useRef(null);

    const extractText = (elements) => {
        return React.Children.toArray(elements).reduce((text, element) => {
          if (typeof element === 'string') {
            return text + element;
          } else if (React.isValidElement(element) && element.props.children) {
            return text + extractText(element.props.children);
          }
          return text;
        }, '');
      };
  
    useEffect(() => {
      if (!codeBlockRef.current) return;
  
      const codeEl = codeBlockRef.current;
      const button = document.createElement("button");
      button.innerHTML = "Copy";
      button.className = "hljs-copy-button";
      button.dataset.copied = 'false';
  
      // Append the button to the code block
      codeEl.parentElement.classList.add("hljs-copy-wrapper");
      codeEl.parentElement.appendChild(button);
  
      button.onclick = () => {
        const textToCopy = extractText(children);
        if (!navigator.clipboard || !textToCopy) return;
  
        navigator.clipboard.writeText(textToCopy)
          .then(() => {
            button.innerHTML = "Copied!";
            button.dataset.copied = 'true';
  
            let alert = document.createElement("div");
            alert.role = "status";
            alert.className = "hljs-copy-alert";
            alert.innerHTML = "Copied to clipboard";
            codeEl.parentElement.appendChild(alert);
  
            setTimeout(() => {
              button.innerHTML = "Copy";
              button.dataset.copied = "false";
              codeEl.parentElement.removeChild(alert);
            }, 2000);
          });
      };
    }, [children]);

  if (className) {
    const isMermaid = className.includes("language-mermaid");

    return (
      <>
        <code className={className} ref={codeBlockRef}>{children}</code>
        <div className="..." style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px"
        }}>
          {isMermaid ? (
            <>
              <Button
                type="primary"
                onClick={() => {
                  setShowMermaidPreview(true);
                }}
              >
                Open Mermaid Preview
              </Button>
              
              <Dialog
                open={showMermaidPreview}
                setOpen={setShowMermaidPreview}
                title="Mermaid Diagram Preview"
                size="3xl"
              >
                <Mermaid content={children?.toString() ?? ""} />
              </Dialog>
            </>
          ) : null}
        </div>
      </>
    );
  }

  return <code className="...">{children}</code>;
};

const Mermaid = ({ content }: { content: string }) => {
  const [diagram, setDiagram] = useState<string | boolean>(true);
  const mermaidRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  let startPos = { x: 0, y: 0 };

  useEffect(() => {
    const render = async () => {
      const id = `mermaid-svg-${Math.round(Math.random() * 10000000)}`;

      if (await mermaid.parse(content, { suppressErrors: true })) {
        const { svg } = await mermaid.render(id, content);
        
        setDiagram(svg);
        
      } else {
        setDiagram(false);
      }
      if (mermaidRef.current) {
        const container = mermaidRef.current.parentElement; // Assuming the parent is the container
        const svgElement = mermaidRef.current.querySelector('svg');

        if (container && svgElement) {
          const containerWidth = container.clientWidth;
          const containerHeight = container.clientHeight;
          const svgWidth = svgElement.clientWidth;
          const svgHeight = svgElement.clientHeight;

          // Calculate the center position
          const x = (containerWidth - svgWidth) / 2;
          const y = (containerHeight - svgHeight) / 2;

          setPosition({ x, y });
        }
      }
    };
    render();
  }, [content]);

  const handleWheel = (event) => {
    event.preventDefault();
    const scaleAmount = 0.1;
    setScale(scale => {
      return event.deltaY < 0 ? scale + scaleAmount : scale - scaleAmount;
    });
  };

  const handleMouseDown = (event) => {
    startPos = {
      x: event.clientX - position.x,
      y: event.clientY - position.y,
    };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (event) => {
    const newPosition = {
      x: event.clientX - startPos.x,
      y: event.clientY - startPos.y,
    };
    setPosition(newPosition);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  // Additional logic to limit zoom and pan bounds
  const limitZoomAndPan = () => {
    // Example logic to limit zoom
    if (scale < 1) setScale(1);
    if (scale > 5) setScale(5);

    // Example logic to limit pan
    // Adjust these values based on your container's dimensions
    const bounds = { left: -300, right: 300, top: -300, bottom: 300 };
    if (position.x < bounds.left) setPosition({ ...position, x: bounds.left });
    if (position.x > bounds.right) setPosition({ ...position, x: bounds.right });
    if (position.y < bounds.top) setPosition({ ...position, y: bounds.top });
    if (position.y > bounds.bottom) setPosition({ ...position, y: bounds.bottom });
  };

  useEffect(() => {
    limitZoomAndPan();
  }, [scale, position]);

  if (diagram === true) {
    return <p className="...">Rendering diagram...</p>;
  } else if (diagram === false) {
    return <p className="...">Unable to render this diagram.</p>;
  } else {
    return (
        <>
        <div style={{
          width: '100%', // Set the width
          height: '650px', // Set the height
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
            <div 
            onWheel={handleWheel}
            onMouseDown={handleMouseDown}
            style={{
              cursor: 'grab',
              transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
              transformOrigin: 'center',
              position: 'absolute',
              marginBottom: '100px',
            }}
            ref={mermaidRef}
            dangerouslySetInnerHTML={{ __html: diagram ?? "" }} />
            <div style={{ position: "absolute", bottom: "0px", width: "100%" }}>
                <Card
                    style={{ width: "100%" }}

                    actions={[
                        <Copy size={32} key="copy" alt="Copy" className="anticon" onClick={() => {
                            const svg = mermaidRef.current.querySelector('svg');
                            svg.removeAttribute("style")
                            const serializer = new XMLSerializer();
                            const source = serializer.serializeToString(svg);
                            const svgDataBase64 = btoa(unescape(encodeURIComponent(source)))
                            const svgDataUrl = `data:image/svg+xml;charset=utf-8;base64,${svgDataBase64}`

                            const img = new Image();
                            // img.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);
                            img.src = svgDataUrl;
                            img.onload = () => {
                                const canvas = document.createElement('canvas');
                                const ctx = canvas.getContext('2d');
                                
                                // const ctx = canvas.getContext('2d');
                                
                                // Adjust these multipliers to increase the image size
                                const scaleFactor = 5; // Example: Increase image size by 2x
                                const ratio = window.devicePixelRatio;
                                // const ratio = 1;
                                

                                // Adjust canvas size based on devicePixelRatio for high-resolution displays
                                canvas.width = svg.parentElement.clientWidth * scaleFactor * ratio;
                                canvas.height = svg.parentElement.clientHeight * scaleFactor * ratio;
                                ctx.scale(scaleFactor * ratio, scaleFactor * ratio);

                                // Draw the image onto the canvas
                                ctx.drawImage(img, 0, 0, svg.parentElement.clientWidth, svg.parentElement.clientHeight);
                                // ctx.drawImage(img, 0, 0);
                                canvas.toBlob((blob) => {
                                    const data = [new ClipboardItem({ [blob.type]: blob })];
                                    navigator.clipboard.write(data);
                                });
                            };
                            // img.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);

                        }} />,
                        <FileSvg size={32} key="file" alt="File" className="anticon" onClick={() => {
                            const svg = mermaidRef.current.querySelector('svg');
                            svg.removeAttribute("style")
                            const serializer = new XMLSerializer();
                            const source = serializer.serializeToString(svg);
                            const a = document.createElement('a');
                            a.href = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);
                            a.download = 'diagram.svg';
                            a.click();
                        }} />,
                        <FilePng size={32} key="file" alt="File" className="anticon" onClick={() => {
                            const svg = mermaidRef.current.querySelector('svg');
                            svg.removeAttribute("style")
                            const serializer = new XMLSerializer();
                            const source = serializer.serializeToString(svg);
                            const svgDataBase64 = btoa(unescape(encodeURIComponent(source)))
                            const svgDataUrl = `data:image/svg+xml;charset=utf-8;base64,${svgDataBase64}`

                            const img = new Image();
                            // img.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);
                            img.src = svgDataUrl;
                            
                            img.onload = () => {
                                const canvas = document.createElement('canvas');
                                const ctx = canvas.getContext('2d');
                                
                                // Adjust these multipliers to increase the image size
                                const scaleFactor = 5; // Example: Increase image size by 2x
                                const ratio = window.devicePixelRatio;
                                // const ratio = 1;
                                

                                // Adjust canvas size based on devicePixelRatio for high-resolution displays
                                canvas.width = svg.parentElement.clientWidth * scaleFactor * ratio;
                                canvas.height = svg.parentElement.clientHeight * scaleFactor * ratio;

                                // Scale the context to ensure the SVG is drawn at the higher resolution
                                ctx.scale(scaleFactor * ratio, scaleFactor * ratio);

                                // Draw the image onto the canvas
                                ctx.drawImage(img, 0, 0, svg.parentElement.clientWidth, svg.parentElement.clientHeight);

                                // Convert the canvas to a Blob and then to a URL
                                canvas.toBlob((blob) => {
                                const url = URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = 'diagram.png';
                                a.click();

                                // Clean up the URL object
                                URL.revokeObjectURL(url);
                                });
                            };
                        }} />,
                    ]}
                >
                    <Card.Meta
                        title="Export Options"
                    />
                </Card>
            </div>
            </div>
        </>
    );
  }
};

// import { Dialog } from "../components/dialog";
// import { CircleNotch, MathOperations } from "@phosphor-icons/react";
// import CheckFat from "@phosphor-icons/react/dist/icons/CheckFat";
// import Copy from "@phosphor-icons/react/dist/icons/Copy";
// import FlowArrow from "@phosphor-icons/react/dist/icons/FlowArrow";
// import { Root } from "hast";
// import "highlight.js/styles/base16/green-screen.css";
// import mermaid from "mermaid";
// import Link from "next/link";
// import React, {
//     Children,
//     Fragment,
//     createElement,
//     isValidElement,
//     useEffect,
//     useMemo,
//     useState,
//     useRef,
// } from "react";
// import flattenChildren from "react-keyed-flatten-children";
// import rehypeHighlight from "rehype-highlight";
// import rehypeReact from "rehype-react";
// import remarkGfm from "remark-gfm";
// import remarkParse from "remark-parse";
// import remarkRehype from "remark-rehype";
// import { Plugin, unified } from "unified";
// import { visit } from "unist-util-visit";

// // import { HtmlGenerator, parse } from "latex";
// // import "node_modules/latex.js/dist/css/base.css"
// import 'katex/dist/katex.min.css';
// import Latex from 'react-latex-next';
// // import "latex.js/dist/css/katex.css";
// // import "latex.js/dist/css/kate.css";
// export const ANCHOR_CLASS_NAME =
//     "font-semibold underline text-emerald-700 underline-offset-[2px] decoration-1 hover:text-emerald-800 transition-colors";

// // const { parse, HtmlGenerator } = require('latex.js')

// // Mixing arbitrary Markdown + Capsize leads to lots of challenges
// // with paragraphs and list items. This replaces paragraphs inside
// // list items into divs to avoid nesting Capsize.
// const rehypeListItemParagraphToDiv: Plugin<[], Root> = () => {
//     return (tree) => {
//         visit(tree, "element", (element) => {
//             if (element.tagName === "li") {
//                 element.children = element.children.map((child) => {
//                     if (child.type === "element" && child.tagName === "p") {
//                         child.tagName = "div";
//                     }
//                     return child;
//                 });
//             }
//         });
//         return tree;
//     };
// };

// export const useMarkdownProcessor = (content: string) => {
//     useEffect(() => {
//         mermaid.initialize({ startOnLoad: false, theme: "forest" });
//     }, []);

//     return useMemo(() => {
//         return unified()
//             .use(remarkParse)
//             .use(remarkGfm)
//             .use(remarkRehype)
//             .use(rehypeHighlight, { ignoreMissing: true })
//             .use(rehypeListItemParagraphToDiv)
//             .use(rehypeReact, {
//                 createElement,
//                 Fragment,
//                 components: {
//                     a: ({ href, children }: JSX.IntrinsicElements["a"]) => (
//                         <a
//                             href={href}
//                             target="_blank"
//                             rel="noreferrer"
//                             className={ANCHOR_CLASS_NAME}
//                         >
//                             {children}
//                         </a>
//                     ),
//                     h1: ({ children, id }: JSX.IntrinsicElements["h1"]) => (
//                         <h1
//                             className="font-sans font-semibold text-2xl text-emerald-950 mb-6 mt-6"
//                             id={id}
//                         >
//                             {children}
//                         </h1>
//                     ),
//                     h2: ({ children, id }: JSX.IntrinsicElements["h2"]) => (
//                         <h2
//                             className="font-sans font-medium text-2xl text-emerald-950 mb-6 mt-6"
//                             id={id}
//                         >
//                             {children}
//                         </h2>
//                     ),
//                     h3: ({ children, id }: JSX.IntrinsicElements["h3"]) => (
//                         <h3
//                             className="font-sans font-semibold text-xl text-emerald-950 mb-6 mt-2"
//                             id={id}
//                         >
//                             {children}
//                         </h3>
//                     ),
//                     h4: ({ children, id }: JSX.IntrinsicElements["h4"]) => (
//                         <h4
//                             className="font-sans font-medium text-xl text-emerald-950 my-6"
//                             id={id}
//                         >
//                             {children}
//                         </h4>
//                     ),
//                     h5: ({ children, id }: JSX.IntrinsicElements["h5"]) => (
//                         <h5
//                             className="font-sans font-semibold text-lg text-emerald-950 my-6"
//                             id={id}
//                         >
//                             {children}
//                         </h5>
//                     ),
//                     h6: ({ children, id }: JSX.IntrinsicElements["h6"]) => (
//                         <h6
//                             className="font-sans font-medium text-lg text-emerald-950 my-6"
//                             id={id}
//                         >
//                             {children}
//                         </h6>
//                     ),
//                     p: (props: JSX.IntrinsicElements["p"]) => {
//                         return (
//                             <p className="font-sans text-sm text-emerald-900 mb-6">
//                                 {props.children}
//                             </p>
//                         );
//                     },
//                     strong: ({ children }: JSX.IntrinsicElements["strong"]) => (
//                         <strong className="text-emerald-950 font-semibold">
//                             {children}
//                         </strong>
//                     ),
//                     em: ({ children }: JSX.IntrinsicElements["em"]) => (
//                         <em>{children}</em>
//                     ),
//                     code: CodeBlock,
//                     pre: ({ children }: JSX.IntrinsicElements["pre"]) => {
//                         return (
//                             <div className="relative mb-6">
//                                 <pre className="p-4 rounded-lg border-2 border-emerald-200 bg-emerald-100 [&>code.hljs]:p-0 [&>code.hljs]:bg-transparent font-code text-sm overflow-x-auto flex items-start">
//                                     {children}
//                                 </pre>
//                             </div>
//                         );
//                     },
//                     ul: ({ children }: JSX.IntrinsicElements["ul"]) => (
//                         <ul className="flex flex-col gap-3 text-emerald-900 my-6 pl-3 [&_ol]:my-3 [&_ul]:my-3">
//                             {Children.map(
//                                 flattenChildren(children).filter(isValidElement),
//                                 (child, index) => (
//                                     <li key={index} className="flex gap-2 items-start">
//                                         <div className="w-1 h-1 rounded-full bg-current block shrink-0 mt-1" />
//                                         {child}
//                                     </li>
//                                 )
//                             )}
//                         </ul>
//                     ),
//                     ol: ({ children }: JSX.IntrinsicElements["ol"]) => (
//                         <ol className="flex flex-col gap-3 text-emerald-900 my-6 pl-3 [&_ol]:my-3 [&_ul]:my-3">
//                             {Children.map(
//                                 flattenChildren(children).filter(isValidElement),
//                                 (child, index) => (
//                                     <li key={index} className="flex gap-2 items-start">
//                                         <div
//                                             className="font-sans text-sm text-emerald-900 font-semibold shrink-0 min-w-[1.4ch]"
//                                             aria-hidden
//                                         >
//                                             {index + 1}.
//                                         </div>
//                                         {child}
//                                     </li>
//                                 )
//                             )}
//                         </ol>
//                     ),
//                     li: ({ children }: JSX.IntrinsicElements["li"]) => (
//                         <div className="font-sans text-sm">{children}</div>
//                     ),
//                     table: ({ children }: JSX.IntrinsicElements["table"]) => (
//                         <div className="overflow-x-auto mb-6">
//                             <table className="table-auto border-2 border-emerald-200">
//                                 {children}
//                             </table>
//                         </div>
//                     ),
//                     thead: ({ children }: JSX.IntrinsicElements["thead"]) => (
//                         <thead className="bg-emerald-100">{children}</thead>
//                     ),
//                     th: ({ children }: JSX.IntrinsicElements["th"]) => (
//                         <th className="border-2 border-emerald-200 p-2 font-sans text-sm font-semibold text-emerald-950">
//                             {children}
//                         </th>
//                     ),
//                     td: ({ children }: JSX.IntrinsicElements["td"]) => (
//                         <td className="border-2 border-emerald-200 p-2 font-sans text-sm text-emerald-900">
//                             {children}
//                         </td>
//                     ),
//                     blockquote: ({ children }: JSX.IntrinsicElements["blockquote"]) => (
//                         <blockquote className="border-l-4 border-emerald-200 pl-2 text-emerald-900 italic">
//                             {children}
//                         </blockquote>
//                     ),
//                 },
//             })
//             .processSync(content).result;
//     }, [content]);
// };

// const CodeBlock = ({ children, className }: JSX.IntrinsicElements["code"]) => {
//     const [copied, setCopied] = useState(false);
//     const [showMermaidPreview, setShowMermaidPreview] = useState(false);
//     const [showLatexPreview, setShowLatexPreview] = useState(false);
//     const ref = useRef<HTMLElement>(null);

//     useEffect(() => {
//         if (copied) {
//             const interval = setTimeout(() => setCopied(false), 1000);
//             return () => clearTimeout(interval);
//         }
//     }, [copied]);

//     // Highlight.js adds a `className` so this is a hack to detect if the code block
//     // is a language block wrapped in a `pre` tag.
//     if (className) {
//         const isMermaid = className.includes("language-mermaid");
//         const isLatex = className.includes("language-latex");

//         return (
//             <>
//                 <code ref={ref} className={`${className} flex-grow flex-shrink my-auto`}>
//                     {children}
//                 </code>
//                 <div className="flex flex-col gap-1 flex-grow-0 flex-shrink-0">
//                     <button
//                         type="button"
//                         className="rounded-md p-1 text-emerald-900 hover:bg-emerald-200 border-2 border-emerald-200 transition-colors"
//                         aria-label="copy code to clipboard"
//                         title="Copy code to clipboard"
//                         onClick={() => {
//                             if (ref.current) {
//                                 navigator.clipboard.writeText(ref.current.innerText ?? "");
//                                 setCopied(true);
//                             }
//                         }}
//                     >
//                         {copied ? (
//                             <CheckFat className="w-4 h-4" />
//                         ) : (
//                             <Copy className="w-4 h-4" />
//                         )}
//                     </button>
//                     {isMermaid ? (
//                         <>
//                             <button
//                                 type="button"
//                                 className="rounded-md p-1 text-emerald-900 hover:bg-emerald-200 border-2 border-emerald-200 transition-colors"
//                                 aria-label="Open Mermaid preview"
//                                 title="Open Mermaid preview"
//                                 onClick={() => {
//                                     setShowMermaidPreview(true);
//                                 }}
//                             >
//                                 <FlowArrow className="w-4 h-4" />
//                             </button>
//                             <Dialog
//                                 open={showMermaidPreview}
//                                 setOpen={setShowMermaidPreview}
//                                 title="Mermaid diagram preview"
//                                 size="3xl"
//                             >
//                                 <Mermaid content={children?.toString() ?? ""} />
//                             </Dialog>
//                         </>
//                     ) : null}
//                     {isLatex ? (
//                         <>
//                             <button
//                                 type="button"
//                                 className="rounded-md p-1 text-emerald-900 hover:bg-emerald-200 border-2 border-emerald-200 transition-colors"
//                                 aria-label="Open Latex preview"
//                                 title="Open Latex preview"
//                                 onClick={() => {
//                                     setShowLatexPreview(true);
//                                 }}
//                             >
//                                 <MathOperations className="w-4 h-4" />
//                             </button>
//                             <Dialog
//                                 open={showLatexPreview}
//                                 setOpen={setShowLatexPreview}
//                                 title="Latex diagram preview"
//                                 size="3xl"
//                             >
//                                 <Latex>{children?.toString() ?? ""}</Latex>
//                             </Dialog>
//                         </>
//                     ) : null}
//                 </div>
//             </>
//         );
//     }

//     return (
//         <code className="inline-block font-code bg-emerald-100 text-emerald-950 p-0.5 -my-0.5 rounded">
//             {children}
//         </code>
//     );
// };

// // const Latex = ({ content }: { content: string }) => {
// //     const [diagram, setDiagram] = useState<string | boolean>(true);

// //     useEffect(() => {
// //         try {
// //             // const generator = new HtmlGenerator({ hyphenate: false });
// //             // const fragment = parse(content, { generator: generator }).domFragment();
// //             // setDiagram(fragment.firstElementChild.outerHTML);
// //             setDiagram(<L>{content}</L>)
// //         } catch (error) {
// //             console.error(error);
// //             setDiagram(false);
// //         }
// //     }, [content]);

// //     if (diagram === true) {
// //         return (
// //             <div className="flex gap-2 items-center">
// //                 <CircleNotch className="animate-spin w-4 h-4 text-emerald-900" />
// //                 <p className="font-sans text-sm text-slate-700">Rendering diagram...</p>
// //             </div>
// //         );
// //     } else if (diagram === false) {
// //         return (
// //             <p className="font-sans text-sm text-slate-700">
// //                 Unable to render this diagram.
// //             </p>
// //         );
// //     } else {
// //         return <div dangerouslySetInnerHTML={{ __html: diagram ?? "" }} />;
// //     }
// // };

// const Mermaid = ({ content }: { content: string }) => {
//     const [diagram, setDiagram] = useState<string | boolean>(true);

//     useEffect(() => {
//         const render = async () => {
//             // Generate a random ID for mermaid to use.
//             const id = `mermaid-svg-${Math.round(Math.random() * 10000000)}`;

//             // Confirm the diagram is valid before rendering.
//             if (await mermaid.parse(content, { suppressErrors: true })) {
//                 const { svg } = await mermaid.render(id, content);
//                 setDiagram(svg);
//             } else {
//                 setDiagram(false);
//             }
//         };
//         render();
//     }, [content]);

//     if (diagram === true) {
//         return (
//             <div className="flex gap-2 items-center">
//                 <CircleNotch className="animate-spin w-4 h-4 text-emerald-900" />
//                 <p className="font-sans text-sm text-slate-700">Rendering diagram...</p>
//             </div>
//         );
//     } else if (diagram === false) {
//         return (
//             <p className="font-sans text-sm text-slate-700">
//                 Unable to render this diagram. Try copying it into the{" "}
//                 <Link
//                     href="https://mermaid.live/edit"
//                     className={ANCHOR_CLASS_NAME}
//                     target="_blank"
//                 >
//                     Mermaid Live Editor
//                 </Link>
//                 .
//             </p>
//         );
//     } else {
//         return <div dangerouslySetInnerHTML={{ __html: diagram ?? "" }} />;
//     }
// };

// export const MARKDOWN_TEST_MESSAGE = `
// # Heading level 1

// This is the first paragraph.

// This is the second paragraph.

// This is the third paragraph.

// ## Heading level 2

// This is an [anchor](https://github.com).

// ### Heading level 3

// This is **bold** and _italics_.

// #### Heading level 4

// This is \`inline\` code.

// This is a code block:

// \`\`\`tsx
// const Message = () => {
//   return <div>hi</div>;
// };
// \`\`\`

// ##### Heading level 5

// This is an unordered list:

// - One
// - Two
// - Three, and **bold**

// This is an ordered list:

// 1. One
// 1. Two
// 1. Three

// This is a complex list:

// 1. **Bold**: One
//     - One
//     - Two
//     - Three
  
// 2. **Bold**: Three
//     - One
//     - Two
//     - Three
  
// 3. **Bold**: Four
//     - One
//     - Two
//     - Three

// ###### Heading level 6

// > This is a blockquote.

// This is a table:

// | Vegetable | Description |
// |-----------|-------------|
// | Carrot    | A crunchy, orange root vegetable that is rich in vitamins and minerals. It is commonly used in soups, salads, and as a snack. |
// | Broccoli  | A green vegetable with tightly packed florets that is high in fiber, vitamins, and antioxidants. It can be steamed, boiled, stir-fried, or roasted. |
// | Spinach   | A leafy green vegetable that is dense in nutrients like iron, calcium, and vitamins. It can be eaten raw in salads or cooked in various dishes. |
// | Bell Pepper | A colorful, sweet vegetable available in different colors such as red, yellow, and green. It is often used in stir-fries, salads, or stuffed recipes. |
// | Tomato    | A juicy fruit often used as a vegetable in culinary preparations. It comes in various shapes, sizes, and colors and is used in salads, sauces, and sandwiches. |
// | Cucumber   | A cool and refreshing vegetable with a high water content. It is commonly used in salads, sandwiches, or as a crunchy snack. |
// | Zucchini | A summer squash with a mild flavor and tender texture. It can be sautéed, grilled, roasted, or used in baking recipes. |
// | Cauliflower | A versatile vegetable that can be roasted, steamed, mashed, or used to make gluten-free alternatives like cauliflower rice or pizza crust. |
// | Green Beans | Long, slender pods that are low in calories and rich in vitamins. They can be steamed, stir-fried, or used in casseroles and salads. |
// | Potato | A starchy vegetable available in various varieties. It can be boiled, baked, mashed, or used in soups, fries, and many other dishes. |

// This is a mermaid diagram:

// \`\`\`mermaid
// gitGraph
//     commit
//     commit
//     branch develop
//     checkout develop
//     commit
//     commit
//     checkout main
//     merge develop
//     commit
//     commit
// \`\`\`

// \`\`\`latex
// \\[F(x) = \\int_{a}^{b} f(x) \\, dx\\]
// \`\`\`
// `;