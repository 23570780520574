import React, { useRef, useState } from "react"
import { Card, Typography, Modal, theme, AutoComplete, Input, FloatButton, Avatar, Drawer, Space, Divider, Row, Col, Form, Tabs, Switch, Button } from "antd"
import { Color } from "antd/es/color-picker"
import {
    EditOutlined,
    DeleteOutlined,
    CopyOutlined,
    ToolOutlined,
    SaveOutlined,
    InfoCircleOutlined
} from "@ant-design/icons"
import "./HorizontalStyle.css";
import { CourierInfoText } from "../../../styled/styled"
// import MDEditor from "../../MDEditor";
import MDEditor from "@uiw/react-md-editor"
import Editor from "@monaco-editor/react"


var JSON5 = require("json5")


const { Text } = Typography
const { TextArea } = Input;



interface DragElementProps {
    provided: any
    snapshot: any
    item: any
    availableTemplateVariables: string[]
    onAdd: (item: any) => void
    onRemove: (item: any) => void
    onCopy: (item: any) => void
    onEdit: (item: any) => void
    onSaveProcessor: (processor: any) => void
    editable?: boolean
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, active, toggle) => ({
    userSelect: "none",
    margin: `0 ${grid}px 0 0`,
    width: toggle ? "725px" : "325px",
    height: toggle ? "525px" : "325px",
    opacity: isDragging ? "0.5" : active ? "1" : "0.5",
    marginBottom: "15px",
    boxShadow: "1px 4px 11px -2px rgba(135,135,135,0.75)",
    ...draggableStyle,

});


export const DragElement = (props: DragElementProps) => {
    const [form] = Form.useForm();
    const [toggle, setToggle] = useState(false);
    const { provided, snapshot, item, onAdd, onRemove, onCopy, onEdit, availableTemplateVariables, onSaveProcessor, editable = true } = props
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false)
    const [color, setColor] = useState<Color | string>("#0a427f");
    const [prompt, setPrompt] = useState<string>(item.prompt.text)
    const [systemPrompt, setSystemPrompt] = useState<string>(item.llm_config.default_system_prompt)
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const [visible, setVisible] = useState(false);


    const handleSubmit = (values) => {
        console.log("handleSubmit", values)
        console.log("onEdit", { ...item, ...values })
        onEdit({ ...item, ...values })
    }

    const handleSearch = (value: string, field: string) => {
        if ((value.match(/{/g) || []).length > (value.match(/}/g) || []).length) {
            setOptions(
                // [{ value: "test" }]
                availableTemplateVariables.map((templateVariable) => {
                    return { value: templateVariable }
                })
            );
        }
        else {
            setOptions([])
        }

    };

    const getPartialTemplate = (input) => {
        let depth = 0;
        let indexToReplace = -1;

        // Iterate through each character in the string
        for (let i = 0; i < input.length; i++) {
            if (input[i] === '{') {
                depth++;
                if (depth === 1) {
                    // Mark the index when the first unmatched '{' is found
                    indexToReplace = i;
                }
            } else if (input[i] === '}') {
                depth--;
            }

            // If depth becomes zero again, reset indexToReplace
            if (depth === 0 && indexToReplace !== -1) {
                indexToReplace = -1;
            }
        }

        // If an unmatched '{' is found
        if (indexToReplace !== -1) {
            // Get the substring after the unmatched '{'
            let restOfString = input.substring(indexToReplace + 1);

            // Return the modified string
            const match = restOfString.match(/^[a-zA-Z0-9.]+/);
            return match ? match[0] : '';
        }

        // If no unmatched '{' is found, return the original input
        return input;
    }

    const replaceTemplate = (input, value) => {
        let depth = 0;
        let indexToReplace = -1;

        // Iterate through each character in the string
        for (let i = 0; i < input.length; i++) {
            if (input[i] === '{') {
                depth++;
                if (depth === 1) {
                    // Mark the index when the first unmatched '{' is found
                    indexToReplace = i;
                }
            } else if (input[i] === '}') {
                depth--;
            }

            // If depth becomes zero again, reset indexToReplace
            if (depth === 0 && indexToReplace !== -1) {
                indexToReplace = -1;
            }
        }

        // If an unmatched '{' is found
        if (indexToReplace !== -1) {
            // Get the substring after the unmatched '{'
            let restOfString = input.substring(indexToReplace + 1);

            // Remove preceding alphanumeric characters
            restOfString = restOfString.replace(/^[a-zA-Z0-9.]+/, '');

            // Return the modified string
            return input.substring(0, indexToReplace) + '{' + value + '}' + restOfString;
        }

        // If no unmatched '{' is found, return the original input
        return input;
    };

    const onSelect = (value: string, field: string, input: string) => {
        if (field === "prompt") {
            console.log("input:", input)
            console.log("value:", value)
            const replacement = replaceTemplate(input, value)
            console.log("replacement:", replacement)
            form.setFieldValue(["prompt", "text"], replacement)
            setPrompt(replacement)
        }
        else {
            const replacement = replaceTemplate(input, value)
            form.setFieldValue(["llm_config", "default_system_prompt"], replacement)
            setSystemPrompt(replacement)
        }
    };
    const showDrawer = () => {
        setToggle(!toggle)
        setOpen(true);
    };

    const onClose = () => {
        setToggle(!toggle)
        setOpen(false);
    };


    const formatPrompt = (prompt: string) => {
        // make template variables blue
        const regex = /{([^}]+)}/g;
        const subst = `<span style="color: blue;">{$1}</span>`;
        const result = prompt.replace(regex, subst);
        return result
    }



    return (
        <>
            <Modal
                title={"Edit " + item.name}
                centered
                open={modalOpen}
                onOk={() => {
                    form.submit()
                    setModalOpen(false)
                }}
                onCancel={() => {
                    setModalOpen(false)
                    form.resetFields()
                }}
                width={"800px"}
                style={{
                    top: "100px"
                }}

            >
                <Form
                    disabled={!editable}
                    layout={"vertical"}
                    form={form}
                    style={{
                        overflowX: "hidden",
                        overflowY: "auto",
                        height: "500px",
                        width: "100%"
                    }}
                    initialValues={
                        {
                            ...item,
                            json_schema: {
                                name: item.json_schema.name,
                                content: JSON.stringify(
                                    JSON5.parse(
                                        item.json_schema.content
                                    ),
                                    null,
                                    4
                                )
                            },
                            llm_config: {
                                ...item.llm_config,
                                model_params: JSON.stringify(
                                    JSON5.parse(
                                        item.llm_config.model_params
                                    ),
                                    null,
                                    4
                                ),
                                environment_variables: JSON.stringify(
                                    JSON5.parse(
                                        item.llm_config.environment_variables
                                    ),
                                    null,
                                    4
                                )
                            },
                            generation_params: JSON.stringify(
                                JSON5.parse(
                                    item.gen_params
                                ),
                                null,
                                4
                            )
                        }
                    }
                    onFinish={() => { const values = form.getFieldsValue(true); handleSubmit(values) }}
                >
                    <Tabs className="card-tabs">
                        <Tabs.TabPane tab="General" key='general'>
                            <Form.Item label="Processor Name" name="name">
                                <Input
                                    value={item.name}
                                />
                            </Form.Item>
                            <Form.Item label="System Prompt" name={["llm_config", "default_system_prompt"]}>
                                <AutoComplete
                                    options={options}
                                    onSelect={(value) => {
                                        const input = systemPrompt
                                        onSelect(value, "systemPrompt", input)
                                    }}
                                    onSearch={(value) => {
                                        handleSearch(value, "systemPrompt")
                                    }}
                                    filterOption={(inputValue, option) => {
                                        const filterVal = getPartialTemplate(inputValue);
                                        return option.value.toUpperCase().indexOf(filterVal.toUpperCase()) !== -1
                                    }
                                    }
                                    style={{ width: "100%" }}
                                >
                                    <TextArea rows={5} value={item.llm_config.default_system_prompt} style={{ width: "100%" }} onChange={(e) => {
                                        setSystemPrompt(e.target.value)
                                    }} />

                                </AutoComplete>
                            </Form.Item>

                            <Form.Item label="Prompt" name={["prompt", "text"]}>
                                <AutoComplete
                                    options={options}
                                    onSelect={(value) => {
                                        const input = prompt
                                        console.log("input:", input)
                                        onSelect(value, "prompt", input)
                                    }}
                                    onSearch={(value) => {
                                        handleSearch(value, "prompt")
                                    }}
                                    filterOption={(inputValue, option) => {
                                        const filterVal = getPartialTemplate(inputValue);
                                        return option.value.toUpperCase().indexOf(filterVal.toUpperCase()) !== -1
                                    }
                                    }
                                    style={{ width: "100%" }}
                                >
                                    <TextArea rows={5} style={{ width: "100%" }} onChange={(e) => {
                                        setPrompt(e.target.value)
                                    }}
                                    />
                                </AutoComplete>
                            </Form.Item>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="JSON Schema" key='json_schema'>
                            <Form.Item label="Schema Name" name={["json_schema", "name"]}>
                                <Input
                                    value={item.json_schema.name}
                                />
                            </Form.Item>
                            <Form.Item label="Schema Content" name={["json_schema", "content"]}>

                                <Editor
                                    height="350px"
                                    language="json"
                                    onChange={(value) => {
                                        form.setFieldValue(["json_schema", "content"], value)
                                    }}
                                />

                            </Form.Item>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="LLM" key='llm'>
                            <Form.Item label="Server Type" name={["llm_config", "server_type"]}>
                                <Input
                                    value={item.llm_config.server_type}
                                />
                            </Form.Item>
                            <Form.Item label="Base Inference URL" name={["llm_config", "base_inference_url"]}>
                                <Input
                                    value={item.llm_config.base_inference_url}
                                />
                            </Form.Item>
                            <Form.Item label="Model Prompt Format" name={["llm_config", "model_prompt_format"]}>
                                <Input
                                    value={item.llm_config.model_prompt_format}
                                />
                            </Form.Item>
                            <Form.Item label="Max Input Length" name={["llm_config", "max_input_length"]}>
                                <Input
                                    value={item.llm_config.max_input_length}
                                />
                            </Form.Item>
                            <Form.Item label="Model Params" name={["llm_config", "model_params"]}>
                                <Editor
                                    height="150px"
                                    language="json"

                                    onChange={(value) => {
                                        form.setFieldValue(["llm_config", "model_params"], value)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Environment Variables" name={["llm_config", "environment_variables"]}>

                                <Editor
                                    height="150px"
                                    language="json"
                                    onChange={(value) => {
                                        form.setFieldValue(["llm_config", "environment_variables"], value)
                                    }}
                                />
                            </Form.Item>
                        </Tabs.TabPane>


                        <Tabs.TabPane tab="Additional" key='additional'>
                            <Form.Item label="Max Tokens/Chunk Buffer" name={"max_tokens_per_chunk_buffer"}>
                                <Input
                                    value={item.max_tokens_per_chunk_buffer}
                                />
                            </Form.Item>
                            <Form.Item label="Generation Params" name={"gen_params"}>
                                <Editor
                                    height="350px"
                                    language="json"
                                    onChange={(value) => {
                                        form.setFieldValue(["gen_params"], value)

                                    }}
                                />
                            </Form.Item>
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            </Modal>
            <Card
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className="card"
                ref={provided.innerRef}


                style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style,
                    item.is_active,
                    toggle
                )}
                bodyStyle={{
                    width: "100%",
                    minWidth: "325px",
                }}
                cover={
                    <>
                        {editable && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: "5px",
                                    left: "5px",
                                    zIndex: 1000,
                                    width: "50px"
                                }}

                            >
                                <Switch
                                    checked={item.is_active}
                                    onChange={(checked) => {
                                        onEdit({ ...item, is_active: checked })
                                    }}
                                />
                            </div>
                        )}

                        <div style={{
                            height: "20px",
                            width: "100%",
                            backgroundColor: typeof color === "string" ? color : color.toHexString()
                        }}>
                            {editable && (
                                <FloatButton.Group
                                    trigger="click"
                                    type="default"
                                    style={{ right: 5, top: 5, position: "absolute" }}
                                    tooltip="Tools"
                                    icon={<ToolOutlined />}
                                >
                                    <FloatButton icon={<CopyOutlined />} onClick={() => { onCopy(item.id) }} tooltip="Copy" />
                                    <FloatButton icon={<SaveOutlined />} onClick={() => { onSaveProcessor(item) }} tooltip="Save" />
                                    <FloatButton icon={<DeleteOutlined />} onClick={() => { onRemove(item.id) }} tooltip="Remove" />
                                    <FloatButton icon={<EditOutlined />} onClick={() => { setModalOpen(true) }} tooltip="Edit" />
                                    <FloatButton icon={<InfoCircleOutlined />} onClick={showDrawer} tooltip="Details" />
                                </FloatButton.Group>
                            )}

                        </div>
                    </>
                }
                actions={[
                    <InfoCircleOutlined key="info" onClick={() => {

                        showDrawer()
                    }} />,
                    <DeleteOutlined key="remove" onClick={() => { onRemove(item.id) }} style={{
                        opacity: editable ? 1 : 0.5,
                        pointerEvents: editable ? "auto" : "none"
                    }} />,
                    <EditOutlined key="edit" onClick={() => { setModalOpen(true) }} />,
                ]}
            >

                <>
                    <Row
                        style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            height: "210px"
                        }}
                        {...provided.dragHandleProps}
                    >
                        <Col span={24}>
                            <CourierInfoText>
                                <Text style={{ fontSize: 12, fontWeight: 200 }}>Processor Name</Text>
                                <h1 style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{item.name}</h1>
                            </CourierInfoText>
                        </Col>
                        <Col span={24} style={{ marginBottom: 10 }}>
                            <CourierInfoText>
                                <Text style={{ fontSize: 12, fontWeight: 200 }}>System Prompt</Text>

                                <MDEditor.Markdown
                                    style={{
                                        // fontSize: 12,
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                    source={formatPrompt(item.llm_config.default_system_prompt)}
                                />
                            </CourierInfoText>
                        </Col>

                        <Col span={24}>
                            <CourierInfoText>
                                <Text style={{ fontSize: 12, fontWeight: 200 }}>Prompt</Text>

                                <MDEditor.Markdown
                                    style={{
                                        // fontSize: 12,
                                        display: '-webkit-box',
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                    source={formatPrompt(item.prompt.text)}
                                />

                            </CourierInfoText>
                        </Col>

                    </Row>

                </>

                <Drawer
                    title={<>Processor Details</>}
                    placement="bottom"
                    closable={false}
                    onClose={onClose}
                    open={open}
                    getContainer={false}
                    height={"475px"}
                >
                    <>
                        <Row style={{
                            overflowX: "hidden",
                        }}>
                            <Col span={24}>
                                <CourierInfoText>
                                    <Text style={{ fontSize: 12, fontWeight: 200 }}>Processor Name</Text>

                                    <h1 style={{
                                        wordBreak: "break-all",

                                    }}>{item.name}</h1>
                                </CourierInfoText>
                            </Col>

                            <Col span={24} style={{ marginBottom: 10 }}>
                                <CourierInfoText>
                                    <Text style={{ fontSize: 12, fontWeight: 200 }}>System Prompt</Text>
                                    <MDEditor.Markdown
                                        style={{
                                            // fontSize: 12
                                        }}
                                        source={formatPrompt(item.llm_config.default_system_prompt)}
                                    />
                                </CourierInfoText>
                            </Col>
                            <Col span={24}>
                                <CourierInfoText>
                                    <Text style={{ fontSize: 12, fontWeight: 200 }}>Prompt</Text>
                                    <MDEditor.Markdown
                                        style={{
                                            // fontSize: 12
                                        }}
                                        source={formatPrompt(item.prompt.text)}
                                    />
                                </CourierInfoText>
                            </Col>
                        </Row>
                    </>
                </Drawer>
            </Card>

        </>
    )
}
