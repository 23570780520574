import React, { ReactNode, useCallback, useEffect, useState } from "react"
import ProcessorEditors from "../../../pages/LiveTester/ProcessEditors"
import { List } from "@refinedev/antd"
import {
    Button,
    Typography,
    Card,
    Table,
    Input,
    Select,
    Tag,
    Row,
    Col,
    Switch,
    Dropdown,
    Modal
} from "antd"
import { MenuOutlined } from "@ant-design/icons"
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core"
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable"
import { CourierInfoText, ProductFooter } from "../../../styled/styled"
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next"
import {
    IProcessorRow,
    DraggableRow,
    DraggableWrapper,
    llmConfigTemplates,
    processorTemplates,
} from "../../../pages/LiveTester/Helpers"
import MDEditor from "@uiw/react-md-editor"
// import MDEditor from "../../../components/MDEditor"
import { HorizontalList } from "./HorizontalList"
import { useDataProvider, useMany, useTable } from "@refinedev/core"
import _ from "lodash"

var JSON5 = require("json5")

const { Text } = Typography

const runLocal = false

interface PipelineEditorProps {
    showVerticalList?: boolean
    showHorizontalList?: boolean
    showMagicMode?: boolean
    showControls?: boolean
}

export const PipelineEditor: React.FC<PipelineEditorProps> = (
    { showVerticalList = true, showHorizontalList = true, showMagicMode = true, showControls = true }
) => {
    const { t } = useTranslation()
    const [editorText, setEditorText] = React.useState<string>("")
    const [outputText, setOutputText] = React.useState<string>("")
    const [rows, setRows] = React.useState<IProcessorRow[]>([])
    const [activeId, setActiveId] = useState(null)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalContent, setModalContent] = useState<ReactNode>(null)
    const [selectedLLMConfigTemplate, setSelectedLLMConfigTemplate] =
        useState<any>(llmConfigTemplates[0])
    const [selectedProcessorTemplate, setSelectedProcessorTemplate] =
        useState<any>(processorTemplates[0])
    const [pipelines, setPipelines] = useState<any[]>([])
    const [selectedPipeline, setSelectedPipeline] = useState<any>(
        null
    )
    const [magicModeEnabled, setMagicModeEnabled] = useState<boolean>(showMagicMode)
    const [pipelineIds, setPipelineIds] = useState<string[]>([])
    const [pipelineToUpdate, setPipelineToUpdate] = useState<any>(null)
    const [saveModalIsOpen, setSaveModalIsOpen] = useState<boolean>(false)


    const { data: pipelinesData, isLoading: pipelinesIsLoading } = useMany({
        resource: "pipelines",
        ids: pipelineIds,
    })

    console.log("pipelinesData:", pipelinesData)

    const myDataProvider = useDataProvider()("default") as any

    const showModal = () => {
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false)
    }

    const showRows = () => {
        setModalContent(
            <div style={{ height: "500px", width: "100%" }}>
                <Typography.Title
                    style={{
                        textAlign: "center",
                        backgroundColor: "lightgray",
                        color: "black",
                    }}
                    level={4}
                >
                    Rows
                </Typography.Title>
                <MDEditor
                    value={JSON.stringify(rows, null, 4)}
                    height={500}
                    preview="edit"
                />
            </div>
        )
        showModal()
    }

    const columns = [
        {
            key: "dragHandle",
            dataIndex: "dragHandle",
            title: "Drag",
            width: 30,
            render: () => <MenuOutlined rev={undefined} />,
        },
    ]

    const onChangeMagicMode = (checked: boolean) => {
        setMagicModeEnabled(checked)
    }

    const handleDefaultSystemPromptChange = useCallback(
        (value: string, recordKey: string) => {
            setRows((oldRows) => [
                ...oldRows.map((row) => {
                    if (row.key === recordKey && row.llm_config) {
                        row.llm_config.default_system_prompt = value
                    }
                    return row
                }),
            ])
        },
        []
    )

    const handleJsonSchemaChange = useCallback(
        (value: string, recordKey: string) => {
            setRows((oldRows) => [
                ...oldRows.map((row) => {
                    if (row.key === recordKey && row.json_schema) {
                        row.json_schema.content = value
                    }
                    return row
                }),
            ])
        },
        []
    )

    const handlePromptChange = useCallback((value: string, recordKey: string) => {
        setRows((oldRows) => [
            ...oldRows.map((row) => {
                if (row.key === recordKey && row.prompt) {
                    row.prompt.text = value
                }
                return row
            }),
        ])
    }, [])

    const handleGenParamsChange = useCallback(
        (value: string, recordKey: string) => {
            setRows((oldRows) => [
                ...oldRows.map((row) => {
                    if (row.key === recordKey && row.gen_params) {
                        row.gen_params = value
                    }
                    return row
                }),
            ])
        },
        []
    )

    const handleModelParamsChange = useCallback(
        (value: string, recordKey: string) => {
            setRows((oldRows) => [
                ...oldRows.map((row) => {
                    if (row.key === recordKey && row.llm_config?.model_params) {
                        row.llm_config.model_params = value
                    }
                    return row
                }),
            ])
        },
        []
    )

    const handleEnvVariablesChange = useCallback(
        (value: string, recordKey: string) => {
            setRows((oldRows) => [
                ...oldRows.map((row) => {
                    if (row.key === recordKey && row.llm_config?.environment_variables) {
                        row.llm_config.environment_variables = value
                    }
                    return row
                }),
            ])
        },
        []
    )

    const handleSavePipeline = async (pipeline_update_id = null) => {
        console.log("handleSavePipeline")
        console.log("pipeline_update_id:", pipeline_update_id)
        const should_update = pipeline_update_id !== null
        console.log("should_update:", should_update)
        const pipeline_id = pipeline_update_id ?? uuidv4()
        const existing_pipeline = should_update ? pipelinesData?.data?.find((pipeline) => pipeline.id === pipeline_id) : null
        console.log("existing_pipeline:", existing_pipeline)
        const pipeline_name = existing_pipeline?.name ?? "test"
        const result = await myDataProvider.update_or_create_pipeline({
            pipeline: {
                id: pipeline_id,
                name: pipeline_name,
                should_update: should_update,
                processors: rows.map((row) => {
                    const existing_processor = existing_pipeline?.processors?.find((processor) => processor.id === row.id)
                    return {
                        id: row.id,
                        name: row.name,
                        should_update: should_update && existing_processor !== null,
                        llm_config: {
                            ...row.llm_config,
                            should_update: should_update && existing_processor !== null,
                            model_params: JSON5.parse(
                                row.llm_config?.model_params ?? "{}"
                            ),
                            environment_variables: JSON5.parse(
                                row.llm_config?.environment_variables ?? "{}"
                            ),
                        },
                        json_schema: {
                            id: row.json_schema?.id,
                            name: row.json_schema?.name,
                            should_update: should_update && existing_processor !== null,
                            content: JSON5.parse(row.json_schema?.content ?? "{}"),
                        },
                        prompt: { ...row.prompt, should_update: should_update && existing_processor !== null },
                        max_tokens_per_chunk_buffer: row.max_tokens_per_chunk_buffer,
                        gen_params: JSON5.parse(row.gen_params ?? "{}"),
                    }
                }),
            },
            input_text: editorText,
        })
        console.log("result:", result)
    }

    const handleSaveProcessor = async (processor, processor_update_id = null) => {
        const should_update = processor_update_id !== null
        const result = await myDataProvider.update_or_create_processor({
            processor: { ...processor, should_update: should_update }
        })
        console.log("result:", result)
    }

    const handleGenerate = async () => {
        try {
            const requestId = uuidv4()
            const result = await myDataProvider.generate({
                pipeline: {
                    id: uuidv4(),
                    name: "test",
                    processors: rows.map((row) => {
                        return {
                            id: row.id,
                            name: row.name,
                            is_active: row.is_active,
                            llm_config: {
                                ...row.llm_config,
                                model_params: JSON5.parse(
                                    row.llm_config?.model_params ?? "{}"
                                ),
                                environment_variables: JSON5.parse(
                                    row.llm_config?.environment_variables ?? "{}"
                                ),
                            },
                            json_schema: {
                                name: row.json_schema?.name,
                                content: JSON5.parse(row.json_schema?.content ?? "{}"),
                            },
                            prompt: row.prompt,
                            max_tokens_per_chunk_buffer: row.max_tokens_per_chunk_buffer,
                            gen_params: JSON5.parse(row.gen_params ?? "{}"),
                        }
                    }),
                },
                input_text: editorText,
                request_id: requestId,
                request_type: null,
                run_locally: runLocal,
            })
            console.log("result:", result)

            return result.data
        } catch (err: any) {
            console.log(err)
            return {
                result: "Error",
                error: err.message,
                stacktrace: err.stack,
            }
        }
    }

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    function handleDragStart(event: any) {
        const { active } = event
        setActiveId(active.id)
    }

    function handleDragEnd(event: any) {
        const { active, over } = event
        if (active.id !== over.id) {
            setRows((items: any[]) => {
                const oldIndex = items.findIndex(
                    (item: { key: any }) => item.key === active.id
                )
                const newIndex = items.findIndex(
                    (item: { key: any }) => item.key === over.id
                )
                return arrayMove(items, oldIndex, newIndex)
            })
        }
        setActiveId(null)
    }

    const menuItems = [
        {
            key: "create",
            label: "Save As New"
        },
        {
            key: "update",
            label: "Update Existing"
        }
    ]

    useEffect(() => {
        if (pipelinesData) {
            setPipelines([
                ...pipelines,
                ...pipelinesData?.data?.map((pipeline) => {
                    return {
                        name: pipeline.name,
                        value: pipeline.id,
                    }
                }),
            ])
        }
    }
        , [pipelinesData]);

    useEffect(() => {
        console.log("selectedPipeline:", selectedPipeline)
        if (selectedPipeline === null) {
            // Leave only rows that are not row_type === "processor"
            const newRows = _.cloneDeep(rows).filter((row) => row.row_type !== "processor")
            setRows(newRows)
        }
        else {
            const pipeline = pipelinesData?.data?.find(
                (pipeline) => pipeline.id === selectedPipeline.value
            )
            const processors = pipeline?.processors ?? []
            console.log("processors:", processors)
            setRows(
                [
                    ...rows,
                    ...processors.map((processor) => {
                        return {
                            id: processor.id,
                            key: uuidv4(),
                            row_type: "processor",
                            is_active: processor.is_active,
                            name: processor.name,
                            llm_config: {
                                id: processor.llm_config?.id ?? uuidv4(),
                                server_type: processor.llm_config?.server_type ?? "",
                                base_inference_url:
                                    processor.llm_config?.base_inference_url ?? "",
                                model_prompt_format:
                                    processor.llm_config?.model_prompt_format ?? "",
                                default_system_prompt:
                                    processor.llm_config?.default_system_prompt ?? "",
                                max_input_length: processor.llm_config?.max_input_length ?? 0,
                                model_params: JSON5.stringify(
                                    processor.llm_config?.model_params ?? {},
                                    null,
                                    4
                                ),
                                environment_variables: JSON5.stringify(
                                    processor.llm_config?.environment_variables ?? {},
                                    null,
                                    4
                                ),
                            },
                            json_schema: {
                                id: processor.json_schema?.id ?? uuidv4(),
                                name: processor.json_schema?.name ?? "",
                                content: JSON5.stringify(
                                    processor.json_schema?.content ?? {},
                                    null,
                                    4
                                ),
                            },
                            prompt: {
                                id: processor.prompt?.id ?? uuidv4(),
                                text: processor.prompt?.text ?? "",
                            },
                            max_tokens_per_chunk_buffer: processor.max_tokens_per_chunk_buffer,
                            gen_params: JSON5.stringify(processor.gen_params ?? {}, null, 4),
                        }
                    })
                ]
            )
        }
    }
        , [selectedPipeline]);
    return (<>
        {(showControls && (
            <>
                <Modal
                    open={saveModalIsOpen}
                    title="Select Pipeline to Update"
                    onOk={() => {
                        console.log("pipelineToUpdate:", pipelineToUpdate)
                        handleSavePipeline(pipelineToUpdate?.value)
                        setSaveModalIsOpen(false)

                    }}
                    onCancel={() => {
                        setSaveModalIsOpen(false)

                    }}
                    footer={(_, { OkBtn, CancelBtn }) => (
                        <>
                            <CancelBtn />
                            <OkBtn />
                        </>
                    )}
                >
                    <Select
                        placeholder="Select Pipeline"
                        allowClear
                        style={{ width: 300 }}
                        loading={pipelinesIsLoading}
                        onChange={(value) => {
                            console.log("value:", value)
                            console.log("pipelines:", pipelines)
                            setPipelineToUpdate(
                                pipelines.find(
                                    (pipeline) => pipeline.value === value
                                ) ?? null
                            )
                        }}
                        options={[
                            ...pipelines.map((pipeline) => {
                                console.log("pipeline:", pipeline)
                                return {
                                    label: pipeline.name,
                                    value: pipeline.value,
                                }
                            }),
                        ]}
                    />
                </Modal>
                <Card>
                    <Row justify="center">
                        <Col
                            xl={12}
                            lg={10}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "flex-end",
                            }}
                        >

                        </Col>

                        <Col
                            xl={12}
                            lg={14}
                            md={14}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                            }}
                        >


                        </Col>
                        <Col>
                            <CourierInfoText>
                                <Text style={{ fontSize: 12, fontWeight: 200 }}>Load Pipeline</Text>
                                <Select
                                    placeholder="Select Pipeline"
                                    allowClear
                                    style={{ width: 300 }}
                                    loading={pipelinesIsLoading}
                                    onChange={(value) => {
                                        console.log("value:", value)
                                        setSelectedPipeline(
                                            pipelines.find(
                                                (pipeline) => pipeline.value === value
                                            ) ?? null
                                        )
                                    }}
                                    options={[
                                        ...pipelines.map((pipeline) => {
                                            console.log("pipeline:", pipeline)
                                            return {
                                                label: pipeline.name,
                                                value: pipeline.value,
                                            }
                                        }),
                                    ]}
                                />
                            </CourierInfoText>
                        </Col>
                        <Col>
                            <CourierInfoText>
                                <Text style={{ fontSize: 12, fontWeight: 200 }}>Default LLM Config</Text>
                                <Select
                                    defaultValue={selectedLLMConfigTemplate.name}
                                    style={{ width: 300 }}
                                    onChange={(value) => {
                                        setSelectedLLMConfigTemplate(
                                            llmConfigTemplates.find(
                                                (template) => template.name === value
                                            ) ?? {}
                                        )
                                    }}
                                    options={[
                                        ...llmConfigTemplates.map((template) => {
                                            return {
                                                label: template.name,
                                                value: template.name,
                                            }
                                        }),
                                    ]}
                                />
                            </CourierInfoText>
                        </Col>
                        <Col>
                            <CourierInfoText>
                                <Text style={{ fontSize: 12, fontWeight: 200 }}>Processor Template</Text>
                                <Select
                                    defaultValue={selectedProcessorTemplate.name}
                                    style={{ width: 300 }}
                                    onChange={(value) => {
                                        setSelectedProcessorTemplate(
                                            processorTemplates.find(
                                                (template) => template.name === value
                                            ) ?? {}
                                        )
                                    }}
                                    options={[
                                        ...processorTemplates.map((template) => {
                                            return {
                                                label: template.name,
                                                value: template.name,
                                            }
                                        }),
                                    ]}
                                />
                            </CourierInfoText>
                        </Col>
                        {showMagicMode && (
                            <Col>
                                <CourierInfoText>
                                    <Text style={{ fontSize: 12, fontWeight: 200 }}>Magic Mode</Text>
                                    <Switch defaultChecked onChange={onChangeMagicMode} />
                                </CourierInfoText>
                            </Col>
                        )}

                        <Col>
                            <CourierInfoText>
                                <Text style={{ fontSize: 12, fontWeight: 200 }}>Save Pipeline</Text>
                                <>
                                    <Dropdown.Button
                                        menu={{
                                            items: menuItems,
                                            onClick: (e) => {
                                                console.log("e:", e)
                                                if (e.key === "create") {
                                                    handleSavePipeline()
                                                }
                                                else if (e.key === "update") {
                                                    setSaveModalIsOpen(true)
                                                }

                                            }
                                        }}
                                        style={{
                                            textAlign: "center",
                                            flex: 1,
                                        }}
                                        type="dashed"
                                    >
                                        {t("Save Options")}
                                    </Dropdown.Button>
                                </>
                                {/* <Button
                                style={{
                                    textAlign: "center",
                                    flex: 1,
                                }}
                                type="dashed"
                                onClick={() => {
                                    console.log("clicked")
                                    console.log(rows)
                                    handleSavePipeline()

                                }}
                            >
                                {t("Save")}
                            </Button> */}
                            </CourierInfoText>

                        </Col>
                        <Col>
                            <CourierInfoText>
                                <Text style={{ fontSize: 12, fontWeight: 200 }}>Add Processor</Text>
                                <Button
                                    style={{
                                        textAlign: "center",
                                        flex: 1,
                                    }}
                                    type="dashed"
                                    onClick={() => {
                                        setRows((oldRows) => [
                                            ...oldRows,
                                            {
                                                id: uuidv4(),
                                                key: uuidv4(),
                                                row_type: "custom",
                                                is_active: true,
                                                name: selectedProcessorTemplate.name,
                                                llm_config: {
                                                    id: uuidv4(),
                                                    server_type: selectedLLMConfigTemplate.value.server_type,
                                                    base_inference_url:
                                                        selectedLLMConfigTemplate.value.base_inference_url,
                                                    model_prompt_format:
                                                        selectedLLMConfigTemplate.value.model_prompt_format,
                                                    default_system_prompt:
                                                        selectedProcessorTemplate.value.default_system_prompt,
                                                    max_input_length:
                                                        selectedLLMConfigTemplate.value.max_input_length,
                                                    model_params: JSON5.stringify(
                                                        selectedLLMConfigTemplate.value.model_params,
                                                        null,
                                                        4
                                                    ),
                                                    environment_variables: JSON5.stringify(
                                                        selectedLLMConfigTemplate.value.environment_variables,
                                                        null,
                                                        4
                                                    ),
                                                },
                                                json_schema: {
                                                    id: uuidv4(),
                                                    name: selectedProcessorTemplate.value.json_schema.name,
                                                    content: JSON5.stringify(
                                                        selectedProcessorTemplate.value.json_schema.content,
                                                        null,
                                                        4
                                                    ),
                                                },
                                                prompt: {
                                                    id: uuidv4(),
                                                    text: selectedProcessorTemplate.value.prompt,
                                                },
                                                max_tokens_per_chunk_buffer: 0,
                                                gen_params: JSON5.stringify(
                                                    selectedProcessorTemplate.value.gen_params,
                                                    null,
                                                    4
                                                ),
                                            },
                                        ])
                                    }}
                                >
                                    {t("Create")}
                                </Button>
                            </CourierInfoText>
                        </Col>
                    </Row>
                </Card>
            </>
        ))}

        {showVerticalList && (
            <List
                headerProps={{ style: { marginTop: 20 } }}
                canCreate={true}
                title={
                    <Text style={{ fontSize: 22, fontWeight: 800 }}>{t("Processors")}</Text>
                }
                headerButtons={({ defaultButtons }) => (
                    <>


                        {defaultButtons}

                    </>

                )}
                createButtonProps={{
                    onClick: () => {
                        console.log("clicked")
                        console.log(rows)
                        setRows((oldRows) => [
                            ...oldRows,
                            {
                                id: uuidv4(),
                                key: uuidv4(),
                                row_type: "custom",
                                is_active: true,
                                name: selectedProcessorTemplate.name,
                                llm_config: {
                                    id: uuidv4(),
                                    server_type: selectedLLMConfigTemplate.value.server_type,
                                    base_inference_url:
                                        selectedLLMConfigTemplate.value.base_inference_url,
                                    model_prompt_format:
                                        selectedLLMConfigTemplate.value.model_prompt_format,
                                    default_system_prompt:
                                        selectedProcessorTemplate.value.default_system_prompt,
                                    max_input_length:
                                        selectedLLMConfigTemplate.value.max_input_length,
                                    model_params: JSON5.stringify(
                                        selectedLLMConfigTemplate.value.model_params,
                                        null,
                                        4
                                    ),
                                    environment_variables: JSON5.stringify(
                                        selectedLLMConfigTemplate.value.environment_variables,
                                        null,
                                        4
                                    ),
                                },
                                json_schema: {
                                    id: uuidv4(),
                                    name: selectedProcessorTemplate.value.json_schema.name,
                                    content: JSON5.stringify(
                                        selectedProcessorTemplate.value.json_schema.content,
                                        null,
                                        4
                                    ),
                                },
                                prompt: {
                                    id: uuidv4(),
                                    text: selectedProcessorTemplate.value.prompt,
                                },
                                max_tokens_per_chunk_buffer: 0,
                                gen_params: JSON5.stringify(
                                    selectedProcessorTemplate.value.gen_params,
                                    null,
                                    4
                                ),
                            },
                        ])
                    },
                }}
            >
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                >
                    <Table
                        pagination={false}
                        dataSource={rows}
                        footer={(_data) => <ProductFooter></ProductFooter>}
                        expandable={{
                            expandedRowRender: (record) => (
                                <Card>
                                    <div>
                                        <ProcessorEditors
                                            recordKey={record.key}
                                            defaultSystemPrompt={
                                                record.llm_config?.default_system_prompt ?? ""
                                            }
                                            jsonSchema={record.json_schema?.content ?? ""}
                                            prompt={record.prompt?.text ?? ""}
                                            genParams={record.gen_params ?? ""}
                                            modelParams={record.llm_config?.model_params ?? ""}
                                            envVariables={
                                                record.llm_config?.environment_variables ?? ""
                                            }
                                            onDefaultSystemPromptChange={
                                                handleDefaultSystemPromptChange
                                            }
                                            onJsonSchemaChange={handleJsonSchemaChange}
                                            onPromptChange={handlePromptChange}
                                            onGenParamsChange={handleGenParamsChange}
                                            onModelParamsChange={handleModelParamsChange}
                                            onEnvVariablesChange={handleEnvVariablesChange}
                                            magicModeEnabled={magicModeEnabled}
                                        />
                                    </div>
                                </Card>
                            ),
                            rowExpandable: (record: any) => record.name !== "Not Expandable",
                            expandRowByClick: false,
                        }}
                        components={{
                            body: {
                                wrapper: DraggableWrapper,
                                row: DraggableRow,
                            },
                        }}
                    >
                        <Table.Column<IProcessorRow>
                            key="dragHandle"
                            dataIndex="dragHandle"
                            title="Drag"
                            width={30}
                            render={() => <MenuOutlined rev={undefined} />}
                        />
                        {/* <Table.Column<IProcessorRow> key="key" dataIndex="key" title="Key"  /> */}
                        <Table.Column<IProcessorRow>
                            key="name"
                            dataIndex="name"
                            title="Name"
                            render={(value, record) => (
                                <Input
                                    value={record.name}
                                    onChange={(e) => {
                                        setRows((oldRows) => [
                                            ...oldRows.map((row) => {
                                                if (row.key === record.key && row.name) {
                                                    row.name = e.target.value
                                                }
                                                return row
                                            }),
                                        ])
                                    }}
                                />
                            )}
                        />
                        <Table.Column<IProcessorRow>
                            key="server_type"
                            dataIndex="llm_config.server_type"
                            title="Server Type"
                            render={(value, record) => (
                                <Input
                                    value={record.llm_config?.server_type}
                                    onChange={(e) => {
                                        setRows((oldRows) => [
                                            ...oldRows.map((row) => {
                                                if (row.key === record.key && row.llm_config) {
                                                    row.llm_config.server_type = e.target.value
                                                }
                                                return row
                                            }),
                                        ])
                                    }}
                                />
                            )}
                        />
                        {!magicModeEnabled && (
                            <Table.Column<IProcessorRow>
                                key="base_inference_url"
                                dataIndex="llm_config.base_inference_url"
                                title="Base Inference URL"
                                render={(value, record) => (
                                    <Input
                                        value={record.llm_config?.base_inference_url}
                                        onChange={(e) => {
                                            setRows((oldRows) => [
                                                ...oldRows.map((row) => {
                                                    if (row.key === record.key && row.llm_config) {
                                                        row.llm_config.base_inference_url = e.target.value
                                                    }
                                                    return row
                                                }),
                                            ])
                                        }}
                                    />
                                )}
                            />
                        )}
                        {!magicModeEnabled && (
                            <Table.Column<IProcessorRow>
                                key="model_prompt_format"
                                dataIndex="llm_config.model_prompt_format"
                                title="Model Prompt Format"
                                render={(value, record) => (
                                    <Input
                                        value={record.llm_config?.model_prompt_format}
                                        onChange={(e) => {
                                            setRows((oldRows) => [
                                                ...oldRows.map((row) => {
                                                    if (row.key === record.key && row.llm_config) {
                                                        row.llm_config.model_prompt_format = e.target.value
                                                    }
                                                    return row
                                                }),
                                            ])
                                        }}
                                    />
                                )}
                            />
                        )}
                        {!magicModeEnabled && (
                            <Table.Column<IProcessorRow>
                                key="max_input_length"
                                dataIndex="llm_config.max_input_length"
                                title="Max Input Length"
                                render={(value, record) => (
                                    <Input
                                        value={record.llm_config?.max_input_length}
                                        onChange={(e) => {
                                            setRows((oldRows) => [
                                                ...oldRows.map((row) => {
                                                    if (row.key === record.key && row.llm_config) {
                                                        row.llm_config.max_input_length = parseInt(
                                                            e.target.value
                                                        )
                                                    }
                                                    return row
                                                }),
                                            ])
                                        }}
                                    />
                                )}
                            />
                        )}




                        <Table.Column<IProcessorRow>
                            key="row_type"
                            dataIndex="row_type"
                            title="Type"
                            render={(value, record) => (
                                <Tag color={record.row_type == "processor" ? "blue" : "green"}>
                                    {record.row_type}
                                </Tag>
                            )}
                        />

                        <Table.Column<IProcessorRow>
                            key="is_active"
                            dataIndex="is_active"
                            title="Active"
                            render={(value, record) => (
                                <Switch
                                    checked={record.is_active}
                                    onChange={(checked) => {
                                        setRows((oldRows) => [
                                            ...oldRows.map((row) => {
                                                if (row.key === record.key) {
                                                    row.is_active = checked
                                                }
                                                return row
                                            }),
                                        ])
                                    }}
                                />


                            )}
                        />

                        <Table.Column<IProcessorRow>
                            key="action"
                            title={t("Action")}
                            align="center"
                            width={100}
                            render={(value, record) => (
                                <Row style={{ justifyContent: "space-between", display: "flex", flexDirection: "row", width: "200px" }}>
                                    <Col style={{ width: "50%" }}>

                                        {record.row_type === "custom" ? (
                                            <Button
                                                style={{
                                                    textAlign: "right",
                                                    flex: 1,
                                                }}
                                                type="dashed"
                                                onClick={() => {
                                                    console.log("clicked")
                                                    console.log(rows)
                                                    handleSaveProcessor(record)

                                                }}
                                            >
                                                {t("Save")}
                                            </Button>
                                        ) : (
                                            <>
                                                <Dropdown.Button
                                                    menu={{
                                                        items: menuItems,
                                                        onClick: (e) => {
                                                            console.log("e:", e)
                                                            if (e.key === "create") {
                                                                handleSaveProcessor(record)
                                                            }
                                                            else if (e.key === "update") {
                                                                handleSaveProcessor(record, record.id)
                                                            }

                                                        }
                                                    }}
                                                    style={{
                                                        textAlign: "center",
                                                        flex: 1,
                                                    }}
                                                    type="dashed"
                                                >
                                                    {t("Save Options")}
                                                </Dropdown.Button>
                                            </>
                                        )}


                                    </Col>
                                    <Col style={{ width: "50%" }}>
                                        <Button
                                            style={{
                                                textAlign: "right",
                                                flex: 1,
                                            }}
                                            type="primary"
                                            onClick={() => {
                                                console.log("clicked")
                                                console.log(rows)
                                                setRows((oldRows) => [
                                                    ...oldRows.filter((row) => row.key !== record.key),
                                                ])
                                            }}
                                        >
                                            {t("Remove")}
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        />
                    </Table>
                    <DragOverlay>
                        <Table
                            columns={columns}
                            showHeader={false}
                            dataSource={
                                activeId
                                    ? new Array(1).fill(
                                        rows[
                                        rows.findIndex(
                                            (item: { key: any }) => item.key === activeId
                                        )
                                        ]
                                    )
                                    : []
                            }
                            pagination={false}
                        />
                    </DragOverlay>
                </DndContext>
            </List>
        )}
        {showHorizontalList && (
            <HorizontalList rows={rows} onChangeRows={setRows} onSaveProcessor={handleSaveProcessor} />
        )}

    </>);
}