import React from "react"
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core"
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  BooleanField,
} from "@refinedev/antd"
import { Table, Space } from "antd"

export const ProcessorList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  })

  const { data: llmConfigData, isLoading: llmConfigIsLoading } = useMany({
    resource: "llm_configs",
    ids: tableProps?.dataSource?.map((item) => item?.llm_config) ?? [],
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  })

  const { data: jsonSchemaData, isLoading: jsonSchemaIsLoading } = useMany({
    resource: "json_schemas",
    ids: tableProps?.dataSource?.map((item) => item?.json_schema) ?? [],
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  })

  const { data: promptData, isLoading: promptIsLoading } = useMany({
    resource: "prompts",
    ids: tableProps?.dataSource?.map((item) => item?.prompt) ?? [],
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  })

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex={["llm_config", "name"]}
          title="Llm Config"
          key="llm_config.id"
          //   render={(value) =>
          //     llmConfigIsLoading ? (
          //       <>Loading...</>
          //     ) : (
          //       llmConfigData?.data?.find((item) => item.id === value.id)?.name
          //     )
          //   }
        />
        <Table.Column
          dataIndex={["json_schema", "name"]}
          title="Json Schema"
          key="json_schema.id"
        />
        <Table.Column
          dataIndex={["prompt", "name"]}
          title="Prompt"
          key="prompt.id"
        />
        <Table.Column
          dataIndex="max_tokens_per_chunk_buffer"
          title="Max Tokens Per Chunk Buffer"
        />
        <Table.Column
          dataIndex="gen_params"
          title="Gen Params"
          //   render={(value) => JSON.stringify(value)}
        />
        <Table.Column
          dataIndex={["is_active"]}
          title="Is Active"
          render={(value: any) => <BooleanField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
