import React from "react"
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core"
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  TagField,
  DateField,
  DeleteButton,
  BooleanField,
} from "@refinedev/antd"
import { Table, Space } from "antd"

export const PipelineList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  })

  const { data: processorsData, isLoading: processorsIsLoading } = useMany({
    resource: "processors",
    ids: [].concat(
      ...(tableProps?.dataSource?.map((item) => item?.processors) ?? [])
    ),
    queryOptions: {
      enabled: !!tableProps?.dataSource,
    },
  })

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex="processors"
          title="Processors"
          render={(value: any[]) =>
            processorsIsLoading ? (
              <>Loading...</>
            ) : (
              <>
                {value?.map((item, index) => (
                  <TagField key={index} value={item?.name} />
                ))}
              </>
            )
          }
        />
        <Table.Column
          dataIndex={["createdAt"]}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex={["updatedAt"]}
          title="Updated At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex={["is_active"]}
          title="Is Active"
          render={(value: any) => <BooleanField value={value} />}
        />

        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
