"use client";

import { useRef, useContext, useEffect, useMemo } from "react";
import { PipelineAssistantContext } from "../context/pipeline-assistant-context";
import { AnnotatedFunction } from "../types/annotated-function";
import { nanoid } from "nanoid";

export function useMakePipelineAssistantActionable<ActionInput extends any[]>(
    annotatedFunction: AnnotatedFunction<ActionInput>,
    dependencies: any[]
) {
    const idRef = useRef(nanoid()); // generate a unique id
    const { setEntryPoint, removeEntryPoint } = useContext(PipelineAssistantContext);

    const memoizedAnnotatedFunction: AnnotatedFunction<ActionInput> = useMemo(
        () => ({
            name: annotatedFunction.name,
            description: annotatedFunction.description,
            argumentAnnotations: annotatedFunction.argumentAnnotations,
            implementation: annotatedFunction.implementation,
        }),
        dependencies
    );

    useEffect(() => {
        setEntryPoint(
            idRef.current,
            memoizedAnnotatedFunction as AnnotatedFunction<any[]>
        );

        return () => {
            removeEntryPoint(idRef.current);
        };
    }, [memoizedAnnotatedFunction, setEntryPoint, removeEntryPoint]);
}